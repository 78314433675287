import React from 'react'


import Faq from '../../Faq/Faq'


import RetailBanner from './retailcompo/RetailBanner'
import RetailBenifits from './retailcompo/RetailBenifits'
import RetailBanner2 from './retailcompo/RetailBanner2'
import RetailReadyBanner from './retailcompo/RetailReadyBanner'
import WhySaasverseRetail from './retailcompo/WhySaasverseRetail'

export default function Retail() {
  return (
    <><RetailBanner/>
    <RetailBenifits/>
    <RetailBanner2/>
    <WhySaasverseRetail/>
    <RetailReadyBanner/>
    <Faq/>
      
    </>
  )
}
