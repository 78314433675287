import React from 'react'

export default function WhyCloud() {
  return (
    <div className="info-container">
   
    <div className="info-text">
      <h2> Why Choose Cloud Consulting Services?
      </h2>
      <p>Harnessing the cloud offers unparalleled benefits, including scalability, cost efficiency, and advanced security. Our Cloud Consulting Services enable you to:
      </p>
      <p>
       <b style={{color:'#006b95'}}>Accelerate Digital Transformation</b> <br/>Leverage the latest cloud technologies to drive your digital transformation initiatives.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Enhance Agility</b><br/>Quickly adapt to changing market demands with flexible cloud solutions.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Optimize Costs</b><br/>Reduce operational costs with efficient cloud resource management and optimization.</p>
      <p>
       <b style={{color:'#006b95'}}>Ensure Security:</b><br/>Protect your data and applications with robust cloud security measures and compliance.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/whycloud.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
