import React from 'react'

export default function WhyB2C() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2 style={{fontSize:'25px'}}>That lead brings a minute to ask why select Saasverse Pvt Ltd?.</h2>
      {/* <h4>Our Salesforce Architects offer a comprehensive skillset</h4> */}
    </div>
    <div className="columnt">
      <p><b>Deep Expertise</b><br/>A crew of certified Salesforce personnel comprising of specialists in business-to-consumer solutions.
      </p>
      <br/> <p><b>Customer Focus</b> <br/>An engagement of the customer’s needs, special circumstances and requirements in the certain sphere of business activity.
      </p>
    </div>
    <div className="columnt">
      <p><b>Proven Track Record</b><br/>Best practices and case studies of the different industries, proving that we can help achieve digital goals.</p>
      <br/> <p><b>Comprehensive Services</b><br/>Full suite of B2C Salesforce solutions –from consulting to project delivery and everything in-between.</p>
    </div>
  </div>
  )
}
