import React from 'react'
import '../../SalesforceAppExchange/Compo/css/benifits.css'

export default function ServiceBenifits() {
  return (
   <>
    <div id="services">
        <div className="cnt">
          <div className="row">
      <h2 style={{fontSize:'40px',textAlign:'center'}}>Our Salesforce Consulting Covers the following</h2>
            <div className="reasons__wrapper">
              <div className="reasons__item"><span className="reasons__item_figure"></span>
                <div className="reasons__item_content">
                  <h3 className="reasons__item_title">Salesforce <br/>Implementation</h3>
                  <p className="reasons__item_text">  
        <b style={{color:'#006b95'}}> Strategic Planning:</b>
        <p> We begin by understanding your business goals and processes to create a strategic implementation plan that aligns with your objectives. </p>
       
   <b style={{color:'#006b95'}}> Custom Configuration:</b>
  <p> Our team configures Salesforce to meet your specific requirements, ensuring a seamless integration with your existing systems.</p>
  
 <b style={{color:'#006b95'}}>   Data Migration:</b>
<p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p>
</p>
                </div>
              </div>
              <div className="reasons__item"><span className="reasons__item_figure"></span>
                <div className="reasons__item_content">
                  <h3 className="reasons__item_title">Salesforce <br/>Customization
                  </h3>
                  <p className="reasons__item_text">
                    <b style={{color:'#006b95'}}>Tailored Solutions:</b>
                    <p> We customize Salesforce to fit your unique business processes, from custom objects and fields to workflow rules and validation.</p>
   <b style={{color:'#006b95'}}>App Development:</b> 
   <p>Our developers create custom applications within Salesforce to enhance functionality and provide additional value to your operations.</p>
   <b style={{color:'#006b95'}}>Integration Services:</b>
   <p> We integrate Salesforce with other systems and platforms you use, ensuring a unified and efficient workflow. </p>
</p>
                </div>
              </div>
              <div className="reasons__item"><span className="reasons__item_figure"></span>
                <div className="reasons__item_content">
                  <h3 className="reasons__item_title">Salesforce Support and Maintenance</h3>
                  <p className="reasons__item_text">
                     <b style={{color:'#006b95'}}>Ongoing Support:</b>
                     <p> Our support team is always available to help you with any issues, ensuring that your Salesforce environment runs smoothly.</p>
   <b style={{color:'#006b95'}}>Regular Updates:</b> 
   <p>We keep your Salesforce system up to date with the latest features and security updates, maximizing its performance and reliability.</p>
   <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p>
</p>
                </div>
              </div>
              <div className="reasons__item" ><span className="reasons__item_figure"></span>
                <div className="reasons__item_content" >
                  <h3 className="reasons__item_title">Salesforce <br/>Development</h3>
                  <p className="reasons__item_text">
                     <b style={{color:'#006b95'}}>Custom Development:</b>
                     <p> Our developers build custom features and applications tailored to your business needs, enhancing the capabilities of your Salesforce platform.</p>
   <b style={{color:'#006b95'}}>
    Apex and Visualforce Development:</b><p> We use Salesforce’s powerful programming languages to create advanced solutions and custom user interfaces.</p>
   <b style={{color:'#006b95'}}>Lightning Components:</b> 
   <p>Our team develops responsive and dynamic Lightning Components to improve user experience and productivity.</p>
</p>
                </div>
              </div>
              <div className="reasons__item"  ><span className="reasons__item_figure"></span>
                <div className="reasons__item_content">
                  <h3 className="reasons__item_title">Salesforce Consulting and Strategy</h3>
                  <p className="reasons__item_text"> <b style={{color:'#006b95'}}>Business Process Analysis:</b> 
                  <p>We analyze your business processes to identify areas where Salesforce can add value and drive improvements.</p>
   <b style={{color:'#006b95'}}>Roadmap Development:</b>
   <p> We develop a strategic roadmap for your Salesforce journey, ensuring that you achieve your long-term business goals.</p>
   <b style={{color:'#006b95'}}>Performance Optimization:</b>
   <p> Our consultants work with you to optimize your Salesforce environment for better performance and scalability.</p>
</p>
                </div>
              </div>
              {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
                <div className="reasons__item_content">
                  <h3 className="reasons__item_title">Security review for appexchange</h3>
                  <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
   </>
  )
}
