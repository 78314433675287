import React from 'react';
import './css/ThreeColumnLayout.css';

const ThreeColumnLayout = () => {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4>
    </div>
    <div className="columnt">
      <p><b>Expert Security Specialists</b><br/> Our team of certified security experts brings extensive experience in Salesforce security assessments and implementations.</p>
      <br/> <p><b>Tailored Solutions:</b> <br/>We provide personalized security recommendations and solutions tailored to your specific business needs and industry requirements.</p>
    </div>
    <div className="columnt">
      <p><b>Commitment to Excellence</b><br/> We are dedicated to delivering high-quality services that prioritize the security and protection of your Salesforce environment.</p>
      <br/> <p><b>Proven Success</b><br/> Our track record of successful security reviews and satisfied clients demonstrates our capability and reliability in ensuring Salesforce security.</p>
    </div>
  </div>
  );
};

export default ThreeColumnLayout;
