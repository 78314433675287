import React from 'react'

export default function FieldsBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Here's how FSL Integration streamlines your field service operations<br/></h2>

        <div className="reasons__wrapper">
<p>Introducing Salesforce Field Service Lightning (FSL) Integration – a powerful solution designed to transform your field service game. By seamlessly integrating FSL with your existing Salesforce platform, you can empower your team, optimize workflows, and ultimately, deliver exceptional customer service.
</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Smarter Scheduling</b>
                 <p> FSL considers factors like technician skillset, location, and workload to assign the right person for the job. This ensures efficient scheduling and reduces wasted time.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Real-Time Visibility</b>
    <p>Gain a real-time overview of your entire field service operation. Track technician locations, job progress, and resource allocation – all on a single, unified platform.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Enhanced Communication</b>
                 <p>Break down communication silos. FSL facilitates seamless communication between dispatchers, technicians, and customers, ensuring everyone stays informed and on the same page.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3> */}
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Mobile Workforce Management</b>
                <p>Equip your technicians with the Field Service Mobile App. This empowers them to access work orders, update statuses, and collaborate with colleagues – even offline</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Improved Customer Satisfaction</b>
                 <p>Faster response times, efficient service delivery, and clear communication lead to happier customers. FSL Integration helps you build stronger customer relationships.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
