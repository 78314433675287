import React from 'react'

export default function SalesFuture() {
  return (
    <div className="info-container">
   
    <div className="info-text" style={{textAlign:'justify'}}>
      <h2>Future Directions
      </h2>
      <p style={{fontWeight:'500'}}>Looking forward, Tech Solutions Inc. plans to leverage Salesforce Sales Cloud to:
      </p>
      <p>
      Implement advanced analytics and AI-driven insights to identify trends, predict customer behavior, and optimize sales strategies.

      </p>
      <p>
      Expand integration with customer service and marketing platforms for seamless end-to-end customer journey management.
      </p>
      <p>
      Enhance mobile capabilities for sales teams to access real-time data and collaborate on the go.</p>
     
    </div>
    <div className="info-image">
      <img src="/assets/images/casestudy/sales.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
