import React from 'react'
import SalesCaseBanner from './compo/SalesCaseBanner'
import SalesClient from './compo/SalesClient'
import SalesSolution from './compo/SalesSolution'
import SalesImplementation from './compo/SalesImplementation'
import SalesResult from './compo/SalesResult'
import SalesFuture from './compo/SalesFuture'
import SalesConclusion from './compo/SalesConclusion'
import ContactForm from '../../Company/Contact Us/contactcompo/ContactForm'
import CertificateSlider from '../../../Home/Component/CertificateSlider'

export default function SalesCaseStudy() {
  return (
    <>
      <SalesCaseBanner/>
      <SalesClient/>
      <SalesSolution/>
      <SalesImplementation/>
      <CertificateSlider/>
      <SalesResult/>
      <SalesFuture/>
      <SalesConclusion/>
      <ContactForm/>

    </>
  )
}
