import React from 'react'
import ReviewBanner from './Compo/ReviewBanner'

import ReviewBenifits from './Compo/ReviewBenifits'
import ReviewBanner2 from './Compo/ReviewBanner2'
import ReviewWhy from './Compo/ReviewWhy'
import ReviewReadyBanner from './Compo/ReviewReadyBanner'
import Faq from '../../../Faq/Faq'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';
export default function SalesforceReview() {
  return (
    <>
        <Helmet>
        <title>Salesforce Security Review Experts - Protect Your Salesforce Data</title>
        <meta name="description" content="Protect your sensitive Salesforce data with a thorough security review from Saasverse. We offer expert services in the USA, UK, and Australia. Ensure data privacy and compliance. Contact us today!" />
      </Helmet>
      <ReviewBanner/>
      <ReviewBenifits/>
      <ReviewBanner2/>
      <ReviewWhy/>
      <CertificateSlider/>
      <Trustedby2/>
      <ReviewReadyBanner/>
      <Faq/>
    </>
  )
}
