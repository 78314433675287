import React from 'react'
import { Link } from 'react-router-dom'

export default function ExperienceCloudReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to transform the way you connect with your audience?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Contact Saasverse Pvt Ltd today and discover how our Experience Cloud expertise can help you
increase customer lifetime value by 20% or more through deeper engagement and
Reduce customer churn by 15% with personalized experiences that keep them coming back.

</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
