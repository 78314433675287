import React from 'react'
import './../css/Partner.css'
export default function Partner() {
  return (
    <div className="logo-grid-container">
    <h2 className="heading" style={{color:'black'}}>Our Technology & Industry Partners</h2>
    <p>We have partnered with high-class technology and industry leaders to deliver expert IT solutions to worldwide clients.</p>
    <div className="logo-grid">
      <div className="logo-item">
          
        <img src="/assets/images/aws22.png" alt="AWS Logo" className="logo-img" />
        {/* <p style={{color:'orange',}}>Learn more</p> */}
      </div>
      <div className="logo-item">
        <img src="/assets/images/salesforce.png" alt="Salesforce Logo" className="logo-img" />
        {/* <p>Learn more</p> */}
      </div>
      <div className="logo-item">
        <img src="/assets/images/micros.png" alt="Microsoft Logo" className="logo-img"  />
        {/* <p>Learn more</p> */}
      </div>
      <div className="logo-item">
        <img src="/assets/images/google.png" alt="Microsoft Logo" className="logo-img"  />
        {/* <p>Learn more</p> */}
      </div>
      <div className="logo-item">
        <img src="/assets/images/sitecore.png" alt="Microsoft Logo" className="logo-img"  />
        {/* <p>Learn more</p> */}
      </div>
    
    
    </div>
  </div>
  )
}
