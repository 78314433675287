import React from 'react'
import { Link } from 'react-router-dom'

export default function FieldsBanner2() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="bannerL">
    <div className="container-md">
      <div className="bannerL__container">
        <div className="bannerL__wrapper">
          <h2 className="bannerL__title">Unlock Unlimited Possibilities with Field Service Lightning Integration
          </h2>
          <h5 className="bannerL__subtitle">Book a call and discuss details with our experts.</h5>
          <div className="bannerL__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</Link></div>
          <div className="bannerL__list">
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">120+</h2>
              <h6 className="bannerL__list-item_text">Delivered projects</h6>
            </div>
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">50+</h2>
              <h6 className="bannerL__list-item_text">Salesforce certified specialists</h6>
            </div>
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">8+</h2>
              <h6 className="bannerL__list-item_text">years of experience</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
