import React from 'react'

export default function WhyB2B() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2 style={{fontSize:'25px'}}>Avail our services to manage efficiency and get the reasons why Saasverse Pvt Ltd must be your ultimate option.</h2>
      {/* <h4>Our Salesforce Architects offer a comprehensive skillset</h4> */}
    </div>
    <div className="columnt">
      <p><b>Deep Expertise</b><br/>A group of talented Salesforce-certified consultants specializing in the B2B segment.
      </p>
      <br/> <p><b>Customer Focus</b> <br/>A promise to listen to your business needs and provide solutions to fit your company’s distinctive requirements.
      </p>
    </div>
    <div className="columnt">
      <p><b>Proven Track Record</b><br/>Best practices of companies in different fields to prove that people can achieve change at their companies.</p>
      <br/> <p><b>Comprehensive Services</b><br/>One-stop solution for all your Salesforce B2B needs right from the planning stage to post-go-live support.</p>
    </div>
  </div>
  )
}
