import React from 'react'


import Faq from '../../Faq/Faq'


import HireQualityBanner from './hirequalitycompo/HireQualityBanner'
import HireQualityBenifits from './hirequalitycompo/HireQualityBenifits'
import HireQualityBanner2 from './hirequalitycompo/HireQualityBanner2'
import HireQualityReadyBanner from './hirequalitycompo/HireQualityReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhyQuality from './hirequalitycompo/WhyQuality'

import JustQuality from './hirequalitycompo/JustQuality'

export default function HireQuality() {
  return (
    <><HireQualityBanner/>
    <HireQualityBenifits/>
    <HireQualityBanner2/>
    {/* <HireQualityReadyBanner/> */}
    <WhyQuality/>
    <CertificateSlider/>
    <JustQuality/>
    <Trustedby2/>
    <HireQualityReadyBanner/>
    <Faq/>
      
    </>
  )
}
