import React from 'react'


import Faq from '../../Faq/Faq'

import HireDeveloperBanner from './hiredevelopercompo/HireDeveloperBanner'
import HireDeveloperBenifits from './hiredevelopercompo/HireDeveloperBenifits'
import HireDeveloperBanner2 from './hiredevelopercompo/HireDeveloperBanner2'
import HireDeveloperReadyBanner from './hiredevelopercompo/HireDeveloperReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseDev from './hiredevelopercompo/WhySaasverseDev'
import JustDeveloper from './hiredevelopercompo/JustDeveloper'


export default function HireDeveloper() {
  return (
    <><HireDeveloperBanner/>
    <HireDeveloperBenifits/>
    <HireDeveloperBanner2/>
    <WhySaasverseDev/>
    <CertificateSlider/>
    <JustDeveloper/>
    <Trustedby2/>
    <HireDeveloperReadyBanner/>
    <Faq/>
      
    </>
  )
}
