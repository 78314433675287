import React from 'react'

export default function WhySaasverse() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4>
    </div>
    <div className="columnt">
      <p><b>Certified AWS Experts</b><br/>Our team consists of certified AWS professionals with extensive experience in designing, deploying, and managing AWS solutions.</p>
      <br/> <p><b>Customized Solutions</b> <br/>We tailor our AWS development services to meet your specific business needs and objectives.</p>
    </div>
    <div className="columnt">
      <p><b>Commitment to Excellence</b><br/> We are dedicated to delivering high-quality services that exceed your expectations and drive business success.</p>
      <br/> <p><b>Proven Track Record</b><br/>Our successful track record and numerous satisfied clients demonstrate our ability to deliver effective AWS solutions.</p>
    </div>
  </div>
  )
}
