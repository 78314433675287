import React from 'react'
import HeaderSlider from './Component/HeaderSlider'
import AboutSaasverse from './Component/AboutSaasverse'
import OurServices from './Component/OurServices'
import TrustedPartner from './Component/TrustedPartner'
import Testimonial from './Component/Testimonial'
import Banner from './Component/Banner'
import Faq from '../Pages/Faq/Faq'
import Trustedby2 from './Component/TrustedBy2'
import CertificateSlider from './Component/CertificateSlider'
import HomeBann from './Component/HomeBann'
import Partner from './Component/Partner'
import ContactForm from '../Pages/Company/Contact Us/contactcompo/ContactForm'

export default function Home() {
  return (
    <>

      {/* <HeaderSlider/> */}
      {/* <HomeBann/> */}
      <HeaderSlider/>
      <AboutSaasverse/>
      <CertificateSlider/>
      <Partner/>
      <OurServices/>
      {/* <TrustedPartner/> */}
      <Trustedby2/>
      <Testimonial/>
      <Banner/>
      <Faq/>
      <ContactForm/>
    </>
  )
}
