import React from 'react'
import { Link } from 'react-router-dom'

export default function FieldsReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Transform your 
      operations with FSL implementation services</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Contact us to learn more about how FSL Integration can empower your business.
          </p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
