import React from 'react'
import { Link } from 'react-router-dom'

export default function B2BReady() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Let’s Get Started</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Are you a B2B business ready to maximise the use of Salesforce for your business? You can reach Saasverse Pvt Ltd for the consultation today. In unison, we will identify the comprehensive mid-term and long-term strategy that will maximize the usage of Salesforce to correspond to your goals.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
