import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import HireBaBanner from './hireBAchcompo/HireBaBanner'
import HireBaBenifits from './hireBAchcompo/HireBaBenifits'
import HireBaBanner2 from './hireBAchcompo/HireBaBanner2'
import HireBaReadyBanner from './hireBAchcompo/HireBaReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import JustBA from './hireBAchcompo/JustBA'
import WhySaasverseBA from './hireBAchcompo/WhySaasverseBA'

export default function HireBusiness() {
  return (
    <><HireBaBanner/>
    <HireBaBenifits/>
    <HireBaBanner2/>
    <WhySaasverseBA/>
    <CertificateSlider/>
    <JustBA/>
    <Trustedby2/>
    <HireBaReadyBanner/>
    <Faq/>
      
    </>
  )
}
