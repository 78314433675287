import React from 'react';


const ManagedWhy = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/2.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Experienced Professionals </b> <br/>Our team of certified Salesforce experts has extensive experience in managing and optimizing Salesforce environments.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Client-Focused Approach </b><br/>We work closely with you to understand your specific needs and deliver personalized solutions that drive results.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Commitment to Excellence</b><br/>We are dedicated to providing high-quality services that exceed your expectations and support your business goals.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
    </div>
  );
};

export default ManagedWhy;
