import React from 'react'
import { Link } from 'react-router-dom'
export default function ReviewBanner() {
  return (
    <div className="hero"  style={{backgroundImage: `url('/assets/images/banner/banner002.png')`, backgroundSize: "cover",
      backgroundPosition: "center"}}>
    <div className="hero-container">
      <div className="hero__content">
        <h1 className="hero__content_title" style={{color:'#006b95' }}>Salesforce <br/>Security Review</h1>
        <p className="hero__content_text">Ensure Your Salesforce Environment is Secure and Protected



        </p>
        <div className="hero__content_button button button-solid contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</a></div>
        <div className="hero__content_logos">
          <Link to='https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=bf4cc5b0-f7c4-4d44-99ac-1ddaa2ca27e1'  target="_blank" > <img className="hero__content_logos-item hero__content_logos-item-1" style={{height:'70px',borderRadius:'10px'}} src="/assets/images/salesforcepartner.png" alt="saasverse" /></Link>
          {/* <img className="hero__content_logos-item hero__content_logos-item-2" src="https://zpx.lch.mybluehost.me/wp-content/uploads/2023/04/OD-Web-Assets_SF-Logo.svg" style={{width:'200px'}} alt="saasverse" /> */}
        </div>
      </div>
      <div className="hero__image">
        <picture className="hire-cns">
          {/* <img src="https://magicfuse.co/_images/hero/page_hero_01_mobile.png" alt="Hire Salesforce Consultants" /> */}
        </picture>
      </div>
    </div>
  </div>
  )
}
