import React from 'react'

import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import NonProfitBanner from './nonprofitcompo/NonProfitBanner'
import NonProfitBenifits from './nonprofitcompo/NonProfitBenifits'
import NonProfitBanner2 from './nonprofitcompo/NonProfitBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import NonProfitReadyBanner from './nonprofitcompo/NonProfitReadyBanner'
import WhySaasverseNon from './nonprofitcompo/WhySaasverseNon'

export default function NonProfit() {
  return (
    <>
    <NonProfitBanner/>
    <NonProfitBenifits/>
    <NonProfitBanner2/>
    <WhySaasverseNon/>
    <CertificateSlider/>
    <Trustedby2/>
   <NonProfitReadyBanner/>
    <Faq/>
      
   </>
  )
}
