import React, { useEffect } from 'react';
import '../css/OurServices.css'; // Import your CSS for styling

const OurServices = () => {
  // const rotationStyle = {
  //   transform: 'rotate(-45deg)', /* Rotate by 45 degrees */
  //   transition: 'transform 0.5s' /* Smooth rotation */
  // };

  // Define partsData within the component
  const partsData = [
    {
      image1: 'https://dilabs-react.netlify.app/img/icon/1.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'Hire a Salesforce Developer',
      text: 'If you want to improve your Salesforce capabilities, you have come to the correct place. Our team of trained and exceptionally competent Salesforce developers can help you in creating customized solutions.'
    },
    {
      image1: '/assets/images/serviceslogo/salesforceconsultingnew.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'Salesforce Consulting',
      text: 'At Saasverse Technologies, we provide professional Salesforce consulting services to support organizations in making the best possible use of this dynamic cloud platform.'
    },
    {
      image1: '/assets/images/serviceslogo/Salesforce_Integration.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'Salesforce Integration Services',
      text: 'Our company specializes in providing companies of every sort Salesforce Integration Services. Our team of professionals has years of expertise connecting Salesforce with other platforms and programmes to boost productiveness.'
    },
    {
      image1: '/assets/images/serviceslogo/AWSNEW.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'Amazon Web Services (AWS)',
      text: 'Looking to apply AWS for improving your company operations? Our professional Amazon Web Services (AWS) can be helpful.'
    },
    {
      image1: '/assets/images/serviceslogo/CRMnew.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'CRM Implementation',
      text: 'Salesforce is a dynamic and flexible platform that has changed how companies handle business in the current technological age. It offers a variety of tools and services that can assist businesses in more successfully'
    },
    {
      image1: '/assets/images/serviceslogo/Salesforceappxchange.png',
      image2: '/assets/images/serviceslogo/Arrow1.png',
      heading: 'Salesforce AppExchange App Development',
      text: 'The largest business app store in the world, Salesforce AppExchange, offers more than 5000 solutions to expand and improve Salesforce CRM functionality'
    },
  ];

  // Function to handle scroll animations
  const handleScrollAnimations = () => {
    const elements = document.querySelectorAll('.part');
    elements.forEach(element => {
      if (isElementInViewport(element)) {
        element.classList.add('show');
      }
    });
  };

  // Function to check if element is in viewport
  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  // useEffect hook to add scroll event listener and initial check
  useEffect(() => {
    window.addEventListener('scroll', handleScrollAnimations);
    handleScrollAnimations(); // Initial check on mount
    return () => window.removeEventListener('scroll', handleScrollAnimations); // Cleanup
  }, []);

  return (
    <div className='service'>
      <p style={{color:'grey'}}>Things that we are expert in</p>
      <h2 style={{color:'black'}}> Our Services</h2>
      <div className="four-parts-container">
        {partsData.map((part, index) => (
          <div className="part" key={index}>
            <div className="part-images">
              <img src={part.image1} alt={`Part ${index + 1}`} className="part-image" />
              {/* <img src={part.image2} alt={`Part ${index + 2}`} className="part-image" /> */}
            </div>
            <h3 className="part-heading">{part.heading}</h3>
            <p className="part-text">{part.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
