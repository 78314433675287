import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function AdminPart() {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', ''); // Get section ID from URL hash
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' }); // Scroll to the section
      }
    }
  }, [location]);
  return (
    <div id="services" >
    <div className="cnt" id='adminpart'>
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}>Salesforce Admin Training</h2>

        <div className="reasons__wrapper">

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 1 <br/>Unlocking the Power of Cloud Computing</h3>
              <p className="reasons__item_text">
               
                 <p>Embark on a journey into the world of cloud computing, the backbone of Salesforce administration. Discover the benefits and intricacies of cloud computing service models, virtualization, and multi-tenant architecture. Gain a deeper understanding of how Salesforce leverages the cloud to provide a robust and scalable platform for managing customer relationships.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 2 <br/>Salesforce.com & CRM: Unleashing Customer Relationship Management</h3>
              <p className="reasons__item_text">
               
                 <p>Uncover the foundations of Salesforce.com and Customer Relationship Management (CRM). Explore how Salesforce revolutionizes customer interactions by automating and organizing sales, marketing, and support processes. Dive into the core concepts of CRM technology and discover how it drives business success.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 3 <br/>Salesforce Platform & Environments: Building the Future</h3>
              <p className="reasons__item_text">
               
                 <p>Unleash the power of the Salesforce Platform, a robust Platform-as-a-Service (PaaS) solution previously known as Force.com. Learn how this platform simplifies the development and deployment of cloud-based applications and websites. Explore the cloud Integrated Development Environment (IDE) and discover how to quickly deploy applications to Salesforce.com's multi-tenant servers.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 4 <br/>Data Modelling: Shaping Your Salesforce Universe</h3>
              <p className="reasons__item_text">
               
                 <p>Master the art of data modeling in Salesforce. Create a robust data structure by designing custom objects, variables, and connections. Leverage essential tools like Schema Builder and Object Manager to implement accurate and effective data analysis. Lay the foundation for data-driven decision-making and maximize the value of your Salesforce implementation.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 5 <br/>UI Customization: Crafting User Experiences</h3>
              <p className="reasons__item_text">
               
                 <p>Elevate user experiences through Salesforce UI customization. Delve into the world of page layouts, buttons, and appearance customization using the Lightning Experience. Streamline workflows, reduce manual data entry, and improve productivity with tailor-made interfaces. Master the art of UI customization for a seamless and intuitive user experience.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 6 <br/>Data Management: Nurturing Your Data Ecosystem</h3>
              <p className="reasons__item_text">
               
                 <p>Unlock the secrets of effective data management in Salesforce. Discover best practices for creating, storing, importing, and maintaining data. Ensure data quality, deduplication, backup, recovery, and compliance with regulations. Leverage powerful tools like Data Loader and Data Import Wizard to streamline data management processes.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 7 <br/>Data Security: Safeguarding Your Salesforce Fortress</h3>
              <p className="reasons__item_text">
               
                 <p>Master the art of data security in Salesforce. Learn how to protect sensitive data from unauthorized access, modification, or deletion. Explore features like two-factor authentication and role-based access control to ensure data integrity and maintain customer trust. Build a solid foundation of data security to safeguard your Salesforce ecosystem.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 8 <br/>Queues: Collaboration and Workload Sharing</h3>
              <p className="reasons__item_text">
               
                 <p>Harness the power of Salesforce Queues to boost collaboration and workload sharing. Create groups that enable multiple users to collaborate on shared records or cases. Automate business processes and assign work to relevant teams based on ownership or location criteria. Optimize efficiency in handling customer inquiries and streamline your operations.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 9 <br/>Process Automation: Streamlining Your Business</h3>
              <p className="reasons__item_text">
               
                 <p>Unleash the potential of process automation in Salesforce. Automate common tasks, emails, and data flows between entities using Lightning Flows and the Process Builder. Streamline your business processes and save valuable time and effort. Master the art of process automation and supercharge your productivity.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 10 <br/>Reports & Dashboards: Unleashing Data Insights</h3>
              <p className="reasons__item_text">
               
                 <p>Unlock the power of Salesforce Reports and Dashboards to gain actionable insights. Customize data analysis and visualization to gain a deeper understanding of your business. Filter, group, and summarize data to identify trends and make data-driven decisions. Share and schedule reports for delivery and access them on various devices. Empower your organization with data-driven decision-making.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 11 <br/>Sales Cloud: Empowering Sales Success</h3>
              <p className="reasons__item_text">
               
                 <p>Discover the Sales Cloud, Salesforce's cloud-based application designed to empower your sales team. Centralize customer information, log interactions, and automate sales tasks. Sell smarter and faster with a comprehensive suite of tools designed to drive sales success.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 12 <br/>Service Cloud: Elevating Customer Service</h3>
              <p className="reasons__item_text">
               
                 <p>Enter the realm of the Service Cloud, Salesforce's solution for exceptional customer service. Manage accounts, contacts, cases, and solutions to support your customer service efforts. Retain existing customers and provide exceptional support with a comprehensive suite of tools designed to meet customer service requests.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 13 <br/>MVC Architecture: Building Solid Foundations</h3>
              <p className="reasons__item_text">
               
                 <p>Dive into the Model-View-Controller (MVC) architecture, a software architectural pattern commonly used for developing user interfaces. Explore how this pattern separates program logic into three interconnected elements, enabling robust and scalable application development. Master the foundations of MVC architecture for building powerful Salesforce applications.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 14 <br/>Activity Management: Mastering Productivity</h3>
              <p className="reasons__item_text">
               
                 <p>Optimize your productivity with Salesforce's Activity Management features. Track tasks and meetings, prioritize your time, and stay up to date with your accounts, campaigns, contacts, leads, and opportunities. Streamline your activity management processes and boost your efficiency.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Chapter 15 <br/>Miscellaneous Tools: Unleashing the Full Potential</h3>
              <p className="reasons__item_text">
               
                 <p>Explore a range of powerful declarative tools available in Salesforce. From mass deletes and transfers to a myriad of other features, uncover hidden gems that enhance your Salesforce administration toolkit. Maximize the potential of these tools to drive success in your Salesforce implementation.
                 </p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

        
        


      


        
       
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
