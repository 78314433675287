import React from 'react'

export default function ReadyKentico() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Transform Your Digital Presence with Kentico</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Unlock the full potential of your digital strategy with Saasverse Pvt Ltd’s Kentico Development Services. Contact us today to discuss your Kentico needs and embark on a journey towards digital excellence.
          </p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
