import React from 'react'

export default function B2BHeading() {
  return (
    <div className="info-container">
      
      <div className="info-text">
        <h2 style={{fontSize:'30px'}}>Transform Your Business Higher Dimension with Salesforce B2B Solution by Saasverse Pvt Ltd
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
        Therefore, carrying out business in the contemporary world especially B2B commerce demands flexibility and a strong foundation of technology that addresses customer needs. Welcome to Saasverse Pvt Ltd where we focus and apply salesforce to design unique Business-to-business solutions for growth, customer satisfaction, and efficiency. Thus, with our set of comprehensive Salesforce B2B services, we facilitate commercial entities’ growth, including yours.

        </p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/b2bb2c/6.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  )
}
