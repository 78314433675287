import React from 'react'

export default function ServiceCloudBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Key Benefits of Our Approach<br/></h2>

        <div className="reasons__wrapper">
<p>Saasverse Pvt Limited specializes in transforming service operations through advanced Service Cloud solutions. We empower businesses to deliver exceptional customer service and optimize operational efficiency.</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Real-time Insights</h3>
              <p className="reasons__item_text">
                 {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                 <p>Monitor service metrics in real-time to proactively address issues and optimise performance.
                 </p>


{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Intuitive Dashboards</h3>
              <p className="reasons__item_text">  
    {/* <b style={{color:'#006b95'}}>Customizable Reporting</b> */}
    <p>Visualize data with customizable dashboards for actionable insights and informed decision-making.</p>
 

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Scalable Solutions</h3>
              <p className="reasons__item_text">
              
                 <p>Grow your service capabilities with scalable solutions that adapt to your business needs and customer demands.

                 </p>


{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


        
      
         

         
   
        </div>
      </div>
    </div>
  </div>
  )
}
