import React from 'react'

export default function SalesResult() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center' }}>Results</h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Enhanced Efficiency</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Increased Sales Productivity</b>
    <p>Streamlined processes and automation reduced administrative tasks, enabling sales teams to focus more on selling and building customer relationships.</p>
   


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Improved Sales Performance</b>
                <p>Enhanced visibility into sales metrics and pipeline health led to improved forecasting accuracy and strategic decision-making.
                </p>


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Hybrid Training</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Enhanced Customer Experience</b>
                 <p>Personalized interactions and timely follow-ups improved customer satisfaction and retention rates.</p>


</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  )
}
