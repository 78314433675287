import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseProject = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Hire a Salesforce Project Manager from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Proven Track Record</b> <br/>Our PMs are Salesforce-certified experts with a history of delivering successful Salesforce projects on time and within budget.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Deep Expertise</b><br/>They possess not only technical prowess in Salesforce but also strong project management skills. This ensures smooth execution, clear communication, and effective risk mitigation.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Business Acumen</b><br/>Our PMs understand the broader business context of your project. They translate your goals into actionable plans and ensure the Salesforce solution aligns with your overall strategy.</p>
        <p>
         <b style={{color:'#006b95'}}>Effective Communication</b><br/>They bridge the gap between technical teams and business stakeholders, fostering clear communication and seamless collaboration throughout the project lifecycle.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Agile Methodology</b><br/>Our PMs are adept at agile project management, allowing for flexibility and continuous improvement as your project unfolds.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseProject;
