import React from 'react';
import './Compo/css/AppExchangeWhy.css'; 

const AppExchangeWhy = () => {
  return (
    <div className="info-container">
   
    <div className="info-text">
      <h2> Why Choose Salesforce AppExchange App Development?
      </h2>
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>Salesforce AppExchange is the world's leading enterprise cloud marketplace, offering a plethora of applications and solutions to enhance your Salesforce experience. Developing custom apps for the AppExchange allows you to:</p>
      <p>
       <b style={{color:'#006b95'}}>Meet Specific Business Needs </b> <br/>Address your business's unique challenges and requirements with tailored solutions.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Extend Salesforce's Capabilities </b><br/>Expand Salesforce's functionality to better serve your business processes and goals.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Reach a Global Audience</b><br/>Gain exposure to millions of Salesforce users worldwide by listing your app on the AppExchange.</p>
      {/* <p>
       <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
     */}
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/6.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  );
};

export default AppExchangeWhy;
