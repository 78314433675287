import React from 'react';
import './css/Gallary.css';

const Gallary = () => {
  const images = [
    '/assets/images/gallary/offc.png', // Use the image you want to display
    // ...other images if needed
  ];

  return (
    <div className="gallery-container00">
      <h2>Our Gallery</h2>
      <div className="gallery-grid00">
        <div className="gallery-item00 full-height00">
          <img src={images[0]} alt="Gallery" />
        </div>
      </div>
    </div>
  );
};

export default Gallary;
