import React, { useState } from 'react';


export default function AWSFaq() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };
    const toggleContent1 = () => {
        setIsOpen1(!isOpen1);
    };
    const toggleContent2 = () => {
        setIsOpen2(!isOpen2);
    };
    const toggleContent3 = () => {
        setIsOpen3(!isOpen3);
    };
    const toggleContent4 = () => {
        setIsOpen4(!isOpen4);
    };
    const toggleContent5 = () => {
        setIsOpen5(!isOpen5);
    };
    const toggleContent6 = () => {
        setIsOpen6(!isOpen6);
    };

    return (
        <>
            {/* <div className="faq-divider"></div> */}
            <div className="faq-container">
                <div className="faq-row">
                    <h2 className="faq-title" style={{color:'black'}}>FAQ's</h2>
                    <div className="faq-collapse">
                        <div className="faq-accordion">
                            <div className="faq-accordion-content" itemScope itemType="https://schema.org/FAQPage">

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen ? 'faq-active' : ''}`} onClick={toggleContent}>
                                    What is AWS and why should I use it for my business?
                                    </div>
                                    <div className={`faq-card-content ${isOpen ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        AWS (Amazon Web Services) is the leading cloud computing platform offering a wide range of services, including computing power, storage, and databases. Using AWS allows businesses to innovate faster, reduce costs, enhance security, and scale their operations effortlessly.
                                        </p>
                                    </div>
                                </div>

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen1 ? 'faq-active' : ''}`} onClick={toggleContent1}>
                                    How can Saasverse Pvt Ltd help with AWS migration?
                                    </div>
                                    <div className={`faq-card-content ${isOpen1 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Saasverse provides comprehensive AWS migration services, including assessment, planning, execution, and post-migration optimization. We ensure a seamless transition with minimal disruption to your business operations.
                                        </p>
                                    </div>
                                </div>

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen2 ? 'faq-active' : ''}`} onClick={toggleContent2}>
                                    What are cloud-native applications, and how can they benefit my business?
                                    </div>
                                    <div className={`faq-card-content ${isOpen2 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Cloud-native applications are designed to run in cloud environments, leveraging services like AWS Lambda and ECS for scalability and resilience. These applications improve agility, reduce operational overhead, and enhance performance.
                                        </p>
                                    </div>
                                </div>

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen3 ? 'faq-active' : ''}`} onClick={toggleContent3}>
                                    What is Infrastructure as Code (IaC) and why is it important?
                                    </div>
                                    <div className={`faq-card-content ${isOpen3 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Infrastructure as Code (IaC) is a practice of managing and provisioning computing infrastructure through machine-readable scripts. IaC improves consistency, reduces manual errors, and allows for automated and repeatable deployments.
                                        </p>
                                    </div>
                                </div>

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen4 ? 'faq-active' : ''}`} onClick={toggleContent4}>
                                    How does Saasverse ensure the security of my AWS environment?
                                    </div>
                                    <div className={`faq-card-content ${isOpen4 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Saasverse implements AWS best practices for security, including encryption, access control, and continuous monitoring. We also ensure compliance with industry standards and regulations.
                                        </p>
                                    </div>
                                </div>

                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen5 ? 'faq-active' : ''}`} onClick={toggleContent5}>
                                    Can Saasverse help with setting up DevOps practices?
                                    </div>
                                    <div className={`faq-card-content ${isOpen5 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Yes, Saasverse specializes in implementing DevOps practices, including CI/CD pipelines, automated testing, and infrastructure automation, to streamline your development and deployment processes.
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                    <div className={`faq-card-title ${isOpen6 ? 'faq-active' : ''}`} onClick={toggleContent6}>
                                    What kind of support can I expect after the AWS implementation?
                                    </div>
                                    <div className={`faq-card-content ${isOpen6 ? 'faq-show' : ''}`}>
                                        <p className="faq-card-text">
                                        Saasverse offers ongoing support and maintenance services, including monitoring, optimization, and troubleshooting, to ensure your AWS environment remains efficient and secure.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
