import React from 'react'

export default function KenticoPower() {
  return (
    <div className="client-info-containerb">
    <div className="client-backgroundb" >
      <h2> Unlock the Power of <span style={{color:'#003e95'}}>Kentico</span> with Saasverse Pvt Ltd</h2>
      <p>At Saasverse Pvt Ltd, we bring you top-tier Kentico Development Services to revolutionize your digital presence. As a leading provider of Kentico solutions, we specialize in creating robust, scalable, and personalized digital experiences that drive business growth.
      </p>
     <p>Our team of certified Kentico experts is dedicated to delivering tailored solutions that meet your unique business needs.</p>
    </div>
  
  </div>
  )
}
