import React from 'react';
// import './../../SalesforceConsulting/compo/Why.css'; 

const IntegrationWhy = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/4.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Choose Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Expert Integration Team </b> <br/>Our team of certified Salesforce professionals brings extensive experience and technical expertise to every integration project.


        </p>
        <p>
          <b style={{color:'#006b95'}}>Tailored Solutions  </b><br/>We deliver customized integration solutions that are specifically designed to meet your unique business requirements.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Commitment to Excellence</b><br/>We are dedicated to providing exceptional service, ensuring seamless integration and optimal performance.</p>
      
        <p>
          <b style={{color:'#006b95'}}>Proven Success  </b><br/>Our track record of successful integrations across various industries highlights our ability to deliver reliable and effective solutions.
        </p>
        {/* <p>
         <b style={{color:'#006b95'}}>Proven Track Record:</b><br/>Our successful track record speaks for itself, with numerous satisfied clients and successful custom development projects across various industries.  </p> */}
      
      </div>
    </div>
  );
};

export default IntegrationWhy;
