import React from 'react'

export default function IntegrationBenifits() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}>Our Salesforce Integration Services<br/></h2>
        <div className="reasons__wrapper">

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">System Integration</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>CRM Integration:</b>
                 <p> Connect Salesforce with other CRM systems to unify customer data and streamline sales and marketing processes.
                 </p>
<b style={{color:'#006b95'}}> ERP Integration: </b> 
<p>  Integrate Salesforce with your ERP system to synchronize financial, inventory, and operational data for better resource planning.
</p>
<p><b style={{color:'#006b95'}}> Marketing Automation Integration:</b> Link Salesforce with marketing automation platforms to streamline campaigns and improve lead management.</p>
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Comprehensive Needs Assessment</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}> Business Analysis:</b>
    <p>We start by understanding your business processes, goals, and current systems to identify integration opportunities.
    </p>
   
<b style={{color:'#006b95'}}>Integration Strategy:</b>
<p> Develop a tailored integration strategy that aligns with your business objectives and technical requirements.

</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
         


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Custom API Development</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> API Design:</b>
                 <p> Develop custom APIs to facilitate seamless communication between Salesforce and your other business applications.

                 </p>
<b style={{color:'#006b95'}}>  Data Synchronization:</b> 
<p> Ensure real-time data synchronization across all systems, enabling accurate and up-to-date information.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Middleware Solutions
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}> Integration Platforms:</b>
                <p>Utilize leading integration platforms like MuleSoft, Dell Boomi, and Jitterbit to connect Salesforce with various systems and applications.</p>
<b style={{color:'#006b95'}}> Custom Middleware: </b> 
<p>  Develop custom middleware solutions to address unique integration challenges and requirements.</p>

</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title"> Data Migration and Management</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Data Mapping:</b>
                 <p>Map data fields between Salesforce and other systems to ensure accurate data transfer.

                 </p>
<b style={{color:'#006b95'}}>Secure Migration:</b> 
<p>Conduct secure and efficient data migration, maintaining data integrity and compliance throughout the process.</p>
{/* <p><b style={{color:'#006b95'}}>Lightning Components:</b> We create dynamic and responsive Lightning Components to enhance the user experience and functionality.</p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title"> Post-Integration Support</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Ongoing Monitoring:</b>
                 <p>Provide continuous monitoring and support to ensure the integration operates smoothly and efficiently.

                 </p>
<b style={{color:'#006b95'}}>Performance Optimization:</b> 
<p>Regularly review and optimize the integration to adapt to evolving business needs and maximize performance.</p>
{/* <p><b style={{color:'#006b95'}}>Lightning Components:</b> We create dynamic and responsive Lightning Components to enhance the user experience and functionality.</p> */}
</p>
            </div>
          </div>
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
