import React from 'react'

export default function ServiceFuture() {
  return (
    <div className="info-container">
   
    <div className="info-text" style={{textAlign:'justify'}}>
      <h2>Future Directions
      </h2>
      <p style={{fontWeight:'500'}}>Looking ahead, GTS plans to leverage Salesforce Service Cloud to:
      </p>
      <p>
      Implement AI-driven chatbots and self-service portals to further enhance customer support capabilities and reduce workload on service agents.

      </p>
      <p>
      Expand integration with IoT devices to proactively monitor and resolve service issues for customers during their travel experiences.
      </p>
      <p>
      Enhance workforce management capabilities to optimize agent scheduling and resource allocation based on service demand.</p>
     
    </div>
    <div className="info-image">
      <img src="/assets/images/casestudy/service2.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
