import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const formRef = useRef();
  const recaptchaRef = useRef();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the form
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone number is required';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Verify reCAPTCHA token before sending email
    const recaptchaToken = recaptchaRef.current.getValue();
    if (!recaptchaToken) {
      console.error('reCAPTCHA validation failed.');
      return;
    }

    // Send email using EmailJS
    emailjs.sendForm('service_kiv85vk', 'template_7x2ol6o', formRef.current, 'jalNTudOd7j3_sOCA')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSubmitted(true);
      }, (error) => {
        console.error('Error sending email:', error.text);
      });
  };

  return (
    <div className="contact-form-container">
      <div className='innerf'>
        <div className="contact-form-left">
          <h2>CONTACT US</h2>
          <h3>Streamline Your Business Operations With Our
          Salesforce Implementation Solutions!</h3>
          <div className='assured'>
            <img className='tickimg1' src='/assets/images/form/assure.svg' alt='tick1'/>
            <p>100% Confidential</p>
            <img className='tickimg2' src='/assets/images/form/assure.svg' alt='tick2'/>
            <p>We sign NDA</p>
          </div>
          <div style={{borderBottom:'1px solid grey', marginTop:'30px'}}></div>
          <div className='emaill'>
            <img className='tickimg1' src='/assets/images/form/office-mail.svg' alt='office'/>
            <div className='email2'>
              <p style={{fontSize:'16px'}}>Or, Reach Out To Us At</p>
              <p><a href="mailto:contact@saasverse.in" style={{color:'white', textDecoration:'none', fontSize:'24px'}}>contact@saasverse.in</a></p>
            </div>
          </div>
        </div>
        <div className="contact-form-right">
          <h2>Fill out the form and we will contact you</h2>
          {submitted ? (
          
            <p style={{ textAlign: 'center', fontSize: '30px', marginTop: '30px', color:'white' }}>Thank you for your message!<br /> We'll get back to you soon.</p>
          
          ) : (
            <form onSubmit={handleSubmit} className="contact-form" ref={formRef}>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    placeholder='Your Name*'
                    onChange={handleInputChange}
                    required
                  />
                  {errors.name && <p className="error-text">{errors.name}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    placeholder='Email Address*'
                    onChange={handleInputChange}
                    required
                  />
                  {errors.email && <p className="error-text">{errors.email}</p>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    placeholder='Phone Number*'
                    onChange={handleInputChange}
                    required
                  />
                  {errors.phone && <p className="error-text">{errors.phone}</p>}
                </div>
                <div className="form-group">
                  <select
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Choose Your Interest</option>
                    <option value="Hire Salesforce Developer">Hire Salesforce Developer</option>
                    <option value="Salesforce Consulting Service">Salesforce Consulting Service</option>
                    <option value="Salesforce Training">Salesforce Training</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.subject && <p className="error-text">{errors.subject}</p>}
                </div>
              </div>
              <div className="form-group">
                <textarea
                  style={{ height: '80px' }}
                  id="message"
                  name="message"
                  value={formData.message}
                  placeholder='Message *'
                  onChange={handleInputChange}
                  required
                />
                {errors.message && <p className="error-text">{errors.message}</p>}
              </div>
              {/* Add reCAPTCHA v3 component */}
              <ReCAPTCHA
                sitekey="6LcK3BIqAAAAAPWk1p1zxsEastCG8K1gJuU3D0IL"
                onChange={() => {}}
                ref={recaptchaRef}
              />
              <button type="submit" className="submit-button">Book A Free Consultation</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
