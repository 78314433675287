import React from 'react';

const ReviewReadyBanner = () => {
  return (


<div className="cnt_banner">
<div className="banner__container">
  <h2 className="banner__heading">Secure Your Salesforce Environment Today</h2>
  <div className="banner__text-wrapper">
    <div className="banner__text-content">
      <p>Protect your business and data with Saasverse Pvt Ltd's Salesforce Security Review services. Contact us now to schedule a comprehensive assessment and ensure the security and integrity of your Salesforce environment.</p>
    </div>
    <div className="banner__button-wrapper">
      <div className="banner__button button button-outline contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
    </div>
  </div>
</div>
</div>
  );
}

export default ReviewReadyBanner;
