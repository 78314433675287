import React, { useState } from 'react';
import './Faq.css';

export default function Faq() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);

    const toggleContent = () => setIsOpen(!isOpen);
    const toggleContent1 = () => setIsOpen1(!isOpen1);
    const toggleContent2 = () => setIsOpen2(!isOpen2);
    const toggleContent3 = () => setIsOpen3(!isOpen3);
    const toggleContent4 = () => setIsOpen4(!isOpen4);
    const toggleContent5 = () => setIsOpen5(!isOpen5);

    return (
        <div className="faq-container">
            <h2 className="faq-title" style={{color: 'black'}}>FAQ's</h2>
            <div className="faq-collapse">
                <div className="faq-accordion" itemScope itemType="https://schema.org/FAQPage">
                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen ? 'faq-active' : ''}`} onClick={toggleContent} itemProp="name">
                            Why hire Salesforce Consultants?
                        </div>
                        <div className={`faq-card-content ${isOpen ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Hiring Salesforce Consultants will make sure that your Salesforce implementation is perfectly aligned with your business goals and delivers maximum ROI.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen1 ? 'faq-active' : ''}`} onClick={toggleContent1} itemProp="name">
                            Can you customize Salesforce to fit my business needs?
                        </div>
                        <div className={`faq-card-content ${isOpen1 ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Absolutely. Our Consultants specialize in customizing Salesforce solutions – be it CRM, Marketing Cloud, Mobile App development, or CDP – to meet your specific business requirements.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen2 ? 'faq-active' : ''}`} onClick={toggleContent2} itemProp="name">
                            What's the typical engagement process with Saasverse Consultants?
                        </div>
                        <div className={`faq-card-content ${isOpen2 ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Our engagement process is client-centric, starting with understanding your business needs, followed by providing strategic Salesforce consultancy tailored to those needs. We work either on a project basis or time & material contracts.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen3 ? 'faq-active' : ''}`} onClick={toggleContent3} itemProp="name">
                            How long does it take to hire Saasverse Consultants?
                        </div>
                        <div className={`faq-card-content ${isOpen3 ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Our efficient hiring process ensures that you can quickly onboard a skilled Salesforce Consultant, often within a few days to a few weeks, depending on your specific requirements.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen4 ? 'faq-active' : ''}`} onClick={toggleContent4} itemProp="name">
                            Can Saasverse provide ongoing support after implementation?
                        </div>
                        <div className={`faq-card-content ${isOpen4 ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Yes, we offer ongoing support and consultancy to ensure your Salesforce ecosystem continues to evolve with your business, delivering continual value.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen5 ? 'faq-active' : ''}`} onClick={toggleContent5} itemProp="name">
                            How do I get started with hiring Salesforce Consultants from Saasverse?
                        </div>
                        <div className={`faq-card-content ${isOpen5 ? 'faq-show' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p className="faq-card-text" itemProp="text">
                                Starting is simple. Contact us, and we'll guide you through our straightforward process to connect you with the ideal Salesforce consultant for your needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
