import React from 'react'


import Faq from '../../Faq/Faq'



import EducationBanner from './educationcompo/EducationBanner'
import EducationBenifits from './educationcompo/EducationBenifits'
import EducationBanner2 from './educationcompo/EducationBanner2'
import EducationReadyBanner from './educationcompo/EducationReadyBanner'
import WhySaasverseEdu from './educationcompo/WhySaasverseEdu'

export default function Education() {
  return (
    <><EducationBanner/>
    <EducationBenifits/>
    <EducationBanner2/>
    <WhySaasverseEdu/>
    <EducationReadyBanner/>
    <Faq/>
      
    </>
  )
}
