import React from 'react'

export default function IsvBenifits() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}> Our Salesforce ISV Development Services <br/><br/></h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Application Ideation and Planning</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Market Research:</b>
    <p>We conduct thorough market research to identify opportunities and validate your app concept. </p>
   
<b style={{color:'#006b95'}}> Strategic Planning:</b>
<p> Our team works with you to develop a strategic plan, outlining the app's features, target audience, and go-to-market strategy.
</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">AppExchange Readiness</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Security Review Preparation:</b>
                 <p> We ensure your application meets Salesforce's security standards and guide you through the security review process.
                 </p>
<b style={{color:'#006b95'}}>Packaging and Deployment:</b> 
<p> Our experts handle the packaging and deployment of your app on the AppExchange, ensuring a smooth and hassle-free launch.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Post-Launch Support</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Ongoing Maintenance:</b>
                 <p> We provide continuous support and maintenance to ensure your application remains up-to-date and performs optimally.
                 </p>
<b style={{color:'#006b95'}}> User Training:</b> 
<p> We offer comprehensive training to help your team and customers get the most out of your new application.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Custom App Development
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Tailored Solutions:</b>
                <p>We design and develop custom applications that meet your unique business requirements and stand out in the Salesforce ecosystem.</p>
<b style={{color:'#006b95'}}> Apex and Visualforce Development::</b> 
<p>Our developers use Salesforce's powerful programming languages to create robust and scalable solutions.</p>
<b style={{color:'#006b95'}}>Lightning Components:</b>
<p>We build responsive Lightning Components to enhance user experience and functionality. </p>
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Integration Services</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Seamless Integration:</b>
                 <p>  We integrate your application with other systems and platforms to ensure smooth data flow and operational efficiency.
                 </p>
<b style={{color:'#006b95'}}>API Development:</b> 
<p>Our team develops APIs to facilitate seamless communication between your app and other software solutions.</p>
<p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p>
</p>
            </div>
          </div>
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
