// CareerBanner.js
import React from 'react';
import './css/Banner.css';

export default function CareerBanner({ onScrollToJobOpenings }) {
  return (
    <section className="about-us-banner">
      <div className="banner-container">
        <picture>
          <img src="/assets/images/banner/careerbanner.png" alt="About Us" />
        </picture>
        <div className="banner-content-overlay">
          <h1>Careers <br />Our Success Starts With You</h1>
          <p>We are looking for Phenomenal people, who are willing to join industries’ leading team.</p>
          <button
            style={{ padding: '15px', borderRadius: '30px', fontSize: '16px', marginTop: '10px' }}
            onClick={onScrollToJobOpenings}
          >
            <b>View Current Openings</b>
          </button>
        </div>
      </div>
    </section>
  );
}
