import React from 'react'
import AWSBanner from './compo/AWSBanner'
import AWSServices from './compo/AWSServices'
import WhyAWS from './compo/WhyAWS'
import WhySaasverse from './compo/WhySaasverse'
import ReadyAWS from './compo/ReadyAWS'
import AWSFaq from './compo/AWSFaq'
import AWSPower from './compo/AWSPower'

export default function ServiceAWS() {
  return (
    <>
      <AWSBanner/>
      <AWSPower/>
      <WhyAWS/>
      <AWSServices/>
      <WhySaasverse/>
      <ReadyAWS/>
      <AWSFaq/>
    </>
  )
}
