import React from 'react'

export default function B2CBanner() {
  return (
    <div className="hero"  style={{backgroundImage: `url('/assets/images/b2bb2c/1.jpg')`, backgroundSize: "cover",
        backgroundPosition: "center"}}>
  
      <div className="hero-container">
        <div className="hero__content">
          <h1 className="hero__content_title" style={{color:'white' }}>Salesforce B2B & B2C Services</h1>
          <p className="hero__content_text" style={{color:'white' }}>Transform Your Business Higher Dimension with Salesforce B2B & B2C Solution by Saasverse Pvt Ltd
          </p>
          <div className="hero__content_button button button-solid contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</a></div>
          <div className="hero__content_logos">
            <img className="hero__content_logos-item hero__content_logos-item-1" style={{height:'70px',borderRadius:'10px'}} src="/assets/images/salesforcepartner.png" alt="saasverse" />
            {/* <img className="hero__content_logos-item hero__content_logos-item-2" src="https://magicfuse.co/_images/badges/hero-2.svg" alt="saasverse" /> */}
          </div>
        </div>
        <div className="hero__image">
          <picture className="hire-cns">
            {/* <img src="/assets/images/4444.png"  style={{height:'500px'}} alt="Hire Salesforce Consultants" /> */}
          </picture>
        </div>
      </div>
    </div>
  )
}
