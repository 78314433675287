import React from 'react'
import ServiceCloudBanner from './servicecompo/ServiceCloudBanner'
import ServiceCloudBenifits from './servicecompo/ServiceCloudBenifits'
import ServiceCloudBanner2 from './servicecompo/ServiceCloudBanner2'

import Faq from '../../Faq/Faq'
import WhySaasverse from './servicecompo/WhySaasverse'
import ThreeColumnLayout from '../../Services/Salesforce/SalesforceAppExchange/Compo/ThreeColumnLayout'
import ClientSuccessStories from './servicecompo/ClientSuccessStories'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import ServiceCloudReadyBanner from './servicecompo/ServiceCloudReadyBanner'

export default function ServiceCloud() {
  return (
    <>
      <ServiceCloudBanner/>
      <ServiceCloudBenifits/>
      <ServiceCloudBanner2/>
      <WhySaasverse/>
      {/* <ThreeColumnLayout/> */}
      {/* <ClientSuccessStories/> */}
      <CertificateSlider/>
      <Trustedby2/>
      <ServiceCloudReadyBanner/>
      <Faq/>
    </>
  )
}
