import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function CpqPart() {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', ''); // Get section ID from URL hash
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' }); // Scroll to the section
      }
    }
  }, [location]);
  return (
    <div id="servicess" >
    <div className="cnts" id='cpqpart'>
      <div className="rows">
        <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Salesforce CPQ Training</h2>

        <div className="reasons__wrappers">

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 1: Introduction to Salesforce CPQ</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>-Overview of Salesforce CPQ and its role in sales quoting</p>

 <p>-Understanding the benefits and features of CPQ</p>

<p>-Exploring the components and architecture of CPQ</p>

                </p>


              </p>
            </div>
          </div>


          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 2: Product Catalog and Configuration</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Setting up a product catalog and pricing rules</p>

 <p>- Defining product families, bundles, and options</p>

<p>- Configuring product attributes and constraints</p>
<p>- Creating guided selling and product selection rules</p>

                </p>
 
              </p>
            </div>
          </div>


          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 3: Pricing and Discounting</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Managing pricing strategies and price books</p>

 <p>- Configuring price rules and price matrices</p>

<p>- Defining discount schedules and discounting methods</p>
<p>- Implementing volume-based pricing and tiered pricing</p>

                </p>


              </p>
            </div>
          </div>


          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 4: Quote Creation and Configuration</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Creating and managing quotes in Salesforce CPQ</p>

 <p>- Customizing quote templates and document generation</p>

<p>- Configuring quote line editor and quote line fields</p>
<p>- Implementing quote approval processes</p>

                </p>


              </p>
            </div>
          </div>
    

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 5: Quote Calculation and Pricing Rules</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Understanding quote line calculation and pricing rules</p>

 <p>- Configuring quote line editor and quote line fields</p>

<p>- Implementing custom calculations and formula fields</p>
<p>- Setting up pricing rules for complex pricing scenarios</p>

                </p>
                
              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 6: Quote Amendments and Renewals</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Managing quote amendments and renewals in CPQ</p>

 <p>- Creating renewal opportunities and contracts</p>

<p>- Implementing quote line amendments and updates</p>
<p>- Tracking quote changes and versioning</p>

                </p>

              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 7: Contract Lifecycle Management</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Utilizing contracts and contract management in CPQ</p>

 <p>- Creating and managing service contracts and subscriptions</p>

<p>- Implementing contract amendments and renewals</p>
<p>- Tracking contract terms, renewals, and terminations</p>

                </p>

              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 8: Approval Processes and Workflow Automation</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Configuring approval processes for quotes and contracts</p>

 <p>- Implementing workflow rules and automation</p>

<p>- Defining approval steps and escalation rules</p>
<p>- Monitoring and managing approval history and notifications</p>

                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 9: Integration and Data Management</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Integrating Salesforce CPQ with other Salesforce products and systems</p>

 <p>- Implementing data synchronization and integration patterns</p>

<p>- Managing data quality and data migration for CPQ</p>
<p>- Utilizing data import/export tools and APIs</p>

                </p>
                

              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 10: CPQ Administration and Customization</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>- Administering and customizing CPQ to meet business requirements</p>

 <p>- Configuring CPQ settings and preferences</p>

<p>- Customizing page layouts, record types, and fields</p>
<p>- Implementing CPQ-specific automation and workflows</p>

                </p>

              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  )
}
