import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseDev = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/6.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Hire a Salesforce Developer from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Proven Expertise</b> <br/>Our developers are Salesforce-certified professionals with a deep understanding of the platform's architecture, development tools, and best practices.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Custom Application Development</b><br/>They excel at crafting custom applications that address your unique business challenges and streamline critical workflows.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Integration Experts</b><br/> Our developers seamlessly integrate Salesforce with your existing business systems, eliminating data silos and fostering a unified data landscape.</p>
        <p>
         <b style={{color:'#006b95'}}>Automation Champions</b><br/>They leverage automation tools to automate repetitive tasks, freeing up your team's time for higher-value activities.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Apex & Visualforce Proficiency</b><br/> Our developers are masters of Salesforce's core programming languages (Apex & Visualforce), ensuring robust and scalable solutions</p>
      
        <p>
         <b style={{color:'#006b95'}}>Testing & Deployment Expertise</b><br/>They prioritize rigorous testing throughout the development lifecycle and ensure smooth deployment of your Salesforce customizations.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseDev;
