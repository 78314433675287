import React from 'react'

export default function SalesConclusion() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Conclusion</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Salesforce Sales Cloud has empowered Tech Solutions  Inc. to streamline their sales processes, improve sales team efficiency, and drive business growth. By leveraging Salesforce's robust features for lead management, opportunity tracking, and analytics, Tech Solutions Inc. has achieved significant improvements in sales performance and customer satisfaction, solidifying their position as a leader in the technology services industry.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
