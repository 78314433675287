import React from 'react'

export default function NonProfitBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Here's how a Nonprofit Consultant can amplify your impact<br/></h2>

        <div className="reasons__wrapper">
<p>The nonprofit sector is a driving force for positive change. But navigating the complexities of fundraising, strategy, and impact measurement can be challenging. This is where a Nonprofit Consultant steps in – a dedicated partner who can help your organization maximize its impact and empower your mission.</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Strategic Planning</b>
                 <p>Develop a clear and actionable roadmap to achieve your organizational goals. A consultant will guide you through strategic planning processes, analyzing your strengths, weaknesses, opportunities, and threats (SWOT analysis) to create a future-proof plan.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Fundraising Expertise</b>
    <p>Unleash the full potential of your fundraising efforts. Consultants identify funding opportunities, develop compelling grant proposals, and craft effective fundraising campaigns.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Program Optimization</b>
                 <p>Ensure your programs are delivering the greatest social good. Consultants can evaluate program effectiveness, suggest improvements, and help you measure the impact of your work.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3> */}
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Capacity Building</b>
                <p>Empower your team with the skills and knowledge they need to succeed. Consultants can provide training workshops, develop operational manuals, and streamline processes for long-term sustainability.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Board Development</b>
                 <p>Strengthen your organization's governance with a well-equipped and engaged board. Consultants can facilitate board development training, guide strategic discussions, and ensure best practices are followed.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Technology Integration</b>
                 <p>Leverage the power of technology to increase efficiency and reach a wider audience. Consultants can help you select the right tools, integrate them seamlessly, and maximize their impact.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
