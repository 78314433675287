import React from 'react'
import B2CBanner from './component/B2CBanner'
import B2BHeading from './component/B2B/B2BHeading'
import B2BServices from './component/B2B/B2BServices'
import WhyB2B from './component/B2B/WhyB2B'
import B2BReady from './component/B2B/B2BReady'
import B2CHeading from './component/B2C/B2CHeading'
import B2CServices from './component/B2C/B2CServices'
import WhyB2C from './component/B2C/WhyB2C'
import B2CReady from './component/B2C/B2CReady'

export default function B2BC2C() {
  return (
    <>
      <B2CBanner/>
      <B2BHeading/>
      <B2BServices/>
      <WhyB2B/>
      <B2BReady/>

      <B2CHeading/>
      <B2CServices/>
      <WhyB2C/>
      <B2CReady/>
    </>
  )
}
