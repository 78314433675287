import React from 'react'
import './css/TrainingPart.css'
export default function TrainingPart() {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/websiteimages/6.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
      <h2> Why Choose Saasverse for Salesforce Training?
      </h2>
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>Investing in Salesforce training ensures that your team can leverage the platform's full capabilities, stay updated with the latest features, and implement best practices. Our training programs offer</p>
      <p>
       <b style={{color:'#006b95'}}>Expert Instructors</b> <br/> Learn from certified Salesforce professionals with extensive industry experience.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Customized Curriculum</b><br/>Tailor-made training programs that align with your specific business needs and objectives.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Flexible Learning Options</b><br/>Choose from on-site, online, or hybrid training formats to suit your schedule and preferences.</p>
      <p>
       <b style={{color:'#006b95'}}>Hands-On Experience</b><br/> Practical, real-world exercises to reinforce learning and ensure practical application.</p>
      {/* <p>
       <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
     */}
    </div>
   
  </div>
  )
}
