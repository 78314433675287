import React from 'react'

import Faq from '../../Faq/Faq'



import TravelBanner from './travelcompo/TravelBanner'
import TravelBenifits from './travelcompo/TravelBenifits'
import TravelBanner2 from './travelcompo/TravelBanner2'
import TravelReadyBanner from './travelcompo/TravelReadyBanner'
import WhySaasverseTravel from './travelcompo/WhySaasverseTravel'

export default function Travel() {
  return (
    <><TravelBanner/>
    <TravelBenifits/>
    <TravelBanner2/>
    <WhySaasverseTravel/>
    <TravelReadyBanner/>
    <Faq/>
      
    </>
  )
}
