import React from 'react'

export default function HealthResult() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center' }}>Results</h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Enhanced Efficiency</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Enhanced Efficiency</b>
    <p>Streamlined workflows and automated processes reduced administrative burden, allowing staff to focus more on patient care.</p>
   


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Improved Patient Outcomes</b>
                <p>Better coordination and access to comprehensive patient data led to improved treatment outcomes and reduced medical errors.
                </p>


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Hybrid Training</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Cost Savings</b>
                 <p>Operational efficiencies and reduced administrative overheads resulted in significant cost savings for UHS.</p>


</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  )
}
