import React from 'react'
import { Link } from 'react-router-dom'

export default function NonProfitReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to amplify your impact?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Contact Saasverse Pvt Limited as your  Nonprofit Consultant today and unlock the full potential of your organization to create a lasting difference in the world.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
