import React from 'react'
import { Link } from 'react-router-dom'

export default function HireProductReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to Find Your Perfect Salesforce Product Manager?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Contact Saasverse Pvt Ltd today! We'll discuss your specific needs and match you with a skilled Salesforce Product Manager who possesses the expertise and experience to shape your Salesforce vision and unlock the full potential of the platform for your business.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
