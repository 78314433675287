import React from 'react'

export default function B2CHeading() {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/b2bb2c/5.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
      <h2 style={{fontSize:'30px'}}>Salesforce B2C by Saasverse Pvt Ltd A Change in Business Heights
      </h2>
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>
      B2C commerce is highly competitive and always evolving and hence requires robust, adaptable, and consumer-centric technology solutions. Salesforce enables Saasverse Pvt Ltd to use mainly out-of-the-box features in providing unique B2C services that enhance growth, customer satisfaction, and operational efficiency. A detailed set of B2C services in Salesforce allows businesses to maximize their proactive solutions.

      </p>
    
    </div>
  </div>
  )
}
