import React from 'react'
import AboutUsBanner from './compo/AboutUsBanner'
import Our from './compo/Our'
import AboutOurServices from './compo/AboutOurServices'
import WhyChooseUs from './compo/WhyChooseUs'

import Testimonial from './../../../Home/Component/Testimonial'
import AboutBanner2 from './compo/AboutBanner2'
import Gallery from './compo/Gallary'

export default function AboutUs() {
    
    return (
       <>
       <AboutUsBanner/>
       <Our/>
       <AboutOurServices/>
       <AboutBanner2/>
       <Gallery/>
       <WhyChooseUs/>
      <Testimonial/>
       </>
    )
}
