import React from 'react'
import './css/TrainingContact.css'
import { Link } from 'react-router-dom'
export default function TrainingContact() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <section id="cta-panel-block_52b39b3c30590fd478440d8b1aa809ab" className="cta-panel custom-block bg-gradient lozad" style={{ background: "linear-gradient(90deg, rgba(0,107,149,1) 0%, rgba(0,0,0,1) 100%)", height: "30vh" }}>
    <div className="cta-panel-inner">
      <div className="left-col">
        <div className="col-inner">
          <p>Empower Your Team with Expert Salesforce Training</p>
        </div>
      </div>
      <div className="right-col">
        <div className="col-inner">
          <Link  to='/contact-us' style={{textDecoration:'none',color:'white'}} onClick={scrollToTop}>Contact Us</Link>
        </div>
      </div>
    </div>
    <div className="bg-logo">
      {/* <!-- <img className="lozad" data-src="https://xenogenix.co.uk/wp-content/themes/xenogenix/assets/images/xo-back.png" alt="Xenogenix logo" /> --> */}
    </div>
  </section>
  )
}
