import React from 'react'

export default function KenticoServices() {
  return (
    <div id="servicess" >
        <div className="cnts">
          <div className="rows">
      <h2 style={{fontSize:'40px',textAlign:'center',paddingBottom:'20px'}}> Our Kentico Development Services
      </h2>
      {/* <p>At Saasverse, we offer more than just a job – we offer a career filled with opportunities for growth, learning, and advancement. Here’s why you should consider joining our team:</p><br/> */}
    
            <div className="reasons__wrappers">
    
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Kentico Implementation</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Consultation and Strategy</b>
                     <p>Understand your business goals and develop a comprehensive implementation strategy.

                     </p>
                     <b style={{color:'#006b95'}}>Setup and Configuration</b>
                     <p>Configure the Kentico environment to align with your business processes and workflows.

                     </p>
                     <b style={{color:'#006b95'}}>Data Migration</b>
                     <p>Seamlessly migrate your existing data to the Kentico platform, ensuring data integrity and minimal disruption.

                     </p>
                    
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Custom Development</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Tailored Solutions</b>
                     <p>Develop custom modules and functionalities to meet your specific business needs.
                     </p>
                     <b style={{color:'#006b95'}}>Integration Services</b>
                     <p>Integrate Kentico with other systems and platforms for a unified digital ecosystem.

                     </p>
                     <b style={{color:'#006b95'}}>User Experience Enhancements</b>
                     <p>Enhance the usability and design of your Kentico-powered websites for an improved user experience.

                     </p>
         
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Kentico Optimization</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Performance Tuning</b>
                     <p>Optimize your Kentico environment for speed and efficiency.

                     </p>
                     <b style={{color:'#006b95'}}>SEO Optimization</b>
                     <p>Implement SEO best practices to improve your website’s search engine rankings.

                     </p>
                     <b style={{color:'#006b95'}}>Security Enhancements</b>
                     <p>Strengthen the security of your Kentico platform to protect your data and users.

                     </p>
                   
   
   
    </p>
                </div>
              </div>


              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Ongoing Support and Maintenance</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Technical Support</b>
                     <p>Provide continuous support to address any issues and ensure optimal performance.

                     </p>
                     <b style={{color:'#006b95'}}>Regular Updates</b>
                     <p>Keep your Kentico environment up-to-date with the latest features and security patches.
                     </p>
                     <b style={{color:'#006b95'}}>Training</b>
                     <p>Offer training sessions to help your team make the most of Kentico’s capabilities.
                     </p>
                  
   
   
    </p>
                </div>
              </div>


              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles"> Kentico Migration</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Seamless Transition</b>
                     <p>Manage the migration from other CMS platforms to Kentico, ensuring a smooth transition and data continuity.

                     </p>
                     <b style={{color:'#006b95'}}>Version Upgrades</b>
                     <p>Upgrade your existing Kentico installation to the latest version to leverage new features and improvements.
                     </p>
                  
   
   
    </p>
                </div>
              </div>




           
          
          
    
             
            
    
    
             
    
    
             
            
    
             
          
            </div>
          </div>
        </div>
      </div>
  )
}
