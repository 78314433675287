import React from 'react'

export default function HireArchitechBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Benefits of Hiring a Saasverse Salesforce Architect<br/></h2>
        <div className="reasons__wrapper">
<p>Building a robust and scalable Salesforce foundation requires the vision of a mastermind. Enter the Salesforce Architect, the strategic partner you need to craft a future-proof Salesforce architecture that empowers your business growth. At Saasverse Pvt Ltd, we connect you with the ideal architect to translate your vision into a high-performing Salesforce ecosystem.</p>

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Reduced Risks</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Reduced Risks</b>
                 <p>A well-designed architecture mitigates potential risks associated with scalability, security, and performance issues.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Enhanced User Experience</b>
    <p>Optimized performance and seamless integrations lead to a positive and productive user experience.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Improved Efficiency</b>
                 <p>Streamlined data flow and optimized processes boost operational efficiency across your organization
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3> */}
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Lower Total Cost of Ownership (TCO)</b>
                <p>The scalability and flexibility of the architecture can minimize future development costs as your business grows.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Future-Proofed Foundation</b>
                 <p>Our architects design with the future in mind, ensuring your Salesforce platform adapts to evolving technologies and business requirements.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
