import React from 'react'

export default function HireQualityBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Why Hire a Salesforce Quality Analyst from Saasverse?<br/></h2>

        <div className="reasons__wrapper">
<p>A successful Salesforce implementation hinges on meticulous attention to detail. Enter the Salesforce Quality Analyst (QA), your guardian of quality at Saasverse Pvt Ltd. We connect you with the perfect QA match to guarantee the flawless execution of your Salesforce projects, from implementations and customizations to integrations and migrations.</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Salesforce Expertise & Testing Prowess</b>
                 <p>Our QAs are Salesforce Certified professionals with a keen eye for detail and a mastery of testing methodologies. They ensure your Salesforce solution functions flawlessly and meets all your requirements.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Functional & Technical Testing Masters</b>
    <p>They excel at conducting both functional testing (verifying features work as intended) and technical testing (ensuring performance, security, and compatibility).</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Automation Champions</b>
                 <p>Our QAs leverage automation tools to streamline the testing process, improve efficiency, and minimize human error.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3> */}
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Risk Management & Mitigation Focus</b>
                <p>Our QAs proactively identify potential risks associated with functionality, security, or performance and recommend effective mitigation strategies.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Collaboration Champions</b>
                 <p>They collaborate effectively with developers, business analysts, and other stakeholders to ensure a smooth and successful project outcome.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
