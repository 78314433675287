import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import HireProjectBanner from './hireprojectcompo/HireProjectBanner'
import HireProjectBenifits from './hireprojectcompo/HireProjectBenifits'
import HireProjectBanner2 from './hireprojectcompo/HireProjectBanner2'
import HireProjectReadyBanner from './hireprojectcompo/HireProjectReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseProject from './hireprojectcompo/WhySaasverseProject'
import JustProject from './hireprojectcompo/JustProject'

export default function HireProjectManager() {
  return (
    <><HireProjectBanner/>
    <HireProjectBenifits/>
    <HireProjectBanner2/>
    <WhySaasverseProject/>
    <CertificateSlider/>
    <JustProject/>
    <Trustedby2/>
    <HireProjectReadyBanner/>
    <Faq/>
      
    </>
  )
}
