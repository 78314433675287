import React from 'react'

export default function ServiceClient() {
  return (
    <div className="client-info-containerb">
      <div className="client-backgroundb">
      <h2>Business  <span style={{color:'#003e95'}}>Challenge</span></h2>
        <p>Global Travel Solutions (GTS) faced challenges in delivering seamless customer service across multiple channels. With a diverse customer base and a wide range of travel services, they struggled with fragmented customer data, inconsistent service delivery, and inefficient case management. They needed a solution to centralize customer interactions, enhance service efficiency, and improve overall customer satisfaction.</p>

      </div>
   
    </div>
  )
}
