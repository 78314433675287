import React from 'react'

export default function ContactBanner() {
  return (
    <section className="about-us-banner">
    <div className="banner-container">
      <picture>
        <source media="(min-width: )" srcSet="/assets/images/banner/contactbanner.jpg" />
        <img src="/assets/images/banner/contactbanner.jpg" alt="About Us" />
      </picture>
      <div className="banner-content-overlay">
        <h1>Contact Us</h1>
        <p>At Saasverse Pvt Ltd, we value open communication and are always here to assist you. 
        </p>
        {/* Talk to Consultant */}
      </div>
    </div>
  </section>
  )
}
