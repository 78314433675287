import React from 'react';
// import './../../SalesforceConsulting/compo/Ready'
const IntegrationReady = () => {
  return (
   

<div className="cnt_banner">
<div className="banner__container">
  <h2 className="banner__heading">Ready to Transform Your Business?</h2>
  <div className="banner__text-wrapper">
    <div className="banner__text-content">
      <p>Harness the full potential of your systems with Saasverse Pvt Ltd’s Salesforce Integration Services. Contact us today to learn how we can help you integrate Salesforce with your existing solutions and drive your business forward.</p>
    </div>
    <div className="banner__button-wrapper">
      <div className="banner__button button button-outline contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
    </div>
  </div>
</div>
</div>
  );
}

export default IntegrationReady;
