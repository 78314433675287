import React from 'react';
import '../../SalesforceAppExchange/Compo/css/WhySaasverse.css'; 

const InfoComponent = () => {
  return (
    <div className="info-container" >
     
      <div className="info-text">
        <h2>Why Choose Saasverse?</h2>
        <p>
         <b style={{color:'#006b95'}}> Expertise and Experience: </b> <br/>Our team of certified Salesforce professionals brings extensive experience and deep expertise to every project.
        </p>
        <p>
          <b style={{color:'#006b95'}}>Client-Centric Approach: </b><br/>We prioritize understanding your unique needs and delivering solutions that are tailored to your business.
        </p>
        <p>
         <b style={{color:'#006b95'}}> Commitment to Excellence:</b><br/> We are dedicated to providing exceptional service and ensuring that our clients achieve outstanding results with Salesforce.
        </p>
        <p>
         <b style={{color:'#006b95'}}> Proven Track Record:</b><br/> Our successful track record speaks for itself, with numerous satisfied clients and successful Salesforce implementations across various industries.
        </p>
      </div>
      <div className="info-image">
        
        <img src="/assets/images/websiteimages/4.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default InfoComponent;
