import React from 'react'
import { Link } from 'react-router-dom'

export default function B2CReady() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Let’s Get Started</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Are you over it and keen to leverage every aspect of Salesforce to the maximum for your B2C brand? Saasverse Pvt Ltd can be contacted for consultation at the earliest. Together, we will develop a strategy that is designed to help you attain your business goals with Salesforce.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
