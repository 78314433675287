import React from 'react'

export default function JustArchitecture() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Architecture</h2>
      <h4>Our Salesforce Architects offer a comprehensive skillset</h4>
    </div>
    <div className="columnt">
      <p><b>Requirements Gathering & Analysis</b><br/>They work closely with stakeholders to understand your business goals, technical needs, and user requirements.
      </p>
      <br/> <p><b>Solution Design & Blueprinting</b> <br/>Our architects design and blueprint a Salesforce architecture that is secure, scalable, and meets your specific needs.
      </p>
    </div>
    <div className="columnt">
      <p><b>Technology Stack Selection</b><br/>They possess expertise in evaluating and selecting the right technologies to integrate with your Salesforce platform.</p>
      <br/> <p><b>Ongoing Support & Maintenance</b><br/>Our architects provide continuous support to maintain your Salesforce architecture and ensure it remains optimized.</p>
    </div>
  </div>
  )
}
