import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseArch = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Hire a Salesforce Architect from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Strategic Visionaries </b> <br/>Our architects are Salesforce Certified professionals with unparalleled knowledge of the platform's capabilities. They possess the strategic foresight to design an architecture that aligns with your long-term business goals.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Integration Orchestrators</b><br/>They excel at integrating Salesforce with your existing systems, fostering a unified data landscape and seamless information flow across your organization.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Security Champions</b><br/>Our architects prioritize rock-solid security throughout the Salesforce architecture. They implement best practices to safeguard your sensitive data and ensure regulatory compliance.</p>
        <p>
         <b style={{color:'#006b95'}}>Scalability Experts</b><br/>They design architectures that are highly scalable to accommodate your growing business needs and evolving user base.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Performance Optimization Gurus</b><br/>Our architects optimize your Salesforce environment for peak performance, ensuring a fast, reliable, and responsive user experience.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Data Governance Architects</b><br/>They establish robust data governance practices to ensure data accuracy, consistency, and accessibility for informed decision-making.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseArch;
