import React from 'react'
import { Link } from 'react-router-dom'

export default function MobileBanner2() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div class="bannerL">
    <div class="container-md">
      <div class="bannerL__container">
        <div class="bannerL__wrapper">
          <h2 class="bannerL__title">Unlock Unlimited Possibilities with Mobile Publisher
          </h2>
          <h5 class="bannerL__subtitle">Book a call and discuss details with our experts.</h5>
          <div class="bannerL__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</Link></div>
          <div class="bannerL__list">
            <div class="bannerL__list-item">
              <h2 class="bannerL__list-item_num">120+</h2>
              <h6 class="bannerL__list-item_text">Delivered projects</h6>
            </div>
            <div class="bannerL__list-item">
              <h2 class="bannerL__list-item_num">50+</h2>
              <h6 class="bannerL__list-item_text">Salesforce certified specialists</h6>
            </div>
            <div class="bannerL__list-item">
              <h2 class="bannerL__list-item_num">8+</h2>
              <h6 class="bannerL__list-item_text">years of experience</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
