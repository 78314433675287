import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseProd = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Why Hire a Salesforce Product Manager from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Strategic Visionaries</b> <br/>Our PMs are Salesforce-certified experts with a keen understanding of the platform's capabilities and strategic thinking to define a roadmap aligned with your business objectives.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Product Expertise</b><br/>They possess a deep understanding of product development lifecycles, user experience (UX) principles, and best practices for maximizing Salesforce product adoption.
        </p>
        <p>
         <b style={{color:'#006b95'}}>User Advocacy</b><br/>Our PMs champion the needs of your users, ensuring the Salesforce solution caters to their workflows and drives productivity and satisfaction.</p>
        <p>
         <b style={{color:'#006b95'}}>Technical Proficiency</b><br/>They possess a solid grasp of Salesforce functionalities and collaborate effectively with development teams to translate product vision into reality.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Data-Driven Decisions</b><br/>Our PMs leverage data and analytics to measure performance, identify areas for improvement, and continuously optimize the Salesforce product.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Communication Champions</b><br/>They excel at clear communication with stakeholders across all levels, fostering collaboration and ensuring everyone is aligned on the product roadmap.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseProd;
