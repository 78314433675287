import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'


import CommunicationBanner from './communicationcompo/CommunicationBanner'
import CommunicationBenifits from './communicationcompo/CommunicationBenifits'
import CommunicationBanner2 from './communicationcompo/CommunicationBanner2'
import CommunicationReadyBanner from './communicationcompo/CommunicationReadyBanner'
import WhySaasverseComm from './communicationcompo/WhySaasverseComm'

export default function Communication() {
  return (
    <><CommunicationBanner/>
    <CommunicationBenifits/>
    <CommunicationBanner2/>
    <WhySaasverseComm/>
    <CommunicationReadyBanner/>
    <Faq/>
      
    </>
  )
}
