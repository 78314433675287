import React from 'react'

export default function WhyQuality() {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
      <h2>Benefits of Hiring a Saasverse Salesforce Quality Analyst
      </h2>
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>
       <b style={{color:'#006b95'}}>Reduced Costs</b> <br/>Early detection and correction of defects minimizes rework and reduces the cost of fixing issues later in the development lifecycle.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Enhanced User Experience</b><br/>Rigorous testing ensures a bug-free and user-friendly Salesforce environment, leading to higher user satisfaction and adoption.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Improved System Performance</b><br/> Our QAs identify and address performance bottlenecks, ensuring your Salesforce platform functions optimally.</p>
      <p>
       <b style={{color:'#006b95'}}>Increased Security</b><br/>Thorough testing safeguards your system from security vulnerabilities, protecting your valuable data.</p>
    
      <p>
       <b style={{color:'#006b95'}}>Peace of Mind</b><br/>With a dedicated QA professional on board, you gain peace of mind knowing your Salesforce project is in expert hands.</p>
    
     
    </div>
  </div>
  )
}
