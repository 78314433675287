import React from 'react'

export default function CloudPower() {
  return (
    <div className="client-info-containerb">
    <div className="client-backgroundb" >
      <h2>Unleash the Full Potential of the <span style={{color:'#003e95'}}>Cloud</span> with Saasverse Pvt Ltd</h2>
      <p>Welcome to Saasverse Pvt Ltd, where we offer cutting-edge Cloud Consulting Services designed to transform your business. Whether you’re just starting your cloud journey or looking to optimize your existing cloud environment, our team of cloud experts is here to guide you every step of the way.
      </p>
     <p>Embrace the power of the cloud and drive innovation, agility, and efficiency in your organization.</p>
    </div>
  
  </div>
  )
}
