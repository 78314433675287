import React from 'react'

import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'

import FinancialBanner from './financialcompo/FinancialBanner'
import FinancialBenifits from './financialcompo/FinancialBenifits'
import FinancialBanner2 from './financialcompo/FinancialBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import FinancialReadyBanner from './financialcompo/FinancialReadyBanner'
import WhySaasverseFinn from './financialcompo/WhySaasverseFinn'

export default function Financial() {
  return (
    <><FinancialBanner/>
    <FinancialBenifits/>
    <FinancialBanner2/>
    <WhySaasverseFinn/>
    <CertificateSlider/>
    <Trustedby2/>
    <FinancialReadyBanner/>
    <Faq/>
      
    </>
  )
}
