import React from 'react'

export default function PrivacyBanner() {
  return (
    <section className="about-us-banner">
      <div className="banner-container">
        <picture>
          <source media="(min-width: )" srcSet="/assets/images/aboutus.jpg" />
          <img src="/assets/images/aboutus.jpg" alt="About Us" />
        </picture>
        <div className="banner-content-overlay">
          <h1>Privacy</h1>
          <p>  We are a thriving squad of nerd minds, think tankers, pro coders, business geeks, and awesomely creative beings.
          </p>
          {/* Talk to Consultant */}
        </div>
      </div>
    </section>
  )
}
