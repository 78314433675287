import React from 'react';


const WhySaasverseMedia = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Saasverse Expertise in Media
        </h2>
        <p>At Saasverse, we have a team of Salesforce experts with a deep understanding of the media industry's unique needs. We can help you:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Customize Media Cloud to meet the specific needs of your organization, whether you're a publisher, broadcaster, or advertising agency.



        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate Media Cloud with your existing content management systems (CMS) and advertising platforms for a unified view of your data.

        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop custom solutions to address unique challenges, such as managing social media campaigns or optimizing content licensing agreements.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure your staff can leverage Media Cloud effectively to achieve your business goals.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseMedia;
