import React from 'react'
import ManagedBanner from './Compo/ManagedBanner'
import ManagedBanner2 from './Compo/ManagedBanner2'
import ManagedWhy from './Compo/ManagedWhy'
import ManagedReadyBanner from './Compo/ManagedReadyBanner'
import ManagedBenifits from './Compo/ManagedBenifits'
import Faq from '../../../Faq/Faq'
import CertificateSlider from '../../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../../Home/Component/TrustedBy2'
import { Helmet } from 'react-helmet';

export default function SalesforceManaged() {
  return (
    <>
      <Helmet>
        <title>Salesforce Managed Services: Get Expert Help from Saasverse</title>
        <meta name="description" content="Saasverse provides comprehensive Salesforce managed services to help businesses in USA, UK, Australia get the most out of their Salesforce investment. Our services include implementation, training, and support from certified Salesforce experts. Contact us today!" />
      </Helmet>
      <ManagedBanner/>
      <ManagedBenifits/>
      <ManagedBanner2/>
      <ManagedWhy/>
      <CertificateSlider/>
      <Trustedby2/>
      <ManagedReadyBanner/>
      <Faq/>
    </>
  )
}
