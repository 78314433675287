import React from 'react'


import Faq from '../../Faq/Faq'


import MediaBanner from './mediacompo/MediaBanner'
import MediaBenifits from './mediacompo/MediaBenifits'
import MediaBanner2 from './mediacompo/MediaBanner2'
import MediaReadyBanner from './mediacompo/MediaReadyBanner'
import WhySaasverseMedia from './mediacompo/WhySaasverseMedia'

export default function Media() {
  return (
    <><MediaBanner/>
    <MediaBenifits/>
    <MediaBanner2/>
    <WhySaasverseMedia/>
    <MediaReadyBanner/>
    <Faq/>
      
    </>
  )
}
