import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseEdu = () => {
  return (
    <div className="info-container">
       <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Saasverse: Your Education Ecosystem Architect
        </h2>
        <p>At Saasverse, we're passionate about education. Our team of Salesforce experts brings a deep understanding of the unique needs of schools, universities, and educational institutions. We can help you:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Customize Education Cloud to cultivate a nurturing environment, tailored to your specific needs.


        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate seamlessly with existing systems (SIS, LMS), ensuring a unified flow of student data and fostering a connected learning ecosystem.
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop custom solutions to address unique challenges, such as fostering career development or managing complex scholarship programs.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training, empowering your staff to leverage Education Cloud for maximum impact.</p>
      
      </div>
    
    </div>
  );
};

export default WhySaasverseEdu;
