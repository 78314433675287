import React from 'react'

export default function HealthImplementation () {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Implementation <span style={{color:'#006b95' }}>Highlights</span></h2>
      {/* <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4> */}
    </div>
    <div className="columnt">
      <p><b>Patient Engagement</b><br/>Salesforce enabled UHS to improve patient engagement through personalized communication and care plans, enhancing patient satisfaction and adherence to treatment.</p>
      <br/> <p><b>Care Coordination</b> <br/>Care teams benefited from real-time access to patient data, facilitating better coordination among providers and reducing duplication of efforts.</p>
    </div>
    <div className="columnt">
      <p><b>Analytics and Insights</b><br/>Salesforce's analytics capabilities provided actionable insights into patient outcomes, operational efficiency, and resource allocation, enabling data-driven decision-making.</p>
      {/* <br/> <p><b>Proven Success</b><br/> Our track record of successful security reviews and satisfied clients demonstrates our capability and reliability in ensuring Salesforce security.</p> */}
    </div>
  </div>
  )
}
