import React from 'react'


import Faq from '../../Faq/Faq'
import MarketingCloudBanner from './marketingcompo/MarketingCloudBanner'
import MarketingCloudBenifits from './marketingcompo/MarketingCloudBenifits'
import MarketingCloudBanner2 from './marketingcompo/MarketingCloudBanner2'
import WhySaasverseMark from './marketingcompo/WhySaasverseMark'
import MarketingCloudReadyBanner from './marketingcompo/MarketingCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function MarketingCloud() {
  return (
    <>
    <MarketingCloudBanner/>
    <MarketingCloudBenifits/>
    <MarketingCloudBanner2/>
    <WhySaasverseMark/>
    <CertificateSlider/>
    <Trustedby2/>
  <MarketingCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
