import React from 'react'

export default function ServiceSolution() {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/casestudy/service.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
    <h2 style={{marginBottom:'0px'}}>Solution</h2>
    <h3 style={{fontSize:'20px',color:'#006b95',fontWeight:'500',marginBottom:'10px'}}>Implementation of Salesforce Service Cloud
    </h3>
      
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>
       <b style={{color:'#006b95'}}>Platform Deployment </b> <br/>GTS decided to implement Salesforce Service Cloud, leveraging its robust features for case management, omnichannel support, and knowledge management.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Customization</b><br/>Salesforce Service Cloud was customized to align with GTS's specific service workflows and industry requirements, ensuring a tailored solution that meets their unique business needs.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Integration</b><br/>Integration with existing systems such as booking platforms and CRM systems was implemented to create a unified view of customer data and streamline processes.</p>
     
    </div>
  </div>
  )
}
