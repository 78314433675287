import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseTech = () => {
  return (
    <div className="info-container">
     
    <div className="info-text">
      <h2>Saasverse: Your Tech-Savvy Salesforce Partner
      </h2>
      <p>At Saasverse, we speak fluent "tech." Our team of Salesforce experts understands the unique challenges faced by tech companies. We can help you:
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Customize Salesforce solutions that seamlessly integrate with your existing tech stack, fostering a unified ecosystem.


      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Develop custom applications to address specific challenges, such as managing complex product lifecycles or building self-service portals for developers.
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Leverage cutting-edge technologies like AI and automation to streamline processes and unlock new possibilities.
       </p>
      <p>
       <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training, ensuring your team can leverage Salesforce effectively to achieve your tech goals.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  );
};

export default WhySaasverseTech;
