import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseCon = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>What We Can Do for You:
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Salesforce Implementation and Customization</b> <br/>Tailor Salesforce to your specific needs and workflows to optimize your sales processes.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Salesforce Integration</b><br/>Seamlessly integrate Salesforce with your existing business systems to eliminate data silos and improve data flow.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Salesforce Development</b><br/>Develop custom applications to extend Salesforce functionality and automate complex tasks.</p>
        <p>
         <b style={{color:'#006b95'}}>Salesforce Data Migration</b><br/>Migrate your customer data to Salesforce securely and efficiently.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Salesforce Training</b><br/>Equip your team with the skills and knowledge they need to utilize Salesforce effectively.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Salesforce Managed Services</b><br/>Outsource your Salesforce management to our team of experts for ongoing maintenance and optimization.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseCon;
