import React from 'react'

export default function WhySitecore() {
  return (
    <div className="info-container">
   
    <div className="info-text">
      <h2>Why Choose Sitecore for Your Business?
      </h2>
      <p>Sitecore is a leading digital experience platform that enables businesses to deliver highly personalized content and seamless customer journeys. Utilizing Sitecore offers numerous benefits, including:
      </p>
      <p>
       <b style={{color:'#006b95'}}>Personalized Experiences</b> <br/>Deliver targeted and personalized content to your audience for increased engagement and conversions.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Scalability</b><br/>Scale your digital solutions effortlessly to meet growing business demands.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Data-Driven Insights</b><br/>Gain valuable insights into customer behavior with advanced analytics and reporting tools.</p>
      <p>
       <b style={{color:'#006b95'}}>Seamless Integration</b><br/>Integrate Sitecore with other systems and platforms for a unified digital ecosystem.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/whyaws.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
