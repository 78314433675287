import React from 'react'

export default function HowAI() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>How AI Can Enhance Your Business</h2>
      {/* <h4>Our Salesforce Architects offer a comprehensive skillset</h4> */}
    </div>
    <div className="columnt">
      <p><b>Boost Efficiency</b><br/>Automate repetitive tasks, freeing up your employees to focus on higher-level activities. AI can streamline processes, shorten turnaround times, and reduce operational costs.
      </p>
      <br/> <p><b>Improve Customer Experience</b> <br/>Leverage AI-powered chatbots for 24/7 customer support, personalize product recommendations, and anticipate customer needs for a more satisfying experience.
      </p>
    </div>
    <div className="columnt">
      <p><b>Gain Valuable Insights</b><br/>AI analyzes vast amounts of data to identify hidden patterns and trends. These insights empower you to make data-driven decisions, predict future outcomes, and optimize marketing campaigns.</p>
      <br/> <p><b>Enhance Security & Risk Management</b><br/>AI can detect fraudulent activity, identify security threats, and predict potential risks before they occur.</p>
    </div>
  </div>
  )
}
