import React from 'react'

export default function JustHire() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Administration</h2>
      <p>Our Salesforce Admins offer a comprehensive skillset</p>
    </div>
    <div className="columnt">
      <p><b>Reporting & Analytics Configuration</b><br/>They configure reports and dashboards to provide valuable insights into your Salesforce data.
      </p>
      <br/> <p><b>System Maintenance & Backups</b> <br/>Our Admins perform regular maintenance tasks and data backups to ensure platform stability and data security.
      </p>
    </div>
    <div className="columnt">
      <p><b>Change Management Support</b><br/>They assist with change management initiatives during system upgrades or new feature implementations.</p>
      <br/> <p><b>Ongoing Support</b><br/>Our Admins provide continuous support to answer questions, troubleshoot issues, and ensure your Salesforce environment runs smoothly.</p>
    </div>
  </div>
  )
}
