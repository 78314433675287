import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseNon = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Benefits of Working with a Nonprofit Consultant
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Increased Efficiency</b> <br/>Streamline operations, free up resources, and focus on what matters most – fulfilling your mission.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Enhanced Fundraising</b><br/>Secure more funding to expand your reach and make a bigger difference.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Measurable Impact</b><br/>Demonstrate the tangible results of your work to donors, volunteers, and stakeholders.</p>
        <p>
         <b style={{color:'#006b95'}}>Sustainable Growth</b><br/>Build a strong and resilient organization equipped for long-term success.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Empowered Team</b><br/>Equip your staff with the skills and confidence to drive positive change.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseNon;
