import React from 'react'

export default function CustomBenifits() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}>Our Salesforce Custom Development Services <br/></h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Workflow and Process Automation</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Automation Solutions:</b>
    <p>Automate repetitive tasks and complex workflows to save time and reduce errors. </p>
   
<b style={{color:'#006b95'}}>Custom Workflows:</b>
<p>Design and implement custom workflows that align with your business processes and improve efficiency.

</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Data Integration and Management</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Seamless Integration:</b>
                 <p> We integrate Salesforce with other systems and platforms you use, ensuring smooth data flow and operational efficiency.
                 </p>
<b style={{color:'#006b95'}}> Data Migration:</b> 
<p> Safely migrate your data to Salesforce, maintaining data integrity and security throughout the process.
</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">User Interface Customization</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>  Intuitive Designs:</b>
                 <p>  Design user-friendly and intuitive interfaces that enhance user adoption and satisfaction.

                 </p>
<b style={{color:'#006b95'}}> Brand Consistency:</b> 
<p>Ensure brand consistency across all Salesforce interfaces, creating a cohesive and professional appearance.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">User Interface Customization</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>  Intuitive Designs:</b>
                 <p>  Design user-friendly and intuitive interfaces that enhance user adoption and satisfaction.

                 </p>
<b style={{color:'#006b95'}}> Brand Consistency:</b> 
<p>Ensure brand consistency across all Salesforce interfaces, creating a cohesive and professional appearance.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Salesforce Einstein Analytics
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Data-Driven Insights:</b>
                <p>Implement Salesforce Einstein Analytics to gain actionable insights and make informed business decisions.</p>
<b style={{color:'#006b95'}}> Custom Dashboards:</b> 
<p> Create custom dashboards that provide real-time data and metrics tailored to your business needs.</p>

</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title"> Custom Application Development</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Bespoke Solutions:</b>
                 <p> We develop custom applications tailored to your business requirements, ensuring they seamlessly integrate with your existing Salesforce environment.

                 </p>
<b style={{color:'#006b95'}}>Advanced Coding:</b> 
<p>Our team leverages Salesforce’s Apex and Visualforce to build robust and scalable applications.</p>
<p><b style={{color:'#006b95'}}>Lightning Components:</b> We create dynamic and responsive Lightning Components to enhance the user experience and functionality.</p>
</p>
            </div>
          </div>
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
