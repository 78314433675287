import React from 'react'

export default function ServiceResult() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center' }}>Results</h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Enhanced Efficiency</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Enhanced Service Efficiency</b>
    <p>Streamlined processes and automation reduced handling times and improved first-call resolution rates, enhancing overall service efficiency.</p>
   


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Improved Customer Satisfaction</b>
                <p>Consistent and personalized customer interactions, enabled by Salesforce Service Cloud, led to higher customer satisfaction scores and increased loyalty.
                </p>


</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Hybrid Training</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Operational Insights</b>
                 <p>Real-time analytics and reporting provided actionable insights into service performance, customer trends, and areas for improvement.</p>


</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  )
}
