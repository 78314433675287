import React from 'react'

export default function SalesSolution() {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/casestudy/sales2.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
    <h2 style={{marginBottom:'0px'}}>Solution</h2>
    <h3 style={{fontSize:'20px',color:'#006b95',fontWeight:'500',marginBottom:'10px'}}>Implementation of Salesforce Sales Cloud
    </h3>
     
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>
       <b style={{color:'#006b95'}}>Platform Deployment </b> <br/>Tech Solutions Inc. opted to implement Salesforce Sales Cloud, leveraging its comprehensive features for lead management, opportunity tracking, and sales forecasting.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Customization</b><br/>Salesforce Sales Cloud was customized to align with Tech Solutions Inc.'s unique sales processes and business requirements, ensuring seamless integration with existing systems and workflows.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Integration</b><br/>Integration with third-party tools and systems, such as marketing automation and customer support platforms, was implemented to create a unified view of customer data across departments.</p>
     
    </div>
  </div>
  )
}
