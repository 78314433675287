import React from 'react'
import ServiceCaseBanner from './Compo/ServiceCaseBanner'
import ServiceClient from './Compo/ServiceClient'
import ServiceSolution from './Compo/ServiceSolution'
import ServiceImplementation from './Compo/ServiceImplementation'
import ServiceResult from './Compo/ServiceResult'
import ServiceFuture from './Compo/ServiceFuture'
import ServiceConclusion from './Compo/ServiceConclusion'
import ContactForm from '../../Company/Contact Us/contactcompo/ContactForm'
import CertificateSlider from '../../../Home/Component/CertificateSlider'

export default function ServiceCaseStudy() {
  return (
    <>
     <ServiceCaseBanner/> 
<ServiceClient/>
<ServiceSolution/>
<ServiceImplementation/>
<CertificateSlider/>
<ServiceResult/>
<ServiceFuture/>
<ServiceConclusion/>
<ContactForm/>


   
    </>
  )
}