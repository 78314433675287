import React from 'react'
import { Link } from 'react-router-dom'
export default function IntegrationBanner() {
  return (
    <div className="hero"  style={{backgroundImage: `url('/assets/images/banner/banner002.png')`, backgroundSize: "cover",
      backgroundPosition: "center"}}>
    <div className="hero-container">
      <div className="hero__content">
        <h1 className="hero__content_title" style={{color:'#006b95' }}>Salesforce <br/>Integration Services</h1>
        <p className="hero__content_text">At Saasverse Pvt Ltd, we specialize in delivering tailored Salesforce solutions that perfectly align with your unique business requirements. 
        </p>
        <div className="hero__content_button button button-solid contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</a></div>
        <div className="hero__content_logos">
           <Link to='https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=bf4cc5b0-f7c4-4d44-99ac-1ddaa2ca27e1'  target="_blank" > <img className="hero__content_logos-item hero__content_logos-item-1" style={{height:'70px',borderRadius:'10px'}} src="/assets/images/salesforcepartner.png" alt="saasverse" /></Link>
          {/* <img className="hero__content_logos-item hero__content_logos-item-2" src="https://magicfuse.co/_images/badges/hero-2.svg" alt="saasverse" /> */}
        </div>
      </div>
      <div className="hero__image">
        <picture className="hire-cns">
          {/* <img src="https://magicfuse.co/_images/hero/page_hero_01_mobile.png" alt="Hire Salesforce Consultants" /> */}
        </picture>
      </div>
    </div>
  </div>
  )
}
