import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseHealth = () => {
  return (
    <div className="info-container">
     
    <div className="info-text">
      <h2>Saasverse Expertise in Healthcare
      </h2>
      <p>At Saasverse, we have a team of Salesforce experts with a deep understanding of healthcare regulations and best practices. We can help you:
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Customize Health Cloud to meet your specific needs, whether you're a hospital, clinic, payer, or pharmaceutical company.


      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Integrate Health Cloud with your existing Electronic Health Records (EHR) system for a seamless data flow.
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Develop custom applications to address unique challenges within your organization.
       </p>
      <p>
       <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure your staff can leverage Health Cloud effectively.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  );
};

export default WhySaasverseHealth;
