import React from 'react';
 

const ISVWhy = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/6.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2> Why Choose Salesforce ISV Development?
        </h2>
        <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can:
        </p>
        <p>
         <b style={{color:'#006b95'}}> Expand Your Market Reach: </b> <br/> Tap into the vast Salesforce ecosystem and reach a global audience of potential customers.

        </p>
        <p>
          <b style={{color:'#006b95'}}> Enhance Business Efficiency: </b><br/>Create custom solutions tailored to your specific business needs, improving operational efficiency and productivity.
        </p>
        <p>
         <b style={{color:'#006b95'}}> Generate New Revenue Streams:</b><br/> Monetize your applications by offering them on the Salesforce AppExchange, creating new revenue opportunities.       </p>
      
      </div>
    </div>
  );
};

export default ISVWhy;
