import React from 'react';
import './css/AboutOurServices.css'
const AboutOurServices = () => {


  return (
    <div id="servicess" >
      <div className="cnts">
        <div className="rows">
          <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Our Services</h2>

          <div className="reasons__wrappers">

            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Salesforce Implementation and Customization</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> We design and deploy Salesforce solutions that align with your business processes, ensuring a seamless integration that enhances efficiency and effectiveness.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">App Development</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Our development team creates robust, scalable, and user-friendly applications tailored to your business requirements.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Data Integration</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> We facilitate smooth and secure data integration, ensuring that your systems communicate effectively and provide you with accurate, real-time information.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Automation</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>By automating repetitive tasks, we help you save time and reduce errors, allowing your team to focus on strategic activities.


                  </p>


                </p>
              </div>
            </div>













          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutOurServices;
