import React from 'react'


import Faq from '../../Faq/Faq'


import HireArchitechBanner from './hirearchitechcompo/HireArchitechBanner'
import HireArchitechBenifits from './hirearchitechcompo/HireArchitechBenifits'
import HireArchitechBanner2 from './hirearchitechcompo/HireArchitechBanner2'
import HireArchitechReadyBanner from './hirearchitechcompo/HireArchitechReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseArch from './hirearchitechcompo/WhySaasverseArch'
import JustArchitecture from './hirearchitechcompo/JustArchitecture'

export default function HireArchitech() {
  return (
    <>
    <HireArchitechBanner/>
    <HireArchitechBenifits/>
    <WhySaasverseArch/>
    <HireArchitechBanner2/>
    <CertificateSlider/>
    <JustArchitecture/>
    <Trustedby2/>
    <HireArchitechReadyBanner/>
    <Faq/>
      
    </>
  )
}
