import React from 'react'
import './HowToApply.css'
export default function HowToApply() {
  return (
    // <div className="apply-banner">
    //         <div className="container">
    //             <h1 className="apply-banner__title">How to Apply</h1>
    //             <p className="apply-banner__description">
    //                 If you are passionate about technology and eager to make a difference, we would love to hear from you. To apply for any of the positions listed above, please send your resume and a cover letter to <a href="mailto:your-email@example.com">[email address]</a>. In your cover letter, please specify the position you are applying for and explain why you would be a great fit for our team.
    //             </p>
    //         </div>
    //     </div>

        
<div className="cnt_bannera">
<div className="banner__containera">
  <h2 className="banner__headinga">How to Apply</h2>
  <div className="banner__text-wrappera">
    <div className="banner__text-contenta">
      <p> If you are passionate about technology and eager to make a difference, we would love to hear from you. To apply for any of the positions listed above, please send your resume and a cover letter to <a href="mailto:hr@sasverse.in">hr@sasverse.in</a> . In your cover letter, please specify the position you are applying for and explain why you would be a great fit for our team.</p>
    </div>
    {/* <div className="banner__button-wrapper">
      <div className="banner__button button button-outline contactModalButton">Book a Call</div>
    </div> */}
  </div>
</div>
</div>

  )
}
