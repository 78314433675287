import React from 'react'

export default function HealthCloudBenifits() {
  return (
    <div id="servicess" >
      <div className="cnts">
        <div className="rows">
          <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Saasverse Pvt Ltd leverages the power of Salesforce Health Cloud to provide you with tailored solutions that elevate patient care. Here's how:</h2>

          <div className="reasons__wrappers">

            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Unified Patient View</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Health Cloud creates a single, holistic view of each patient's medical history, treatment plans, and preferences. This empowers your care teams to make informed decisions, personalize communication, and deliver a more coordinated healthcare experience.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Enhanced Patient Engagement</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Empower patients to take an active role in their health journey. Health Cloud facilitates secure communication channels, allowing patients to easily access lab results, schedule appointments, and refill prescriptions online.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Streamlined Care Coordination</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Break down communication silos between healthcare providers. Health Cloud facilitates real-time collaboration between doctors, nurses, specialists, and other healthcare professionals, ensuring all parties have access to the latest patient information.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Data-Driven Decisions</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Get powerful insights into patient trends and population health metrics. Health Cloud's robust analytics empower you to identify areas for improvement, optimize care delivery models, and achieve better patient outcomes.


                  </p>


                </p>
              </div>
            </div>













          </div>
        </div>
      </div>
    </div>
  )
}
