import React from 'react';
 

const ReviewWhy = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Expert Security Specialists </b> <br/>Our team of certified security experts brings extensive experience in Salesforce security assessments and implementations.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Tailored Solutions</b><br/>We provide personalized security recommendations and solutions tailored to your specific business needs and industry requirements.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Commitment to Excellence</b><br/>We are dedicated to delivering high-quality services that prioritize the security and protection of your Salesforce environment.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Success</b><br/>Our track record of successful security reviews and satisfied clients demonstrates our capability and reliability in ensuring Salesforce security.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/1.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default ReviewWhy;
