// Disclaimer.js
import React from 'react';
import DisclaimerBanner from './DisclaimerBanner';
import './Disclaimer.css'; 

const Disclaimer = () => {
  return (
    <>

    <DisclaimerBanner/>
    <div className="disclaimer">
      <h2>Disclaimer</h2>
      <p>Please read this disclaimer carefully before using the Saasverse Private Limited website (the "Website") or any of our services.</p>

      <h3>1. General Information</h3>
      <p>The information provided on our Website is for general informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the Website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

      <h3>2. Course and Service Information</h3>
      <p>Our courses and services, including any descriptions, schedules, fees, or related details, are provided for general information purposes. We may make updates or changes to course content, availability, or pricing without prior notice. It is your responsibility to verify the most current information by contacting us directly.</p>

      <h3>3. No Professional Advice</h3>
      <p>The information provided on our Website and in our courses does not constitute professional advice or recommendations. It is your responsibility to seek professional advice or conduct independent research to make informed decisions related to Salesforce training or project work.</p>

      <h3>4. Third-Party Links</h3>
      <p>Our Website may include links to third-party websites or resources for your convenience. We do not endorse or have control over the content and availability of these third-party websites. Any reliance on links to external websites is at your own risk, and we are not responsible for any loss or damage resulting from your use of these links.</p>

      <h3>5. Limitation of Liability</h3>
      <p>Saasverse Private Limited shall not be liable for any direct, indirect, incidental, special, or consequential damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with the use of our Website, services, or the information contained therein.</p>

      <h3>6. Changes to the Website</h3>
      <p>We may modify, suspend, or discontinue the Website or any part of it at any time, for any reason, without notice. We are not liable for any changes, interruptions, or termination of the Website.</p>

      <h3>7. Updates to Disclaimer</h3>
      <p>We may update this disclaimer from time to time, and the revised version will be effective immediately upon posting. You are encouraged to review this disclaimer regularly to stay informed about any changes.</p>

      <h3>8. Contact Us</h3>
      <p>If you have any questions or concerns about this disclaimer or any other aspect of our Website or services, please contact us at <a href="mailto:learning@saasverse.in">learning@saasverse.in</a>.</p>

      <p>By using our Website and services, you acknowledge that you have read, understood, and agree to this disclaimer. Your continued use of our Website and services constitutes your acceptance of the terms outlined herein.</p>
    </div>
    </>
  );
};

export default Disclaimer;
