import React from 'react'

export default function WhyKentico() {
  return (
    <div className="info-container">
   
    <div className="info-text">
      <h2>Why Kentico?
      </h2>
      <p>Kentico is a comprehensive digital experience platform (DXP) that combines content management, digital marketing, and e-commerce capabilities. With Kentico, you can:
      </p>
      <p>
       <b style={{color:'#006b95'}}>Deliver Personalized Experiences</b> <br/>Engage your audience with targeted content and personalized user journeys.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Streamline Content Management</b><br/>Efficiently manage and publish content across multiple channels.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Boost Digital Marketing Efforts</b><br/>Utilize advanced marketing tools to drive customer engagement and conversions.</p>
      <p>
       <b style={{color:'#006b95'}}>Scale Seamlessly</b><br/>Easily scale your digital solutions to meet evolving business demands.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/whyaws.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
