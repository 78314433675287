import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseConsumer = () => {
  return (
    <div className="info-container">
     
    <div className="info-text">
      <h2>Saasverse Expertise in Consumer Goods
      </h2>
      <p>At Saasverse, we have a team of Salesforce experts with a deep understanding of the unique challenges faced by the consumer goods industry. We can help you:
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Customize Consumer Goods Cloud to meet your specific needs, whether you're a manufacturer, brand owner, retailer, or distributor.


      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Integrate Consumer Goods Cloud with your existing systems (ERP, WMS) for a unified view of your data and operations.

      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Develop custom applications to address unique challenges, such as managing product lifecycles, optimizing pricing strategies, or streamlining social media marketing campaigns.
       </p>
      <p>
       <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure your staff can leverage Consumer Goods Cloud effectively.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  );
};

export default WhySaasverseConsumer;
