import React from 'react'

export default function ReadySitecore() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Transform Your Digital Experience with Sitecore</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Elevate your digital presence with Saasverse Pvt Ltd’s Sitecore Development Services. Contact us today to discuss your Sitecore needs and start your journey towards delivering exceptional digital experiences.
          </p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
