import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

export default function CPQBanner() {
  const formRef = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zcafogo', 'template_zsg2e4c', formRef.current, 'hHyomKedb7xFnbRSS')
      .then((result) => {
          console.log(result.text);
          setMessage('Email sent successfully!');
      }, (error) => {
          console.log(error.text);
          setMessage('Failed to send email. Please try again.');
      });

    e.target.reset();
  };

  return (
    <div className="banner1">
    <div className="banner-text1">
    <h1>Salesforce CPQ Training & Certification</h1>
  
      <p>Ensure your business gets value from Salesforce</p>
    </div>
    <div className="banner-form1">
        <h2 style={{ fontSize: '20px' }}>Request Call back</h2>
        <form ref={formRef} onSubmit={sendEmail}>
          <input type="text" name="full_name" placeholder="Full Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <input type="tel" name="phone" placeholder="Phone" required />
          <input type="text" name="country" placeholder="Country" required />
          <input type="text" name="course" placeholder="Course" value="Salesforce CPQ Training" required readOnly />
          <textarea name="message" placeholder="Message" required ></textarea>
          <button type="submit">Submit</button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
  </div>
  )
}
