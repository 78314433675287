import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function MarkPart() {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', ''); // Get section ID from URL hash
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' }); // Scroll to the section
      }
    }
  }, [location]);
  return (
    <div id="servicess" >
    <div className="cnts" id='marketingpart'>
      <div className="rows">
        <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Salesforce Marketing Cloud Training</h2>

        <div className="reasons__wrappers">

        

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 1: Introduction to Salesforce Marketing Cloud</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Overview of Salesforce Marketing Cloud</p>

<p>- Key features and benefits</p>

<p>- Understanding the role of Marketing Cloud in digital marketing strategies</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 2: Email Marketing</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Creating and managing email campaigns</p>

<p>- Designing responsive email templates</p>

<p>- Personalization and dynamic content in emails</p>
<p>- A/B testing and optimizing email performance</p>
<p>- Email deliverability best practices</p>











                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 3: Journey Builder</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Understanding customer journeys</p>

<p>- Creating and managing journeys in Journey Builder</p>

<p>- Setting up entry and exit criteria</p>
<p>- Using decision splits and engagement splits</p>
<p>- Personalization and automation in customer journeys</p>






                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 4: Automation Studio</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Introduction to Automation Studio</p>

<p>- Creating and scheduling automation</p>

<p>- Data extraction and file import activities</p>
<p>- Data filtering and segmentation</p>
<p>- Advanced automation workflows</p>















                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 5: Mobile Marketing</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Mobile messaging channels (SMS, push notifications)</p>

<p>- Creating and managing mobile messages</p>

<p>- Mobile message personalization and automation</p>
<p>- Mobile message tracking and analytics</p>











                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 6: Social Media Marketing</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Overview of Social Studio</p>

<p>- Social listening and monitoring</p>

<p>- Social media publishing and engagement</p>
<p>- - Social media advertising and analytics</p>











                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 7: Advertising Studio</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Advertising Studio overview</p>

<p>- Audience segmentation and targeting</p>

<p>- Advertising campaign creation and management</p>
<p>- Advertising data integration and tracking</p>













                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 8: Data Management and Analytics</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>

<p>- Data management practices in Marketing Cloud</p>

<p>- Data synchronization and integration</p>
                <p>- Data extensions and data relationships</p>
                <p>- Reporting and analytics in Marketing Cloud</p>
                <p>- Utilizing Marketing Cloud Connect with Salesforce CRM</p>
          













                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 9: Personalization and Content Recommendations</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Creating personalized experiences with Audience Builder</p>

<p>- Implementing content recommendations with Einstein</p>

<p>- Leveraging AI and machine learning for personalization</p>











                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 10: Marketing Cloud Connect</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Integration between Salesforce CRM and Marketing Cloud</p>

<p>- Syncing and managing data between the platforms</p>

<p>- Leveraging CRM data for targeted marketing campaigns</p>
<p>- Utilizing Sales and Service Cloud data in Marketing Cloud</p>













                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Module 11: Compliance and Security</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                <p>- Data privacy and compliance regulations</p>

<p>- Implementing best practices for data security</p>

<p>- Managing subscriber consent and preferences</p>
<p>- Ensuring email deliverability and sender reputation</p>











                </p>


              </p>
            </div>
          </div>





        </div>
      </div>
    </div>
  </div>
  )
}
