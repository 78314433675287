import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import AIBanner from './AIcompo/AIBanner'
import AIBenifits from './AIcompo/AIBenifits'
import AIBanner2 from './AIcompo/AIBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseAI from './AIcompo/WhySaasverseAI'
import HowAI from './AIcompo/HowAI'

export default function AICompany() {
  return (
    <><AIBanner/>
    <AIBenifits/>
    <WhySaasverseAI/>
    <CertificateSlider/>
    <HowAI/>
    <Trustedby2/>
    <AIBanner2/>
    <Faq/>
      
    </>
  )
}
