import React from 'react'

export default function TechnologyBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Why Salesforce for Tech?<br/></h2>

        <div className="reasons__wrapper">
<p>Saasverse Pvt Ltd understands the relentless drive for innovation in the tech industry. Traditional CRM solutions often struggle to keep pace with the dynamic needs of tech companies. That's where Salesforce leaps ahead. We're here to equip you with a future-proof platform that scales with your ambitions, and propels your tech venture to new heights.
</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Fueling the Sales Engine</b>
                 <p>Automate repetitive tasks, personalize outreach for high-value accounts, and empower sales teams to close deals faster.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Building a Loyal Developer Community</b>
    <p>Foster collaboration, streamline onboarding, and provide self-service portals for developers to access resources and support.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Automated Order Management</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Accelerating Innovation</b>
                 <p>Leverage AI and automation to streamline workflows, personalize customer experiences, and unlock hidden insights for continuous improvement.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Building a Connected Ecosystem</b>
                <p> Integrate seamlessly with your existing development tools and platforms for a unified data flow and a frictionless user experience.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Scaling with Agility</b>
                 <p> The Salesforce platform is built for scalability, ensuring your CRM solution adapts to your ever-growing tech business.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Data-Driven Decision Making</b>
                 <p>Gain real-time insights into customer behavior, product performance, and market trends to make data-driven decisions that propel your business forward.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
        
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
