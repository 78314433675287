import React, { useState, useEffect, memo } from 'react';
import '../css/HeaderSlider.css'; // Import the CSS file

const HeaderSlider = () => {
  const slides = [
    {
      id: 1,
      text1: 'Proud to be the',
      text2: '#1 Ranked',
      text3: 'Salesforce',
      text4: 'Consulting Company',
    },
    {
      id: 2,
      text1: 'We Provide',
      text2: 'Salesforce',
      text3: 'Training',
      text4: ' & Certifications',
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="slider-container">
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
          >
            <div className="slide-content">
              <h1>
                {slide.text1} <br />
                {slide.text2} <br />
                <span className="highlight">{slide.text3}</span> <br />
                {slide.text4}
              </h1>
            </div>
          </div>
        ))}
        <button className="slide-btn prev" onClick={prevSlide}>&#10094;</button>
        <button className="slide-btn next" onClick={nextSlide}>&#10095;</button>
      </div>
      <div className="slider-info">
        
        <div className="word-container1">
          <div className="word" style={{ color: 'white' }}><h2>Design</h2></div>
        </div>
        <div className="word-container2">
          <div className="word" style={{ color: '#006b95' }}><h2>Development</h2></div>
        </div>
        <div className="word-container3">
          <div className="word" style={{ color: 'white' }}><h2>Marketing</h2></div>
        </div>
        <div className="word-container4">
          <div className="word" style={{ color: '#006b95' }}><h2>Agency</h2></div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSlider;
