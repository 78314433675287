import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseFinn = () => {
  return (
    <div className="info-container">
    
      <div className="info-text">
        <h2>Financial Cloud Implementation: A Win-Win Situation
        </h2>
        <p>By implementing a Financial Cloud solution, you not only optimize customer relationships but also reap significant benefits for your business:
        </p>
        <p>
         <b style={{color:'#006b95'}}>Increased Efficiency</b> <br/>Automate manual tasks, streamline workflows, and free up valuable time for your staff.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Improved Operational Agility </b><br/>Respond quickly to changing market conditions and customer demands.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Reduced Costs</b><br/>Eliminate the need for expensive on-premise infrastructure and IT maintenance.</p>
        <p>
         <b style={{color:'#006b95'}}>Scalability and Flexibility</b><br/>Easily scale your operations to accommodate growth and changing business needs.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseFinn;
