import React from 'react'

export default function ServiceCaseBanner() {
  return (
    <section className="about-us-bannercl" >
    <div className="banner-containercl">
      <picture>
        <source media="(min-width: )" srcSet="/assets/images/servicebanner.jpg" />
        <img src="/assets/images/casestudy/servicebanner.jpg" alt="About Us" />
      </picture>
      <div className="banner-content-overlaycl">
        <h1>Improving Customer Service Excellence with Salesforce Service Cloud</h1>
        <p>We are a thriving squad of nerd minds, think tankers, pro coders, business geeks, and awesomely creative beings.</p>
      </div>
      <div className="client-namecl">
        <p style={{color:'#003e95', fontWeight:'600',fontSize:'20px'}}>Client Overview</p>
    <p> Client : : Global Travel Solutions (GTS)</p>
   <p> Industry : : Travel and Hospitality</p>
    <p>Location : : Miami, USA</p>
      </div>
    </div>
  </section>
  )
}
