import React from 'react'

export default function ReviewBenifits() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}>Our Salesforce Security Review Services <br/></h2>

        <div className="reasons__wrapper">

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Comprehensive Security Assessment</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Thorough Analysis:</b>
                 <p>Conduct a detailed evaluation of your Salesforce instance, including user permissions, data access controls, and configuration settings.
                 </p>
<b style={{color:'#006b95'}}>Vulnerability Identification:</b> 
<p> Identify potential security vulnerabilities, loopholes, and weaknesses that could compromise your Salesforce environment.
</p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Customized Security Recommendations</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Actionable Insights:</b>
    <p> Provide personalized recommendations and best practices to address identified security gaps and strengthen your Salesforce security posture.</p>
   
<b style={{color:'#006b95'}}> Security Policy Development:</b>
<p> Assist in developing and implementing security policies and procedures to mitigate risks and maintain compliance.
</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


       
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">User Access Control Review</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Permission Set Analysis:</b>
                 <p>Review and analyze user permission sets, profiles, and roles to ensure appropriate access controls are in place.
                 </p>
<b style={{color:'#006b95'}}>User Activity Monitoring</b> 
<p>Monitor user activity and behavior to detect anomalies and suspicious activities that may indicate security threats.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Data Encryption and Protection
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Data Encryption:</b>
                <p> Implement encryption mechanisms to protect sensitive data at rest and in transit.</p>
<b style={{color:'#006b95'}}>Data Loss Prevention:</b> 
<p>Set up data loss prevention measures to prevent unauthorized data leakage and ensure data integrity.</p>
{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title"> Compliance Assessment</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Regulatory Compliance Check:</b>
                 <p>Assess your Salesforce environment against relevant regulatory requirements and industry standards.
                 </p>
<b style={{color:'#006b95'}}>Audit Trail Review: </b> 
<p>Review audit trails and logs to ensure compliance with data access and usage regulations.
</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
