import React from 'react'

export default function WhySaasverseK() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4>
    </div>
    <div className="columnt">
      <p><b>Certified Kentico Experts</b><br/>Our team comprises certified Kentico professionals with extensive experience in Kentico development and implementation.</p>
      <br/> <p><b>Customized Solutions</b> <br/>We deliver bespoke Kentico solutions that align with your business objectives and workflows.</p>
    </div>
    <div className="columnt">
      <p><b>Proven Excellence</b><br/>We are committed to delivering high-quality services that exceed expectations and drive measurable results.</p>
      <br/> <p><b>Client-Centric Approach</b><br/>Our successful track record and numerous satisfied clients testify to our dedication and expertise.</p>
    </div>
  </div>
  )
}
