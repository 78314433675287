import React from 'react';
import { useNavigate } from 'react-router-dom';

const TrainingType = () => {
  
  const navigate = useNavigate();
  const jobOpenings = [
    { 
      id: 1, 
      title: 'Salesforce Admin Training', 
      route: '/apply/salesforce-admin#adminpart',
      experience: 'Fundamentals: Understand the core concepts of Salesforce administration, including user management, security settings, and data management.', 
      description: 'Advanced Admin: Dive deeper into advanced features like workflow automation, reporting, and dashboards.'
    },
    { 
      id: 2, 
      title: 'Salesforce Developer Training', 
      route: '/apply/salesforce-developer#devpart',
      experience: 'Basic Development: Learn the essentials of Salesforce development, including Apex programming, Visualforce, and Lightning components.', 
      description: 'Advanced Development: Master advanced topics such as integration, custom app development, and performance optimization.'
    },
    { 
      id: 3, 
      title: 'Salesforce CPQ Training', 
      route: '/apply/salesforce-cpq#cpqpart',
      experience: 'CPQ Basics: Get to grips with the fundamentals of Salesforce CPQ (Configure, Price, Quote), including product configuration, pricing rules, and quoting processes.', 
      description: 'Advanced CPQ: Explore advanced CPQ functionalities, such as advanced pricing, discounting strategies, and proposal generation.'
    },
    { 
      id: 4, 
      title: 'Salesforce Marketing Cloud Training', 
      route: '/apply/marketing-salesforce#marketingpart',
      experience: 'Marketing Cloud Training: Drive marketing success with targeted Marketing Cloud training, covering email marketing, automation, and analytics.', 
      // description: 'Marketing Cloud Training: Drive marketing success with targeted Marketing Cloud training, covering email marketing, automation, and analytics.'
    },
    // Add more job openings as needed
  ];

  return (
    <div className="job-openings">
      <h2 className="job-openings-title">Our Salesforce Training Programs</h2>
      <div className="job-list">
        {jobOpenings.map(job => (
          <div key={job.id} className="job-item">
            <h3 className="job-title" style={{fontSize:'35px',fontWeight:'500'}}>{job.title}</h3>
            <div className="job-details">
              <p><span className="label"></span>{job.experience}</p>
              {/* Use an anchor tag for navigation */}
              <a className="apply-button" href={job.route} style={{textDecoration:'none'}}><b>Apply</b></a>
            </div>
            <div className="job-description">
              <p><span className="label"></span>{job.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingType;
