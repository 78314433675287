import React from 'react'

export default function ManagedBenifits() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center'}}>Our Salesforce Managed Services<br/></h2>

        <div className="reasons__wrapper">

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Regular Maintenance and Updates</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>System Health Checks:</b>
                 <p>Conduct regular health checks to ensure your Salesforce instance is performing optimally.
                 </p>
<b style={{color:'#006b95'}}>Security Updates:</b> 
<p>Implement the latest security patches and updates to protect your data and maintain compliance.
</p>
<p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p>
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Dedicated Support Teams</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Proactive Monitoring:</b>
    <p> Our dedicated teams continuously monitor your Salesforce environment to identify and resolve issues before they impact your operations.</p>
   
<b style={{color:'#006b95'}}>Responsive Support:</b>
<p>We provide timely and efficient support to address any questions or issues, ensuring minimal downtime and disruption.
</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Customization and Optimization</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Tailored Customizations:</b>
                 <p>Adjust and customize Salesforce configurations to better align with your evolving business processes.
                 </p>
<b style={{color:'#006b95'}}>Performance Optimization:</b> 
<p>Optimize workflows, reports, and dashboards to improve efficiency and user experience.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Data Management and Integration
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Data Quality Management:</b>
                <p>Ensure data accuracy, consistency, and integrity across your Salesforce environment.</p>
<b style={{color:'#006b95'}}> Integration Maintenance:</b> 
<p> Maintain and optimize integrations with other systems to ensure seamless data flow and operational efficiency.</p>
{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Training and User Adoption</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Ongoing Training:</b>
                 <p> Provide continuous training sessions to keep your team updated on new features and best practices.
                 </p>
<b style={{color:'#006b95'}}>User Adoption Programs: </b> 
<p>Implement strategies to drive user adoption and maximize the value of your Salesforce investment.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
