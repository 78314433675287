import React from 'react'

export default function SalesClient() {
  return (
    <div className="client-info-containerb">
    <div className="client-backgroundb">
    <h2>Business  <span style={{color:'#003e95'}}>Challenge</span></h2>
      <p>Tech Solutions Inc. faced challenges in managing their sales processes efficiently. With a growing customer base and complex sales cycles, they struggled with disparate systems for lead management, opportunity tracking, and customer communication. They needed a solution to streamline their sales operations, improve sales team productivity, and gain better insights into their sales pipeline.</p>
     
    </div>
 
  </div>
  )
}
