import React from 'react'

export default function HealthIndustryBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Why Salesforce for Healthcare?<br/></h2>

        <div className="reasons__wrapper">
<p>Saasverse Pvt Ltd understands the ever-evolving needs of the healthcare industry. In today's data-driven landscape, healthcare providers require a secure and scalable platform to manage patient relationships, streamline workflows, and deliver exceptional care. This is where Salesforce Health Cloud steps in, and Saasverse is here to unlock its full potential for your organization.
</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Unified Patient View</b>
                 <p> Gain a 360-degree view of your patients, including medical history, treatment plans, and communication preferences.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Enhanced Patient Engagement</b>
    <p>Foster stronger relationships with patients through personalized communication and self-service portals.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Automated Order Management</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Improved Care Coordination</b>
                 <p>Break down silos and enable seamless collaboration between care teams.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Streamlined Operations</b>
                <p>Automate tasks, optimize workflows, and boost operational efficiency.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Compliance Management</b>
                 <p>Ensure patient data security and HIPAA compliance with robust security features.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Population Health Management</b>
                 <p>Proactively identify at-risk patients and tailor preventive care programs.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
        
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
