import React, { useState } from 'react';


export default function SitecoreFaq() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);


    const toggleContent = () => {
        setIsOpen(!isOpen);
    };
    const toggleContent1 = () => {
        setIsOpen1(!isOpen1);
    };
    const toggleContent2 = () => {
        setIsOpen2(!isOpen2);
    };
    const toggleContent3 = () => {
        setIsOpen3(!isOpen3);
    };
    const toggleContent4 = () => {
        setIsOpen4(!isOpen4);
    };
    const toggleContent5 = () => {
        setIsOpen5(!isOpen5);
    };


  return (
    <div className="faq-container">
    <div className="faq-row">
        <h2 className="faq-title" style={{color:'black'}}>FAQ's</h2>
        <div className="faq-collapse">
            <div className="faq-accordion">
                <div className="faq-accordion-content" itemScope itemType="https://schema.org/FAQPage">

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen ? 'faq-active' : ''}`} onClick={toggleContent}>
                        What is Sitecore and why should I use it for my business?
                        </div>
                        <div className={`faq-card-content ${isOpen ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Sitecore is a powerful digital experience platform that enables businesses to deliver personalized content and seamless customer journeys. It helps improve engagement, scalability, and provides data-driven insights.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen1 ? 'faq-active' : ''}`} onClick={toggleContent1}>
                        How can Saasverse Pvt Ltd help with Sitecore implementation?
                        </div>
                        <div className={`faq-card-content ${isOpen1 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Saasverse provides end-to-end Sitecore implementation services, from strategy and planning to installation, configuration, and data migration, ensuring a smooth and successful deployment.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen2 ? 'faq-active' : ''}`} onClick={toggleContent2}>
                        Can Sitecore be customized to fit my specific business needs?
                        </div>
                        <div className={`faq-card-content ${isOpen2 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Yes, Sitecore is highly customizable. Saasverse can tailor existing modules or develop new ones to meet your unique business requirements.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen3 ? 'faq-active' : ''}`} onClick={toggleContent3}>
                        What types of integrations can be done with Sitecore?
                        </div>
                        <div className={`faq-card-content ${isOpen3 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Sitecore can be integrated with various third-party solutions such as CRMs, e-commerce platforms, and marketing automation tools. We also develop custom APIs for seamless data exchange.
                            </p>
                        </div>
                    </div>
                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen4 ? 'faq-active' : ''}`} onClick={toggleContent4}>
                        What kind of support can I expect after Sitecore implementation?
                        </div>
                        <div className={`faq-card-content ${isOpen4 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Saasverse offers ongoing support and maintenance services, including system monitoring, updates, troubleshooting, and user training to ensure your Sitecore environment runs smoothly.
                            </p>
                        </div>
                    </div>
                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen5 ? 'faq-active' : ''}`} onClick={toggleContent5}>
                        How does Saasverse handle Sitecore migrations?
                        </div>
                        <div className={`faq-card-content ${isOpen5 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            We manage both version upgrades and migrations from other CMS platforms to Sitecore, ensuring data integrity and a seamless transition.
                            </p>
                        </div>
                    </div>

                

                </div>
            </div>
        </div>
    </div>
</div>
  )
}
