import React from 'react'

export default function AWSServices() {
  return (
    <div id="servicess" >
        <div className="cnts">
          <div className="rows">
      <h2 style={{fontSize:'40px',textAlign:'center',paddingBottom:'20px'}}>Our AWS Development Services
      </h2>
      <p>At Saasverse, we offer more than just a job – we offer a career filled with opportunities for growth, learning, and advancement. Here’s why you should consider joining our team:</p><br/>
    
            <div className="reasons__wrappers">
    
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">AWS Cloud Migration</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Assessment and Planning</b>
                     <p>Conduct a thorough assessment of your existing infrastructure and develop a customized migration strategy.

                     </p>
                     <b style={{color:'#006b95'}}>Seamless Migration</b>
                     <p> Execute a seamless migration to AWS with minimal disruption to your business operations.

                     </p>
                     <b style={{color:'#006b95'}}>Post-Migration Optimization</b>
                     <p>Optimize your new AWS environment for performance, security, and cost-efficiency.

                     </p>
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Cloud-Native Application Development</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Custom Application Development</b>
                     <p> Build scalable and resilient cloud-native applications using AWS services like Lambda, ECS, and RDS.

                     </p>
                     <b style={{color:'#006b95'}}>Serverless Architecture</b>
                     <p> Leverage serverless technologies to reduce operational overhead and improve agility.

                     </p>
                     <b style={{color:'#006b95'}}>API Development and Integration</b>
                     <p>Develop robust APIs and integrate them with other cloud services and on-premises systems.

                     </p>
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">AWS Infrastructure Management</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Infrastructure as Code (IaC)</b>
                     <p>Implement IaC practices using tools like AWS CloudFormation and Terraform to automate infrastructure management.

                     </p>
                     <b style={{color:'#006b95'}}>Monitoring and Logging</b>
                     <p>Set up comprehensive monitoring and logging solutions to gain visibility into your AWS environment.

                     </p>
                     <b style={{color:'#006b95'}}>Security and Compliance</b>
                     <p>Ensure your AWS infrastructure adheres to best practices for security and compliance.

                     </p>
   
   
    </p>
                </div>
              </div>


              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">DevOps and Continuous Integration/Continuous Deployment (CI/CD)</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>DevOps Implementation</b>
                     <p>Implement DevOps practices and tools to streamline your development and deployment processes.

                     </p>
                     <b style={{color:'#006b95'}}>CI/CD Pipelines</b>
                     <p>Set up CI/CD pipelines using AWS CodePipeline, CodeBuild, and CodeDeploy to automate application delivery.

                     </p>
                     <b style={{color:'#006b95'}}>Automated Testing</b>
                     <p>Integrate automated testing into your CI/CD pipelines to ensure high-quality code releases.

                     </p>
   
   
    </p>
                </div>
              </div>
          
          
    
             
            
    
    
             
    
    
             
            
    
             
          
            </div>
          </div>
        </div>
      </div>
  )
}
