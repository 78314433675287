import React from 'react';
import './css/Our.css';

export default function Our() {
  return (
    <div className="our-container">
      <div className="our-row">
        {/* Column for 'Who We Are' */}
        <div className="our-col d-flex align-items-center">
          <div className="our-section-content-wrapper">
            
            <div className="our-section">
              <h2 className="our-section-title">Who We Are</h2>
              <p className="our-section-content">
                Saasverse is a team of passionate and highly skilled professionals committed to transforming businesses through cutting-edge technology. We specialize in Salesforce implementation and customization, app development, data integration, and automation. Our deep industry knowledge and technical expertise enable us to craft tailored solutions that meet the unique needs of each client.
              </p>
            </div>
            <img src="/assets/images/websiteimages/6.jpg" alt="Who We Are" className="our-section-image mr-4" />
          </div>
        </div>
        
        {/* Column for 'Our Approach' */}
        <div className="our-col d-flex align-items-center">
          <div className="our-section-content-wrapper">
            <img src="/assets/images/websiteimages/4.jpg" alt="Our Approach" className="our-section-image mr-4" />
            <div className="our-section">
              <h2 className="our-section-title">Our Approach</h2>
              <p className="our-section-content">
                We believe in a personalized approach to business solutions. Understanding that every business has its own set of challenges and goals, we take the time to thoroughly understand your specific needs. This allows us to deliver bespoke solutions that not only address your current pain points but also position your business for future success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
