import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseAI = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Choose Saasverse Pvt Ltd for Your AI Needs?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Proven Track Record of Success</b> <br/>We have a proven track record of helping businesses across various industries leverage AI to achieve tangible results.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Focus on Transparency & Explainability</b><br/>We understand the importance of understanding how AI models arrive at their conclusions. We ensure our solutions are transparent and explainable, building trust and confidence in AI decision-making.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Ongoing Support & Optimization</b><br/>Our commitment doesn't end with implementation. We provide ongoing support and maintenance to ensure your AI solution continues to deliver value over time.</p>
       
      </div>
    </div>
  );
};

export default WhySaasverseAI;
