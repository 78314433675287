import React from 'react'

export default function JustProduct() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Product Management</h2>
      <p>Our Salesforce Product Managers offer a comprehensive skillset</p>
    </div>
    <div className="columnt">
      <p><b>Requirements Gathering & Analysis</b><br/>They work closely with stakeholders to define user needs, business objectives, and product requirements..
      </p>
      <br/> <p><b>User Research & Testing</b> <br/>Our PMs conduct user research and testing to ensure the product aligns with user needs and expectations.
      </p>
    </div>
    <div className="columnt">
      <p><b>Agile Project Management</b><br/>They are adept at agile methodologies, enabling flexible and iterative product development.</p>
      <br/> <p><b>Roadmap Development & Prioritization</b><br/>Our PMs develop a clear roadmap for product development, prioritizing features based on business value and user impact</p>
    </div>
  </div>
  )
}
