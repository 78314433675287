import React from 'react'

export default function ServiceConclusion() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Conclusion</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Salesforce Service Cloud has enabled Global Travel Solutions to transform their customer service operations, delivering superior service experiences and driving customer loyalty. By centralizing customer interactions, optimizing service workflows, and leveraging advanced analytics, GTS has positioned itself as a leader in the travel and hospitality industry, setting new standards for customer service excellence.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
