import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseCPQ = () => {
  return (
    <div className="info-container">
      
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Proven CPQ Experts</b> <br/> Our team boasts extensive experience implementing leading CPQ solutions. We ensure your CPQ system is configured to meet your specific needs, maximizing its impact on your sales process.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Data-Driven Approach</b><br/>We analyze your existing data to identify inefficiencies and configure your CPQ system to address your unique challenges.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Seamless Integrations</b><br/>We integrate your CPQ solution with your CRM and other business systems, ensuring a smooth flow of data and eliminating data silos.</p>
        <p>
         <b style={{color:'#006b95'}}>Change Management Expertise</b><br/>We understand the importance of user adoption. We provide comprehensive training and ongoing support to ensure your sales team utilizes the CPQ system effectively.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseCPQ;
