import React from 'react'

export default function JustDeveloper() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Development</h2>
      <p>Our Salesforce Developers offer a comprehensive skillset</p>
    </div>
    <div className="columnt">
      <p><b>Requirements Gathering & Analysis</b><br/>They collaborate with stakeholders to understand your business needs and translate them into technical specifications.
      </p>
      <br/> <p><b>Solution Design & Architecture</b> <br/>Our developers design and architect custom solutions that are secure, scalable, and maintainable.
      </p>
    </div>
    <div className="columnt">
      <p><b>User Interface (UI) & User Experience (UX) Focus</b><br/>They prioritize intuitive user interfaces and positive user experiences to ensure user adoption and satisfaction.</p>
      <br/> <p><b>Ongoing Support & Maintenance</b><br/>Our developers provide continuous support to maintain your Salesforce customizations and address any technical issues.</p>
    </div>
  </div>
  )
}
