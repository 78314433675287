import React from 'react'
import SalesCloudBanner from './salescompo/SalesCloudBanner'
import SalesCloudBenifits from './salescompo/SalesCloudBenifits'
import SalesCloudBanner2 from './salescompo/SalesCloudBanner2'
import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import SalesCloudReadyBanner from './salescompo/SalesCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function SalesCloud() {
  return (
    <><SalesCloudBanner/>
    <SalesCloudBenifits/>
    <SalesCloudBanner2/>
    <WhySaasverse/>
    <CertificateSlider/>
    <Trustedby2/>
    <SalesCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
