import React from 'react'
import { Link } from 'react-router-dom'

export default function MuleSoftReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to unlock the true potential of your CRM?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Don't wait! Contact Saasverse Pvt Ltd today and discover how our Mulesoft development expertise can transform your CRM into a powerful business asset, automating workflows, connecting seamlessly, and driving significant business growth.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
