import React from 'react';


const WhySaasverseEnergy = () => {
  return (
    <div className="info-container">
     
    <div className="info-text">
      <h2>Saasverse: Your Energy Industry Experts
      </h2>
      <p>At Saasverse, we understand the complexities of the energy sector. Our team of Salesforce experts brings a deep understanding of the industry's unique challenges. We can help you:
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Customize Salesforce solutions to meet your specific needs, whether you're a utility provider, a renewable energy company, or an energy management consultant.


      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Integrate seamlessly with your existing systems (metering systems, asset management platforms) to gain a holistic view of your operations and customer data.
      </p>
      <p>
      <b style={{color:'#006b95'}}>-</b> Develop custom applications to address specific challenges, such as managing smart grid infrastructure or optimizing energy usage for large commercial customers.
       </p>
      <p>
       <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training, empowering your team to leverage Salesforce and drive innovation in the energy sector.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  );
};

export default WhySaasverseEnergy;
