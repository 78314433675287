import React from 'react'
import KenticoBanner from './Compo/KenticoBanner'
import KenticoPower from './Compo/KenticoPower'
import WhyKentico from './Compo/WhyKentico'
import KenticoServices from './Compo/KenticoServices'
import ReadyKentico from './Compo/ReadyKentico'
import KenticoFaq from './Compo/KenticoFaq'
import WhySaasverseK from './Compo/WhySaasverseK'

export default function Kentico() {
  return (
    <>
      <KenticoBanner/>
      <KenticoPower/>
      <WhyKentico/>
      <KenticoServices/>
      <WhySaasverseK/>
      <ReadyKentico/>
      <KenticoFaq/>
    </>
  )
}
