import React, { useEffect } from 'react';
import '../css/AboutSaasverse.css';

const AboutSaasverse = () => {
  useEffect(() => {
    const handleScroll = () => {
      const items = document.querySelectorAll('.about-saasverse-list li');
      
      items.forEach(item => {
        const itemTop = item.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        
        if (itemTop < windowHeight - 100) {
          item.classList.add('show');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="about-saasverse-container">
     
      <div className="about-saasverse-content">
        <div className="about-saasverse-image">
          <img src="/assets/images/services/team.jpg" alt="Image Alt Text" />
        </div>
        <div className="about-saasverse-text"  >
          <p style={{color:'grey',marginBottom:'-10px',paddingBottom:'0px'}}>Welcome to Saasverse</p>
      <h2 className="centered-heading" style={{textAlign:'left',color:'black'}}>About Us</h2>
      <p>
        Saasverse Technologies offers cutting-edge solutions to businesses
        looking to optimize their operations and processes. We customize
        our services, including Salesforce deployment and modification, app
        development, data integration, and automation, to match the unique
        demands of each customer. In order to guarantee that clients get
        the most out of their investment, we also offer continuous
        assistance and training. Our design team adheres to the most recent
        trends and best practices to develop visually appealing and
        approachable interfaces while maintaining brand coherence.
        Dedicated to quality, we go above and beyond for clients, helping
        companies prosper within the Salesforce ecosystem.
      </p>
    </div>
      </div>
    </div>
  );
};

export default AboutSaasverse;
