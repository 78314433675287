import React from 'react'


import Faq from '../../Faq/Faq'


import HireProductBanner from './hireproductcompo/HireProductBanner'
import HireProductBenifits from './hireproductcompo/HireProductBenifits'
import HireProductBanner2 from './hireproductcompo/HireProductBanner2'
import HireProductReadyBanner from './hireproductcompo/HireProductReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseProd from './hireproductcompo/WhySaasverseProd'
import JustProduct from './hireproductcompo/JustProduct'

export default function HireProductManager() {
  return (
    <><HireProductBanner/>
    <HireProductBenifits/>
    <HireProductBanner2/>
    <WhySaasverseProd/>
    <CertificateSlider/>
    <JustProduct/>
    <Trustedby2/>
    <HireProductReadyBanner/>
    <Faq/>
      
    </>
  )
}
