import React from 'react'


import Faq from '../../Faq/Faq'


import HealthIndustryBanner from './healthindustrycompo/HealthIndustryBanner'
import HealthIndustryBenifits from './healthindustrycompo/HealthIndustryBenifits'
import HealthIndustryBanner2 from './healthindustrycompo/HealthIndustryBanner2'
import HealthIndustryReadyBanner from './healthindustrycompo/HealthIndustryReadyBanner'
import WhySaasverseHealth from './healthindustrycompo/WhySaasverseHealth'

export default function HealthIndustry() {
  return (
    <><HealthIndustryBanner/>
    <HealthIndustryBenifits/>
    <HealthIndustryBanner2/>
    <WhySaasverseHealth/>
    <HealthIndustryReadyBanner/>
    <Faq/>
      
    </>
  )
}
