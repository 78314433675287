import {React,useState} from 'react'
import { Link } from 'react-router-dom'


// import './css/Banner2.css'
export default function TrainingBanner2() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <>
        {/* <div className="sp-line"></div> */}
      <div className="bannerL">
        <div className="container-md">
          <div className="bannerL__container">
            <div className="bannerL__wrapper">
              <h2 className="bannerL__title">Unlock the Full Potential of Salesforce</h2>
              <h5 className="bannerL__subtitle">Partner with Saasverse Pvt Ltd for expert Salesforce Training Services and empower your team to achieve excellence. Contact us today to discuss your training needs and start your journey towards mastering Salesforce.
              </h5>
              <div className="bannerL__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</Link></div>

              {/* <div className="bannerL__list">
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">120+</h2>
                  <h6 className="bannerL__list-item_text">Delivered projects</h6>
                </div>
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">50+</h2>
                  <h6 className="bannerL__list-item_text">Salesforce certified specialists</h6>
                </div>
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">8+</h2>
                  <h6 className="bannerL__list-item_text">years of experience</h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
