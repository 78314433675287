import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseAd = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Hire a Salesforce Administrator from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Certified Expertise</b> <br/>Our Admins are Salesforce-certified professionals with a deep understanding of platform functionalities, security protocols, and best practices.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Customization Prowess</b><br/>They can customize Salesforce to match your specific workflows and user requirements, optimizing your Salesforce experience.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Data Management Masters</b><br/>Our Admins are experts in data management, ensuring data accuracy, integrity, and compliance with security regulations.</p>
        <p>
         <b style={{color:'#006b95'}}>Automation Champions</b><br/>They leverage automation tools to streamline repetitive tasks, freeing up your team's time to focus on higher-value activities.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Security & User Management</b><br/>Our Admins prioritize platform security, user access control, and data encryption to safeguard your valuable information.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Integration Experts</b><br/>They can seamlessly integrate Salesforce with your existing business systems, eliminating data silos and fostering a unified data environment.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseAd;
