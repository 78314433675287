import React from 'react'


import Faq from '../../Faq/Faq'

import TableauBanner from './tableaucompo/TableauBanner'
import TableauBenifits from './tableaucompo/TableauBenifits'
import TableauBanner2 from './tableaucompo/TableauBanner2'
import WhySaasverseTab from './tableaucompo/WhySaasverseTab'
import TableauReadyBanner from './tableaucompo/TableauReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function TableauCRM() {
  return (
    <><TableauBanner/>
    <TableauBenifits/>
    <TableauBanner2/>
    <WhySaasverseTab/>
    <CertificateSlider/>
    <Trustedby2/>
    <TableauReadyBanner/>
    <Faq/>
      
    </>
  )
}
