import React from 'react'

export default function TableauBenifits() {
  return (
    <div id="servicess" >
      <div className="cnts">
        <div className="rows">
          <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Here's how we empower you to unlock the true power of your data</h2>

          <div className="reasons__wrappers">

            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Effortless Data Exploration</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Say goodbye to complex queries and endless spreadsheets. Tableau CRM's intuitive interface allows anyone, regardless of technical skill, to easily explore and analyze data directly within Salesforce.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Dynamic Insights at Your Fingertips</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Get real-time insights into customer behavior, sales trends, and overall business performance. No more waiting for reports – Tableau CRM delivers data visualizations and insights instantly, so you can make informed decisions faster.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Uncover Hidden Patterns</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Go beyond basic reporting. Tableau CRM's powerful analytics capabilities help you identify hidden trends, correlations, and opportunities you might have missed before.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Drive Actionable Results</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Don't just see data, use it to take action. Tableau CRM allows you to drill down into specific details, identify areas for improvement, and tailor strategies based on real-time insights.


                  </p>


                </p>
              </div>
            </div>













          </div>
        </div>
      </div>
    </div>
  )
}
