import React from 'react';
import './../../SalesforceConsulting/compo/Why.css'; 

const CustomWhy = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/3.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Opt for Custom Development?
        </h2>
        <p>Every business is unique, with distinct processes, goals, and challenges. Off-the-shelf solutions may not always meet your specific needs. That’s where our custom development services come in. With Salesforce Custom Development, you can
        </p>
        
        <p>
         <b style={{color:'#006b95'}}>Bespoke Solutions</b> <br/> We develop custom applications tailored to your business requirements, ensuring they seamlessly integrate with your existing Salesforce environment.


        </p>
        <p>
          <b style={{color:'#006b95'}}>Advanced Coding</b><br/>Our team leverages Salesforce’s Apex and Visualforce to build robust and scalable applications.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Lightning Components</b><br/> We create dynamic and responsive Lightning Components to enhance the user experience and functionality.</p>
      
      
      </div>
    </div>
  );
};

export default CustomWhy;
