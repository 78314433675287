import React from 'react'

export default function WhyWork() {
  return (
    <div id="servicess" >
        <div className="cnts">
          <div className="rows">
      <h2 style={{fontSize:'40px',textAlign:'center',paddingBottom:'20px'}}> Why Work With Us?
      </h2>
      <p>At Saasverse, we offer more than just a job – we offer a career filled with opportunities for growth, learning, and advancement. Here’s why you should consider joining our team:</p>
    
            <div className="reasons__wrappers">
    
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                  <h3 className="reasons__item_titles">Innovation and Excellence</h3>
                  <p className="reasons__item_texts">
                     {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                     <p>Be a part of a team that consistently pushes the boundaries of technology to deliver cutting-edge solutions.

                     </p>
   
   
    </p>
                </div>
              </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                  <h3 className="reasons__item_titles">Professional Development</h3>
                  <p className="reasons__item_texts">
                     {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                     <p> We invest in our employees' growth through continuous learning opportunities, training programs, and career advancement paths.


                     </p>
   
   
    </p>
                </div>
              </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                  <h3 className="reasons__item_titles">Collaborative Culture</h3>
                  <p className="reasons__item_texts">
                     {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                     <p> Work alongside talented professionals in a supportive and collaborative environment that encourages creativity and innovation.


                     </p>
   
   
    </p>
                </div>
              </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                  <h3 className="reasons__item_titles">Work-Life Balance</h3>
                  <p className="reasons__item_texts">
                     {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                     <p>We understand the importance of work-life balance and offer flexible working arrangements to help you maintain it.


                     </p>
   
   
    </p>
                </div>
              </div>
    
             
            
    
    
             
    
    
             
            
    
             
          
            </div>
          </div>
        </div>
      </div>
  )
}
