import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseExp = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Experience Cloud Architects, Not Just Builders</b> <br/> Our team boasts extensive experience in Experience Cloud. We don't just build websites; we craft strategic digital experiences that align with your business goals and drive measurable results.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Data Champions</b><br/>We go beyond guesswork. We leverage data to understand your audience and personalize every interaction. This ensures your content and offerings resonate with each visitor, maximizing engagement and conversions.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Seamless Integrations</b><br/> We connect Experience Cloud with your existing CRM, marketing automation, and other business-critical tools. This creates a unified customer experience across all touchpoints, eliminating data silos and streamlining operations.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseExp;
