import React from 'react'

export default function CPQBenifits() {
  return (
    <div id="servicess" >
    <div className="cnts">
      <div className="rows">
        <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Saasverse Pvt Ltd unlocks the power of CPQ solutions</h2>

        <div className="reasons__wrappers">
<p>Struggling with manual quoting, pricing inconsistencies, and slow sales cycles? You're not alone. Traditional quote-to-cash processes can be cumbersome, hindering your sales team's efficiency and impacting your bottom line.</p>
        {/* <p>Saasverse Pvt Ltd unlocks the power of CPQ (Configure, Price, Quote) solutions, empowering you to:</p> */}
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Automate Quote Generation</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Eliminate manual data entry and errors with automated quoting. CPQ streamlines the process, allowing your sales reps to focus on closing deals, not paperwork.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Accurate Pricing & Profitability</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Ensure consistent and accurate pricing across all quotes. CPQ helps you define pricing rules and discounts, preventing revenue leakage.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Improved Sales Cycle Efficiency</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Streamline quote approvals and expedite the sales cycle with automated workflows. Get quotes out faster and close deals quicker.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Enhanced Sales Team Productivity</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p> Equip your sales reps with the tools they need to succeed. CPQ simplifies the quoting process, freeing up time for selling activities.


                </p>


              </p>
            </div>
          </div>













        </div>
      </div>
    </div>
  </div>
  )
}
