import React from 'react'

export default function ExperienceCloudBenifits() {
  return (
    <div id="servicess" >
    <div className="cnts">
      <div className="rows">
        <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Here's how Saasverse Pvt Ltd unlocks the full potential of Experience Cloud for you:
        </h2>

        <div className="reasons__wrappers">

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Craft Personalized Journeys</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Go beyond basic content. We design personalized experiences that adapt to each visitor's needs and interests. Imagine a website that recommends relevant products based on past purchases, or a mobile app that delivers targeted offers in real-time.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Boost Engagement & Loyalty</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Leverage data-driven insights to understand your audience better than ever before. We personalize every interaction, from content recommendations and targeted offers to interactive features and gamification elements. This fosters deeper engagement, builds lasting loyalty, and keeps your customers coming back for more.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Empower Your Brand Advocates</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Experience Cloud's powerful community features are more than just forums. We help you build thriving online communities where brand advocates can connect, share knowledge, and support each other. This fosters a sense of belonging and strengthens brand loyalty.

                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Drive Measurable Results</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>Forget vanity metrics. We track what truly matters – increased customer lifetime value, reduced churn rates, and a thriving online community. Our Experience Cloud solutions are designed to deliver a positive return on investment (ROI) for your business.


                </p>


              </p>
            </div>
          </div>













        </div>
      </div>
    </div>
  </div>
  )
}
