import React from 'react';
import './css/Case1Banner.css'

export default function Case1Banner() {
  return (
    <section className="about-us-bannercl" >
      <div className="banner-containercl">
        <picture>
          <source media="(min-width: )" srcSet="/assets/images/aboutus.jpg" />
          <img src="/assets/images/casestudy/h1.webp" alt="About Us" />
        </picture>
        <div className="banner-content-overlaycl">
          <h1>Transforming Patient Care with Salesforce Healthcare Cloud</h1>
          <p>We are a thriving squad of nerd minds, think tankers, pro coders, business geeks, and awesomely creative beings.</p>
        </div>
        <div className="client-namecl">
        <p style={{color:'#003e95', fontWeight:'600',fontSize:'20px'}}>Client Overview</p>
      <p> Client : : Unity Health Systems (UHS)</p>
      <p>Industry: : Healthcare</p>
      <p>Location : : San Francisco, USA</p>
        </div>
      </div>
    </section>
  );
}
