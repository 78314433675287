import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'
import FieldsBanner from './fieldscompo/FieldsBanner'
import FieldsBenifits from './fieldscompo/FieldsBenifits'
import FieldsBanner2 from './fieldscompo/FieldsBanner2'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import FieldsReadyBanner from './fieldscompo/FieldsReadyBanner'
import WhySaasverseField from './fieldscompo/WhySaasverseField'
import FieldNext from './fieldscompo/FieldNext'

export default function Fields() {
  return (
    <><FieldsBanner/>
    <FieldsBenifits/>
    <FieldsBanner2/>
    <WhySaasverseField/>
    <CertificateSlider/>
    <FieldNext/>
    <Trustedby2/>
    <FieldsReadyBanner/>
    <Faq/>
      
    </>
  )
}
