import React from 'react'

// import WhySaasverse from '../../Services/Salesforce/SalesforceAppExchange/Compo/WhySaasverse'
import Faq from '../../Faq/Faq'





import HireConsultantBanner from './hireconsultantcompo/HireConsultantBanner'
import HireConsultantBenifits from './hireconsultantcompo/HireConsultantBenifits'
import HireConsultantBanner2 from './hireconsultantcompo/HireConsultantBanner2'
import HireConsultantReadyBanner from './hireconsultantcompo/HireConsultantReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import WhySaasverseCon from './hireconsultantcompo/WhySaasverseCon'

export default function HireConsultant() {
  return (
    <><HireConsultantBanner/>
    <HireConsultantBenifits/>
    <HireConsultantBanner2/>
    <WhySaasverseCon/>
    <CertificateSlider/>
    <Trustedby2/>
    <HireConsultantReadyBanner/>
    <Faq/>
      
    </>
  )
}
