// TrainingService.js
import React from 'react';
import './css/TrainingService.css';

export default function TrainingService() {
  return (
    <section id="training-service" className="training-service">
      <div className="training-service-intro-wrap">
        <div className="training-service-title-bordered">
          <h2 className="training-service-title-with-lines">Salesforce Training Services</h2>
        </div>
      </div>

      <div className="training-service-flip-cards-container">
        <div className="training-service-flip-cards-wrap">
          <div className="training-service-flip-card-outer">
            <div className="training-service-flip-card">
              <div className="training-service-flip-card-body">
                <div className="training-service-flip-card-front">
                  <div className="training-service-front-inner">
                    <img className="lozad" src="/assets/images/training/trainingicon2.png" alt="icon-end-user-training" />
                    <h3>Salesforce</h3>
                    <h2>End User Training</h2>
                  </div>
                </div>
                <div className="training-service-flip-card-back">
                  <div className="training-service-back-inner">
                    <p>In our experience, investing in effective end user training is one of the most important factors for any successful Salesforce implementation and a major step towards achieving high adoption rates.<br />
                      We’ll tailor your Salesforce training to your precise business requirements, system configuration and the specific needs of your sales, marketing and customer service teams.<br /><br />
                      We confirm what your objectives are and what you want to be able to accomplish by the end of your training and create a bespoke training plan to match.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="training-service-flip-card-outer">
            <div className="training-service-flip-card">
              <div className="training-service-flip-card-body">
                <div className="training-service-flip-card-front">
                  <div className="training-service-front-inner">
                    <img className="lozad" src="/assets/images/training/trainingicon.png" alt="CRM Consultation Logo" />
                    <h3>Salesforce</h3>
                    <h2>Corporate Training</h2>
                  </div>
                </div>
                <div className="training-service-flip-card-back">
                  <div className="training-service-back-inner">
                    <p>A committed Management team is key in the implementation of a successful CRM system. This training provides your managers tools for them to understand the system and implementation.<br />
                      We can provide your managers with best practice advice and training on the Salesforce tools and key performance indicators that will enable them to be more effective in their role.<br /><br />
                      Training on advanced Salesforce reports and custom dashboards will help them unlock valuable business insight to better manage their teams, drive business performance and improve results.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
