import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseField = () => {
  return (
    <div className="info-container">
    <div className="info-image">
     <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
   </div>
   <div className="info-text">
     <h2>FSL Integration: The Key to Success
     </h2>
     <p>By integrating FSL with your Salesforce platform, you gain a powerful tool to transform your field service operations. Experience the benefits of:
     </p>
     <p>
     <b style={{color:'#006b95'}}>-</b> Improved Efficiency


     </p>
     <p>
     <b style={{color:'#006b95'}}>-</b> Enhanced Visibility
     </p>
     <p>
     <b style={{color:'#006b95'}}>-</b> Streamlined Communication
      </p>
     <p>
      <b style={{color:'#006b95'}}>-</b> Increased Productivity</p>
   
     <p>
      <b style={{color:'#006b95'}}>-</b> Reduced Costs</p>
   
     <p>
      <b style={{color:'#006b95'}}>-</b> Unmatched Customer Satisfaction</p>
   
   </div>
 
 </div>
  );
};

export default WhySaasverseField;
