import React from 'react';
import './../css/Trustedby2.css';

const Trustedby2 = () => {
  
  const partnerLogos = [
    '/assets/images/ourclients/Databricks.png',
    '/assets/images/ourclients/Doordash.png',
    '/assets/images/ourclients/Elkay.png',
    '/assets/images/ourclients/Five9.png',
    '/assets/images/ourclients/IngersolRand.png',
    '/assets/images/ourclients/Lafarge.png',
    '/assets/images/ourclients/Lkpackaging.png',
    '/assets/images/ourclients/Marlab.png',
    '/assets/images/ourclients/SYSCO.png',
    '/assets/images/ourclients/Telstra.png',
    '/assets/images/ourclients/XPO.png',
    '/assets/images/ourclients/origin.png',
    '/assets/images/ourclients/SpringEducationGroup.png',
    
  ];

  return (
    <section className="trusted-partner">
      <h2>Trusted by global business and <br/>respected partners</h2>
      <div className="trusted-partner__grid">
        {partnerLogos.map((logo, index) => (
            <div key={index} className="trusted-partner__item">
              
            <img src={logo} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Trustedby2;
