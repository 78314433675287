import React from 'react'

export default function TrainingFormat() {
  return (
    <div id="services">
    <div className="cnt">
      <div className="row">
  <h2 style={{fontSize:'40px',textAlign:'center' ,paddingBottom:'20px'}}>Our Training Formats</h2>
        <div className="reasons__wrapper">
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">On-Site Training</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Personalized Sessions</b>
    <p> Deliver tailored training programs at your location for a hands-on learning experience.</p>
   
<b style={{color:'#006b95'}}>Team Building</b>
<p>Foster collaboration and team building with group training sessions.</p>

</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title" >Online Training
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Flexible Scheduling</b>
                <p>Access training sessions from anywhere, at any time, with our online learning platform.
                </p>
<b style={{color:'#006b95'}}>Interactive Learning</b> 
<p> Engage with instructors through live webinars, interactive modules, and virtual labs.</p>

</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Hybrid Training</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Blended Approach</b>
                 <p>Combine the benefits of on-site and online training for a comprehensive learning experience.</p>
<b style={{color:'#006b95'}}>Adaptable Solutions</b> 
<p> Customize the training format to suit your team's needs and availability.</p>

</p>
            </div>
          </div>
        
         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
