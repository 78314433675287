import React from 'react';
import './FooterContent.css'; // Import the CSS file for styling

const FooterContent = () => {
  return (
    <div className="contact-header" >
      <h2 className="available-text">Available</h2>
      <div className="mobile-number">Call On <a href='tel:+16179814454' style={{textDecoration:'none',color:'white'}}>+1 (617) 981-4454</a></div>
    </div>
  );
};

export default FooterContent;
