import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function DevelopmentPart() {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', ''); // Get section ID from URL hash
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' }); // Scroll to the section
      }
    }
  }, [location]);
  return (
    <div id="servicess" >
    <div className="cnts" id='devpart'>
      <div className="rows">
        <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Salesforce CPQ Training</h2>

        <div className="reasons__wrappers">

        

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 1 - Unleashing the Potential of Salesforce Development</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Welcome to a realm where innovation meets business transformation - Salesforce Development. In this chapter, we embark on a journey to explore the limitless possibilities of designing and building custom applications and integrations on the Salesforce platform. With the power of Apex, Visualforce, and Lightning Web Components, we dive into automating business processes, elevating customer experiences, and driving unprecedented organizational growth. Join us and unlock the secrets to success with our free Salesforce tutorials brought to you by Saasverse Technologies.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 2 - Apex Basics: Unleashing the Force Within</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Prepare to harness the power of Apex, the mighty programming language that fuels custom application development and business logic on the Salesforce platform. Similar to Java, Apex empowers you to write custom triggers, controllers, and server-side code that seamlessly automates and extends the functionality of Salesforce. Unleash the force within and embark on a journey of unparalleled development possibilities.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 3 - Apex Data Types: Forging the Foundations of Innovation</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Salesforce Apex, a formidable object-oriented programming (OOP) language, acts as the catalyst for developing custom applications and integrations. Dive into the realm of OOP concepts that Salesforce Apex offers, empowering you to construct intricate, scalable, and maintainable applications. Equip yourself with the knowledge to forge the foundations of innovation and unleash the true potential of your Salesforce development endeavors.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 4 - Object Oriented Programming: Sculpting Solutions with Elegance</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Immerse yourself in the artistry of object-oriented programming (OOP) within the Salesforce ecosystem. As Salesforce Apex embraces the power of OOP, you gain the ability to shape sophisticated, scalable, and easily maintainable applications. Step into the realm where code becomes art, and discover the elegance of sculpting solutions that stand the test of time.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 5 - Logical and Conditional Statements: Mastering the Symphony of Decision-Making</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Welcome to the symphony of logical and conditional statements in Apex, where you become the conductor of program execution. Unleash the power of if-else statements, switch statements, logical operators, and ternary operators. With this orchestration of decision-making, developers can gracefully navigate through conditions and variables, harmonizing the flow of code and orchestrating the desired outcomes.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 6 - SOQL: Unveiling the Secrets of Data Retrieval</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Embark on a quest to master the art of data retrieval with SOQL, the query language designed for unearthing treasures hidden within Salesforce databases. Journey through the intricacies of querying records from a single object, discovering the nuances and potential of this powerful language. Join us as we unravel the secrets of SOQL and equip you with the skills to fetch data with finesse.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 7 - SOSL: Exploring the Depths of Salesforce Data Search</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Venture into the depths of Salesforce data search with SOSL, the query language that transcends boundaries and spans multiple objects. Dive into the world of full-text search capabilities and experience the thrill of unearthing ranked lists of results. Join us as we explore the expansive possibilities of SOSL and empower you to navigate through Salesforce data like never before.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 8 - Data Manipulation Language (DML) Statements: Shaping and Transforming Data</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Experience the art of shaping and transforming data within Salesforce with the Data Manipulation Language (DML) statements. Seamlessly manipulate records in the database through operations like insert, update, upsert, delete, and undelete. Explore the stand-alone power of DML, enabling you to retrieve and manipulate data without the need for triggers or complex programmatic logic.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 9 - Apex Triggers: Automating Salesforce Processes with Finesse</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Unleash the power of automation in Salesforce with Apex Triggers. These intelligent pieces of code execute before or after events occur on a record, enabling you to automate processes with finesse. Dive into the world of validation, related record updates, and data manipulation as triggers access and modify data of the triggering record. Whether you're synchronously or asynchronously executing triggers for any object, get ready to automate Salesforce processes like never before.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 10 - Exception Handling: Mastering the Art of Error Management</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Navigate the complex terrain of error management with Salesforce's Exception Handling. Discover how to catch errors, prevent code crashes, and gracefully handle exceptions. Explore a range of built-in exceptions like DMLException and System.Exception, as well as the ability to create custom exceptions. With the power of try-catch, finally, and throw statements, you'll become a master of handling exceptions and ensuring the smooth execution of your code.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 11 - Apex Testing: Ensuring Code Excellence</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Ensure the excellence of your Salesforce code with Apex Testing. Dive into the world of separate testing environments and unleash the power of unit tests. From testing classes to triggers and beyond, learn how to validate your code's functionality and reliability before deployment. Discover the insights provided by test results, all within the Salesforce UI, and elevate your development process to new heights.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 12 - Governor Limits & Asynchronous Apex: Harnessing Efficiency and Scalability</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Enter the realm of efficiency and scalability with Salesforce's Governor Limits and Asynchronous Apex. Gain an understanding of the limits enforced by Salesforce to ensure optimal resource utilization. Explore the boundaries of operations, queries, record retrieval, CPU time, and memory, all while adapting to the specific limits based on your Salesforce edition. With a firm grasp on Governor Limits, you'll learn to develop code that maximizes performance and avoids exceptions.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 13 - APIs: Bridging Salesforce with the World</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Discover the power of Salesforce APIs and unlock the ability to interact with the platform programmatically. Seamlessly create custom integrations and applications with the REST, SOAP, and Bulk APIs. Explore the breadth of operations, from data retrieval, creation, update, and deletion to performing other platform operations. With APIs as your bridge, you'll open up a world of possibilities for extending Salesforce's capabilities and integrating it with external systems.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 14 - Lightning Component Framework (Aura): Enchanting User Interfaces</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Step into the realm of enchanting user interfaces with the Lightning Component Framework, also known as Aura. Experience the first lightning framework that empowers you to create stunning UIs in Salesforce. Dive into the complexities and intricacies of this powerful framework, leveraging its capabilities to enhance the user experience and create captivating interfaces that leave a lasting impression.</p>



                </p>


              </p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 15 - Visualforce Pages: Unleashing Creativity in User Interface Design</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Unleash your creativity and design custom user interfaces using Salesforce Visualforce Pages. Combining the power of HTML, Apex, and Visualforce tags, you'll unlock the potential to create tailor-made forms, reports, and dashboards that seamlessly integrate with Salesforce data and metadata. Step into the world of Visualforce Pages and elevate the user experience to new heights.</p>



                </p>


              </p>
            </div>
          </div>
          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
              <h3 className="reasons__item_titles">Chapter 16 - Deployment: Managing Changes with Precision</h3>
              <p className="reasons__item_texts">
                {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                <p>
                    <p>Navigate the complex landscape of Salesforce deployment with precision and confidence. Discover the indispensable tools such as ANT Migration and Change Sets that facilitate the seamless movement of metadata changes between Salesforce environments. From selecting metadata components to tracking deployments and even rolling back changes if needed, these tools are essential for managing changes between development, testing, and production environments. Master the art of deployment and ensure smooth transitions across your Salesforce ecosystem.</p>



                </p>


              </p>
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>
  )
}
