// src/Banner.js
import React from 'react';
import './TrainingBanner.css';

const TrainingBanner = () => {
  return (
    <div className="banner1t" style={{justifyContent:'center'}}>
      <div className="banner-text1t" style={{width:'80%'}}>
      <h1 style={{textAlign:'center' ,fontSize:'40px' ,justifyContent:'center'}}>Salesforce Training & Certifications</h1>
    
        <p style={{fontSize:'20px',textAlign:'center'}}>At Saasverse Pvt Ltd, we are committed to helping individuals and organizations unlock the full potential of Salesforce.
        </p>
      </div>
    </div>
  );
};

export default TrainingBanner;
