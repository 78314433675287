import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseHealth = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Choose Saasverse Pvt Ltd for Health Cloud Solutions?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Healthcare Experts & Salesforce Certified</b> <br/>Our team combines deep healthcare industry knowledge with proven expertise in Salesforce Health Cloud. We understand your unique challenges and can configure the platform to meet your specific needs.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Tailored Solutions, Not a One-Size-Fits-All Approach</b><br/> We take the time to understand your workflows and patient population. We then design and implement customized Health Cloud solutions that optimize your operations and deliver superior care.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Focus on Security & HIPAA Compliance</b><br/>We prioritize robust security measures to ensure patient data privacy and HIPAA compliance.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseHealth;
