import React from 'react'

export default function VisitUs() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading" style={{textAlign:'center' }}> Visit Us</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content" >
          <p style={{width:'90vw' }}>We welcome you to visit our office to discuss how Saasverse can help your business thrive. Please schedule an appointment in advance by contacting us through any of the above methods.</p>
        </div>
        {/* <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton">Book a Call</div>
        </div> */}
      </div>
    </div>
  </div>
  )
}
