import React from 'react'

export default function WhyAWS() {
  return (
    <div className="info-container">
   
    <div className="info-text">
      <h2>Why Choose AWS Development Services?
      </h2>
      <p>Amazon Web Services is the leading cloud platform, offering a vast array of services and tools that enable businesses to innovate faster, reduce costs, and scale effortlessly. Our AWS Development Services empower you to:
      </p>
      <p>
       <b style={{color:'#006b95'}}>Accelerate Innovation</b> <br/>Develop and deploy applications faster with AWS's comprehensive suite of services.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Enhance Security</b><br/>Protect your data with AWS's robust security measures and compliance certifications.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Optimize Costs</b><br/>Reduce operational costs with AWS's pay-as-you-go pricing and cost management tools.</p>
      <p>
       <b style={{color:'#006b95'}}>Scale Effortlessly:</b><br/>Easily scale your applications and infrastructure to meet changing business demands.</p>
    
    </div>
    <div className="info-image">
      <img src="/assets/images/websiteimages/whyaws.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
