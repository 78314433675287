import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseRetail = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Saasverse Expertise in Retail
        </h2>
        <p>At Saasverse, we have a team of Salesforce experts with a deep understanding of the retail industry's unique challenges. We can help you:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Customize Retail Cloud to meet your specific needs, whether you're a large department store, a specialty boutique, or an online retailer.


        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate Retail Cloud with your existing systems (eCommerce platform, POS) for a unified view of your data and operations.
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop custom solutions to address unique challenges, such as managing social media marketing for in-store promotions or optimizing click-and-collect services.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure your staff can leverage Retail Cloud effectively.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseRetail;
