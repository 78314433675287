import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseMobile = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Choose Saasverse Pvt Ltd for Mobile Publisher Expertise?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Mobile Publisher Certified Professionals</b> <br/>Our team comprises experts who can guide you through every step of the Mobile Publisher process, ensuring a smooth and successful app creation experience.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Data-Driven Optimization</b><br/>We leverage analytics to understand how users interact with your app. These insights help us continuously refine your app and improve user engagement.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Ongoing Support & Maintenance</b><br/>We're here for you beyond the initial launch. Our ongoing support ensures your app stays up-to-date, secure, and delivers a consistently positive user experience.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseMobile;
