import React from 'react';
import './Ready.css'
const Ready = () => {
  return (
   

<div className="cnt_banner">
<div className="banner__container">
  <h2 className="banner__heading">Ready to Transform Your Business?</h2>
  <div className="banner__text-wrapper">
    <div className="banner__text-content">
      <p>Partner with Saasverse Pvt Ltd for top-notch Salesforce Consulting Services and take your business to new heights. Contact us today to learn how we can help you leverage the power of Salesforce to achieve your business goals.</p>
    </div>
    <div className="banner__button-wrapper">
      <div className="banner__button button button-outline contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
    </div>
  </div>
</div>
</div>
  );
}

export default Ready;
