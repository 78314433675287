import React from 'react'

export default function MarketingCloudBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Saasverse Pvt Ltd unlocks the true potential of Marketing Cloud, empowering you to<br/></h2>

        <div className="reasons__wrapper">
{/* <p>Struggling to cut through the digital noise and engage your audience? Tired of campaigns that fizzle out? You're not alone. But it doesn't have to be this way.</p> */}
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Craft laser-targeted campaigns</h3>
              <p className="reasons__item_text">
                 {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                 <p>Design personalized journeys that resonate with each customer, across every touchpoint (email, social, mobile, etc.).
                 </p>


{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Turn prospects into loyal fans</h3>
              <p className="reasons__item_text">  
    {/* <b style={{color:'#006b95'}}>Customizable Reporting</b> */}
    <p>Go beyond basic marketing with data-driven insights that fuel deeper customer engagement and loyalty.</p>
 

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">See real results, fast</h3>
              <p className="reasons__item_text">
              
                 <p>Track key metrics and optimize campaigns in real-time to maximize your return on investment (ROI).

                 </p>


{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


        
      
         

         
   
        </div>
      </div>
    </div>
  </div>
  )
}
