import React from 'react'


import Faq from '../../Faq/Faq'



import TechnologyBanner from './technologycompo/TechnologyBanner'
import TechnologyBenifits from './technologycompo/TechnologyBenifits'
import TechnologyBanner2 from './technologycompo/TechnologyBanner2'
import TechnologyReadyBanner from './technologycompo/TechnologyReadyBanner'
import WhySaasverseTech from './technologycompo/WhySaasverseTech'

export default function Technology() {
  return (
    <><TechnologyBanner/>
    <TechnologyBenifits/>
    <TechnologyBanner2/>
    <WhySaasverseTech/>
    <TechnologyReadyBanner/>
    <Faq/>
      
    </>
  )
}
