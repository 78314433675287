import React from 'react'

export default function ServiceImplementation() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Implementation <span style={{color:'#006b95' }}>Highlights</span></h2>
      {/* <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4> */}
    </div>
    <div className="columnt">
      <p><b>Omnichannel Support</b><br/>Salesforce Service Cloud enabled GTS to manage customer inquiries seamlessly across multiple channels, including email, phone, social media, and chat.</p>
      <br/> <p><b>Case Management</b> <br/>Advanced case management features allowed GTS to track and resolve customer issues more efficiently, improving response times and service quality.</p>
    </div>
    <div className="columnt">
      <p><b>Knowledge Base</b><br/>A centralized knowledge base empowered customer service agents with access to up-to-date information and resources, enabling faster resolution of customer queries.</p>
      {/* <br/> <p><b>Proven Success</b><br/> Our track record of successful security reviews and satisfied clients demonstrates our capability and reliability in ensuring Salesforce security.</p> */}
    </div>
  </div>
  )
}
