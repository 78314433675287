import React from 'react'

export default function FieldNext() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Ready to take your field service operations to the next level?</h2>
    
    </div>
    <div className="columnt">
    <p><b>Reduce Costs</b><br/>Optimize scheduling, minimize wasted resources, and improve first-time fix rates to drive down operational costs.</p>
      <br/> <p><b>Boost Productivity</b> <br/>FSL Integration empowers your technicians to get more done in less time, increasing overall productivity.
      </p>
    </div>
    <div className="columnt">
     
      <p><b>Streamline Workflows</b><br/> Automate repetitive tasks and free up valuable time for your team to focus on what matters most – delivering exceptional service.
      </p>
    </div>
  </div>
  )
}
