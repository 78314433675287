import React from 'react'

export default function ReadyAWS() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Transform Your Business with AWS</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Leverage the power of the cloud with Saasverse Pvt Ltd's AWS Development Services. Contact us today to discuss your AWS needs and start your journey towards cloud excellence.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
