import React from 'react'
import { Link } from 'react-router-dom'

export default function TravelReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to Craft Unforgettable Journeys?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Partner with Saasverse and unlock the transformative power of Salesforce for Travel & Hospitality. Let's navigate the evolving travel landscape together, create personalized guest journeys, and turn your business into a destination for lasting memories. Contact us today and see how Saasverse can be your guide to travel & hospitality success!</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
