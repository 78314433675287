import React, { useState, useEffect } from 'react';
import './Nav.css'; // Import your CSS file for styling
import MobileNavbar from './MobileNavbar'; // Import MobileNavbar component
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome for icons
import { Link, NavLink } from 'react-router-dom';


const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleItemClick = () => {
    setDropdownVisible(false);
    window.scrollTo(0, 0);
  };
 

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header__wrapper">
        <div className="header__logo" >
      <Link to='/'  onClick={scrollToTop}> <picture>
            <source media="(min-width: 768px)" srcSet={isScrolled ? "/assets/images/lastlogo.png" : "/assets/images/saasverselast.png"} />
            <img src={isScrolled ? "/assets/images/lastlogo.png" : "/assets/images/saasverselast.png"} alt="saasverse logo" />
          </picture>
          </Link> 
        </div>
        {!isMobile && (
          <nav className="desktop-nav">
            {/* Your desktop menu content here */}
            <section className="content" id="scrlTop">
    
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>

  <div className="header__wrapper">
    <Link className="header__logo" to="/"  onClick={scrollToTop} >
    <picture>
            <source media="(min-width: 768px)" srcSet={isScrolled ? "/assets/images/lastlogo.png" : "/assets/images/saasverselast.png"} />
            <img  src={isScrolled ? "/assets/images/lastlogo.png" : "/assets/images/saasverselast.png"} alt="saasverse logo" />
          </picture>

      </Link>
    <div className="nav">
      <div className="nav__wrapper">



      <div className="nav__dropdown" data-id="dropNav8"  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title" >Services</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav1">
            <div className="navContent mod-row5 mod-column-txt">
              <Link className="navContent-item navLink" to="/services-aws"  onClick={handleItemClick} >Amazon Web Services (AWS)</Link>
              <Link className="navContent-item navLink" to="/services-cloud-computing"  onClick={handleItemClick}>Cloud Computing Services</Link>
              <Link className="navContent-item navLink" to="/salesforce-sitecore"  onClick={handleItemClick}>Sitecore</Link>
              <Link className="navContent-item navLink" to="/salesforce-kentico"  onClick={handleItemClick}>Kentico</Link>
              {/* <a className="navContent-item navLink" href="/salesforce-custom">Odoo</a> */}
             
              </div>
          </div>
            )}
        </div>



        <div className="nav__dropdown" data-id="dropNav1"  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title" >Salesforce</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav1">
            <div className="navContent mod-row4 mod-column-txt">
              <Link className="navContent-item navLink" to="/salesforce-consulting-company"  onClick={handleItemClick}>Salesforce Consulting Service<span>Support and development</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-isv-development-company" onClick={handleItemClick}>Salesforce ISV Development<span>Launch own Salesforce app</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-custom-development-services" onClick={handleItemClick}>Salesforce Custom Development<span>Implement any solution for your Salesforce</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-integration-services" onClick={handleItemClick}>Salesforce Integration Services<span>Integrate any solution with your Salesforce</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-managed-services" onClick={handleItemClick}>Salesforce Managed Service<span>Dedicated teams for customers</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-security-review" onClick={handleItemClick}>Salesforce Security Review<span>Full check-up of Salesforce environment</span></Link>
              <Link className="navContent-item navLink" to="/salesforce-appexchange-development-company" onClick={handleItemClick}>Salesforce AppExchange App Development<span>Implement any solution for your Salesforce</span></Link>
              </div>
          </div>
           )}
        </div>


        <div className="nav__dropdown" data-id="dropNav2"   onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title">Expertise</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav2" onClick={closeMenu}>
            <div className="navContent mod-row7 mod-column-txt" >
              <Link className="navContent-item navLink" to="/expertise/salesforce-sales-cloud/"onClick={handleItemClick} ><span >Sales Cloud</span><span >Effectively monitor sales process</span></Link>
              <Link className="navContent-item navLink" to="/expertise/salesforce-service-cloud/"onClick={handleItemClick}><span>Service Cloud</span><span>Proficiently manage service operations</span></Link>
              <Link className="navContent-item navLink" to="/expertise/salesforce-marketing-cloud/"onClick={handleItemClick}><span>Marketing Cloud</span><span>Boost marketing initiatives and <br/>customer engagement</span></Link>
              <Link className="navContent-item navLink" to="/expertise/mulesoft-development/"onClick={handleItemClick}><span>Mulesoft Development</span><span>Connect and automate your CRM</span></Link>
              <Link className="navContent-item navLink" to="/expertise/health-cloud-services/"onClick={handleItemClick}><span>Health Cloud</span><span>Tailored solutions for superior care</span></Link>
              <Link className="navContent-item navLink" to="/expertise/experience-cloud/"onClick={handleItemClick}><span>Experience Cloud</span><span>Redefining personalized engagement</span></Link>
              <Link className="navContent-item navLink" to="/expertise/tableau-crm/"onClick={handleItemClick}><span>Tableau CRM</span><span>Unleashing dynamic data insights</span></Link>
              <Link className="navContent-item navLink" to="/expertise/b2b-b2c/"onClick={handleItemClick}><span>Salesforce B2B & B2C Services</span><span>Transform Your Business</span></Link>
              <Link className="navContent-item navLink" to="/expertise/salesforce-ai-company/"onClick={handleItemClick}><span>AI Company</span><span>Enhance business with AI insights</span></Link>
              <Link className="navContent-item navLink" to="/expertise/mobile-publisher-services/"onClick={handleItemClick}><span>Mobile Publisher</span><span>Empower your mobile engagement</span></Link>
              <Link className="navContent-item navLink" to="/expertise/salesforce-cpq/"onClick={handleItemClick}><span>CPQ Implementation</span><span>Optimize sales workflow solutions</span></Link>
              <Link className="navContent-item navLink" to="/expertise/field-service-lightning/"onClick={handleItemClick}><span>Field Service Lightning Integration</span><span>Improve field service operations</span></Link>
              <Link className="navContent-item navLink" to="/expertise/financial-cloud-implementation-services/"><span>Financial Cloud Implementation</span><span>Optimize financial customer relationships</span></Link>
              <Link className="navContent-item navLink" to="/expertise/nonprofit-cloud/"onClick={handleItemClick}><span>Nonprofit Consultant</span><span>Amplify Impact for Nonprofits</span></Link>
              {/* <a className="navContent-item navLink" href="/expertise/nonprofit-cloud/"><span>Salesforce Data Cloudt</span><span>Amplify Impact for Nonprofits</span></a> */}
              {/* <a className="navContent-item navLink" href="/expertise/nonprofit-cloud/"><span>Salesforce 360</span><span>Amplify Impact for Nonprofits</span></a> */}
              {/* <a className="navContent-item navLink" href="/expertise/nonprofit-cloud/"><span>Salesforce Einstein</span><span>Amplify Impact for Nonprofits</span></a> */}
              </div>
          </div>
            )}
        </div>



        <div className="nav__dropdown" data-id="dropNav4"  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title">Industries</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav4">
            <div className="navContent mod-row6">
              <Link className="navContent-item navLink" to="/automotive-industry/" onClick={handleItemClick}>Automotive</Link>
            <Link className="navContent-item navLink" to="/healthcare-industry/" onClick={handleItemClick}>Healthcare</Link>
            <Link className="navContent-item navLink" to="/communications-industry/" onClick={handleItemClick}>Communications</Link>
            <Link className="navContent-item navLink" to="/media-industry/" onClick={handleItemClick}>Media</Link>
            <Link className="navContent-item navLink" to="/consumer-goods-industry/" onClick={handleItemClick}>Consumer Goods</Link>
            <Link className="navContent-item navLink" to="/retail-industry/" onClick={handleItemClick}>Retail</Link>
            <Link className="navContent-item navLink" to="/education-industry/" onClick={handleItemClick}>Education</Link>
            <Link className="navContent-item navLink" to="/technology-industry/" onClick={handleItemClick}>Technology</Link>
            <Link className="navContent-item navLink" to="/energy-industry/" onClick={handleItemClick}>Energy</Link>
            <Link className="navContent-item navLink" to="/travels-hospitality-industry/" onClick={handleItemClick}>Travels & Hospitality</Link>
            <Link className="navContent-item navLink" to="/financial-industry/" onClick={handleItemClick}>Financial</Link>
            </div>
          </div>
           )}
        </div>

      
        <div className="nav__dropdown" data-id="dropNav3" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title">Hire</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav3">
            <div className="navContent mod-column">
              <Link className="navContent-item navLink" to="/hire-salesforce-consultants/" onClick={handleItemClick}>Hire Salesforce Consultants</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-project-managers/" onClick={handleItemClick}>Hire Salesforce Project Managers</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-admin/" onClick={handleItemClick}>Hire Salesforce Administrators</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-product-managers/" onClick={handleItemClick}>Hire Salesforce Product Managers</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-developers/" onClick={handleItemClick}>Hire Salesforce Developers</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-architect/" onClick={handleItemClick}>Hire Salesforce Architects</Link>
              <Link className="navContent-item navLink" to="/hire-salesforce-business-analyst/" onClick={handleItemClick}>Hire Salesforce Business Analysts</Link>
              <Link className="navContent-item navLink mb20" to="/hire-salesforce-expert/" onClick={handleItemClick}>Hire Salesforce Quality Analyst</Link>
              </div>
          </div>
           )}
        </div>


        <div className="nav__dropdown" data-id="dropNav5"  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title"> Training</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav4">
            <div className="navContent mod-row6">
              <Link className="navContent-item navLink" to="/training/" onClick={handleItemClick}>Salesforce Training & Certifications</Link>
              <Link className="navContent-item navLink" to="/apply/salesforce-admin/" onClick={handleItemClick}>Salesforce Admin Training</Link>
              <Link className="navContent-item navLink" to="/apply/salesforce-developer/" onClick={handleItemClick}>Salesforce Developer Training</Link>
              <Link className="navContent-item navLink" to="/apply/salesforce-cpq/" onClick={handleItemClick}>Salesforce CPQ Training</Link>
              <Link className="navContent-item navLink" to="/apply/marketing-salesforce/" onClick={handleItemClick}>Salesforce Marketing Training</Link>
          
            </div>
          </div>
          )}
        </div>




        <div className="nav__dropdown" data-id="dropNav6"  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title"> Work</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav4">
            <div className="navContent mod-row6">
              <Link className="navContent-item navLink" to="/case-studies/salesforce-healthcare-cloud/" onClick={handleItemClick}>Salesforce Healthcare Cloud</Link>
              <Link className="navContent-item navLink" to="/case-studies/salesforce-sales-cloud/" onClick={handleItemClick}>Salesforce Sales Cloud</Link>
              <Link className="navContent-item navLink" to="/case-studies/salesforce-service-cloud/" onClick={handleItemClick}>Salesforce Service Cloud</Link>
            {/* <a className="navContent-item navLink" href="/apply/salesforce-cpq/">Salesforce CPQ Training</a> */}
            {/* <a className="navContent-item navLink" href="/apply/marketing-salesforce/">Salesforce Marketing Training</a> */}
          
            </div>
          </div>
           )}
        </div>



        {/* <div className="nav__dropdown"><a className="navLink nav__dropdown_title" href="/case-study" style={{textDecoration:'none'}}>Case Studies</a></div> */}
        {/* <div className="nav__dropdown"><Link className="navLink nav__dropdown_title" to="/career" style={{textDecoration:'none'}}  onClick={scrollToTop}>Careers</Link></div> */}
        {/* <div className="nav__dropdown"><Link className="navLink nav__dropdown_title" to="/about-us" style={{textDecoration:'none'}}  onClick={scrollToTop}>About Us</Link></div> */}
        {/* <div className="nav__dropdown"><a className="navLink nav__dropdown_title" href="/contact-us" style={{textDecoration:'none'}}>Contact Us</a></div> */}
        {/* <div className="nav__dropdown" data-id="dropNav5">
          <div className="nav__dropdown_title">Case Studies</div>
          <div className="navContent-wrap nav__dropdown_content" id="dropNav5">
            <div className="navContent mod-row6"><a className="navContent-item navLink mb20" href="/case-studies/">All Cases</a><a className="navContent-item navLink" href="/case-studies/elements-cloud/">Elements.cloud</a><a className="navContent-item navLink" href="/case-studies/atamis/">Atamis</a><a className="navContent-item navLink" href="/case-studies/data-privacy-manager/">Data Privacy Manager</a><a className="navContent-item navLink" href="/case-studies/limio/">Limio</a><a className="navContent-item navLink" href="/case-studies/id-pal/">ID-Pal</a>
              <div className="navContent-item navLink"></div><a className="navContent-item navLink" href="/case-studies/purlos/">Purlos</a><a className="navContent-item navLink" href="/case-studies/surfly/">Surfly</a><a className="navContent-item navLink" href="/case-studies/ascent/">Ascent Solutions</a><a className="navContent-item navLink" href="/case-studies/opfocus/">OpFocus</a><a className="navContent-item navLink" href="/case-studies/saas-success/">SaaS Success</a>
            </div>
          </div>
        </div> */}
        <div className="nav__dropdown" data-id="dropNav6" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
          <div className="nav__dropdown_title">Company</div>
          {isDropdownVisible && (
          <div className="navContent-wrap nav__dropdown_content" id="dropNav6">
            <div className="navContent mod-column">
              <Link className="navContent-item navLink" to="/about-us" onClick={handleItemClick}>About Us</Link>
              <Link className="navContent-item navLink" to="/career/" onClick={handleItemClick}>Careers</Link>
              <Link className="navContent-item navLink" to="/contact-us/" onClick={handleItemClick}>Contact Us</Link></div>
          </div>
          )}
        </div>
       
        {/* <div className="nav__dropdown"><a className="navLink nav__dropdown_title" href="/contact-us" style={{color:'white',textDecoration:'none'}}>Contact Us</a></div> */}

        {/* <div className="nav__dropdown"><button className="navLink nav__dropdown_title" href="" style={{color:'white',textDecoration:'none',padding:"0px 30px"}}>Get In Contact</button></div> */}
      </div>
    </div>
      <button  className='glow-button' style={{padding:'13px 10px',borderRadius:'10px',marginTop:'10px',marginRight:'0'}}><Link  to='/contact-us' style={{textDecoration:'none',color:'white',fontSize:'16px'}} onClick={scrollToTop}>Get a Free Consultation</Link></button>
    {/* <div className="hamburger menuSubTrigger" data-id="menu">Menu</div> */}
    {/* <div className="button button-solid header__button contactModalButton">Contact us</div> */}

  </div>
</header>


</section>
          </nav>
        )}
        {isMobile && (
          <div className="hamburger" onClick={toggleMenu}>
            <i className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
        )}
      </div>
      {isMobile && (
        <MobileNavbar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      )}
    </header>
  );
}

export default Nav;
