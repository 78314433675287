import React from 'react'


import Faq from '../../Faq/Faq'





import FinancialBenifits from './financialcompo/FinancialBenifits'
import FinancialBanner2 from './financialcompo/FinancialBanner2'
import FinancialReadyBanner from './financialcompo/FinancialReadyBanner'
import FinancialBannerr from './financialcompo/FinancialBannerr'
import WhySaasverseFin from './financialcompo/WhySaasverseFin'

export default function FinancialInd() {
  return (
    <>
    <FinancialBannerr/>
    <FinancialBenifits/>
    <FinancialBanner2/>
    <WhySaasverseFin/>
    <FinancialReadyBanner/>
    <Faq/>
      
    </>
  )
}
