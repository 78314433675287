import React from 'react'

export default function WhySitecoreS() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4>
    </div>
    <div className="columnt">
      <p><b>Certified Sitecore Experts</b><br/>Our team consists of certified Sitecore professionals with extensive experience in Sitecore development and implementation.</p>
      <br/> <p><b>Tailored Solutions</b> <br/>We provide customized Sitecore solutions that align with your specific business processes and goals.</p>
    </div>
    <div className="columnt">
      <p><b>Quality Assurance</b><br/>  We are committed to delivering high-quality services that meet your expectations and drive business success.</p>
      <br/> <p><b>Proven Track Record</b><br/>Our successful track record and numerous satisfied clients demonstrate our ability to deliver effective Sitecore solutions.</p>
    </div>
  </div>
  )
}
