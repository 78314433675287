import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/TrustedPartner.css'; // Import your CSS for styling

const TrustedPartner = () => {
  // Define partner data (you can modify as per your requirements)
  const partnerData = [
    { id: 1, image: '/assets/images/ourclients/DataBricks.png', heading: 'DataBricks' },
    { id: 2, image: '/assets/images/ourclients/Doordash.png', heading: 'Doordash' },
    { id: 3, image: '/assets/images/ourclients/Elkay.png', heading: 'Elkay' },
    { id: 4, image: '/assets/images/ourclients/Five9.png', heading: 'Five9' },
    { id: 5, image: '/assets/images/ourclients/IngersolRand.png', heading: 'Ingersol' },
    { id: 6, image: '/assets/images/ourclients/Lafarge.png', heading: 'Lafarge' },
    { id: 7, image: '/assets/images/ourclients/Lkpackaging.png', heading: 'Lk' },
    { id: 8, image: '/assets/images/ourclients/Marlab.png', heading: 'Marlab' },
    { id: 9, image: '/assets/images/ourclients/SYSCO.png', heading: 'SYSCO' },
    { id: 10, image: '/assets/images/ourclients/Telstra.png', heading: 'Telstr' },
    { id: 11, image: '/assets/images/ourclients/XPO.png', heading: 'XPO' },
    // Add more partners as needed
  ];

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Slide transition speed
    slidesToShow: 6, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Autoplay slides
    autoplaySpeed: 3000, // Autoplay interval
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    prevArrow: null, // Remove previous arrow
    nextArrow: null, // Remove next arrow
  };

  return (
    <div className="trusted-partner-container">
      <h2 className="centered-heading">We’re trusted by</h2>
      <Slider {...settings}>
        {partnerData.map((partner) => (
          <div key={partner.id} className="partner-slide">
            <img src={partner.image} alt={`Partner ${partner.id}`} className="partner-image" />
            {/* <h3 className="partner-heading">{partner.heading}</h3> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TrustedPartner;
