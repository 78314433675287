import React from 'react'

export default function MobileBenifits() {
  return (
    <div id="servicess" >
      <div className="cnts">
        <div className="rows">
          <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Here's how Mobile Publisher unlocks mobile engagement for your business</h2>

          <div className="reasons__wrappers">
<p>Building a custom mobile app can be a game-changer for your business. But the traditional app development route – with its hefty costs, long timelines, and app store approval hurdles – can be a major turnoff.</p>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Effortless App Creation</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Leverage pre-built templates and intuitive drag-and-drop functionality to design a user-friendly mobile app that aligns perfectly with your brand.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Seamless Deployment</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Bypass the app store approval process altogether. Mobile Publisher allows you to distribute your app directly to your target audience, saving you time and frustration.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Enhanced Brand Control</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Maintain complete control over your app's look, feel, and functionality. Ensure your brand identity shines through, fostering trust and recognition with your mobile audience.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Real-Time Updates & Analytics</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Make changes and deploy updates to your app instantly, all without app store approvals. Track user engagement with built-in analytics to optimize your app for maximum impact.


                  </p>


                </p>
              </div>
            </div>













          </div>
        </div>
      </div>
    </div>
  )
}
