import React from 'react'

export default function MediaBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Why Salesforce for Media?<br/></h2>

        <div className="reasons__wrapper">
<p>Saasverse Pvt Ltd understands the ever-changing media landscape. In today's digital age, media companies face challenges in audience engagement, content monetization, and navigating a complex advertising ecosystem. Salesforce Media Cloud offers a powerful solution, and Saasverse is here to help you harness its potential to achieve success.
</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Unified Customer Experience</b>
                 <p>Deliver a seamless experience across all platforms, from content discovery to subscriptions and advertising partnerships.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Data-Driven Content Strategy</b>
    <p> Gain deeper audience insights to personalize content, optimize distribution channels, and maximize engagement.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Automated Order Management</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Streamlined Ad Sales & Management</b>
                 <p>Automate workflows, manage ad inventory effectively, and deliver targeted campaigns that resonate with audiences.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
             
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Subscription Management</b>
                <p>Simplify subscription processes, reduce churn, and offer flexible pricing models to cater to diverse audience preferences.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Real-Time Performance Tracking</b>
                 <p>Measure the effectiveness of your content and advertising campaigns across platforms, and make data-driven decisions for continuous improvement.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Enhanced Collaboration</b>
                 <p>Break down silos between teams and foster a collaborative environment for content creation, sales, and marketing.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
        
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
