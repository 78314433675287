import React from 'react'

export default function HireAdministratorBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2>Benefits of Hiring a Saasverse Salesforce Administrator<br/></h2>

        <div className="reasons__wrapper">
<p>Unleashing the power of Salesforce requires a skilled custodian – a Salesforce Administrator. At Saasverse Pvt Ltd, we connect you with the perfect Salesforce Admin to ensure your platform runs smoothly, and efficiently, and aligns with your evolving business needs.</p>
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Increased Efficiency</b>
                 <p>Streamlined workflows, automated tasks, and optimized configurations lead to a more efficient Salesforce operation.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Enhanced User Experience</b>
    <p>Customizations and user-friendly configurations create a positive user experience, promoting user adoption and maximizing platform value.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Improved Data Quality</b>
                 <p>Data management expertise ensures accurate, clean, and organized data, leading to better decision-making.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3> */}
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Reduced Costs</b>
                <p>Automation and streamlined processes can minimize administrative overhead and reduce reliance on external resources.</p>

{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Enhanced Security</b>
                 <p>Robust security protocols and user access controls protect your data and ensure regulatory compliance.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
