import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseBA = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Hire a Salesforce Business Analyst from Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Business Acumen & Technical Expertise</b> <br/>Our BAs possess a unique blend of business understanding and Salesforce knowledge. They translate your business processes and objectives into actionable Salesforce solutions.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Requirements Gathering Masters</b><br/>They excel at gathering and analyzing your business requirements, user needs, and pain points to define the ideal Salesforce solution.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Process Optimization Champions</b><br/>Our BAs identify areas for process improvement and leverage Salesforce functionalities to streamline workflows and boost efficiency.</p>
        <p>
         <b style={{color:'#006b95'}}>Data Storytelling Experts</b><br/>They transform complex data into clear and actionable insights, empowering data-driven decision making.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Change Management Champions</b><br/> Our BAs facilitate change management initiatives to ensure user adoption and maximize the value of your Salesforce implementation.</p>
      
        <p>
         <b style={{color:'#006b95'}}>Communication Bridge</b><br/>They act as a bridge between business stakeholders and technical teams, fostering clear communication and collaboration throughout the project lifecycle.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseBA;
