import React, { useRef } from 'react';
import CareerBanner from './Compo/CareerBanner'
import WhyWork from './Compo/WhyWork'
import JobOpenings from './Compo/JobOpenings'
import Syn from './Compo/Syn'
import HowToApply from './Compo/HowToApply'

export default function Career() {
  const jobOpeningsRef = useRef(null); // Create a ref for the JobOpenings component

  const handleScrollToJobOpenings = () => {
    jobOpeningsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {/* <CareerBanner/> */}
      <CareerBanner onScrollToJobOpenings={handleScrollToJobOpenings} />
      <WhyWork/>
      {/* <Syn/> */}
      <div ref={jobOpeningsRef}>
        <JobOpenings />
      </div>
      {/* <JobOpenings/> */}
      <HowToApply/>
    </>
  )
}
