import React from 'react'

export default function MuleSoftBenifits() {
  return (
    <div id="servicess" >
      <div className="cnts">
        <div className="rows">
          <h2 style={{ fontSize: '40px', textAlign: 'center', paddingBottom: '20px' }}>Saasverse Pvt Ltd's Mulesoft development expertise can be your secret weapon</h2>

          <div className="reasons__wrappers">

            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Effortless Data Exchange</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Say goodbye to tedious manual data entry and hello to streamlined processes. Mulesoft integrations automate data exchange between your CRM and other applications, ensuring real-time updates and eliminating errors. Imagine sales reps automatically triggering marketing campaigns based on new leads in the CRM, or customer service agents instantly accessing a customer's entire purchase history.


                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Unified Customer Experience</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p> Break down data silos and create a 360-degree view of your customers. With Mulesoft, all your teams – marketing, sales, and customer service – have access to the latest and most accurate customer information. This fosters a more personalized customer experience, leading to increased satisfaction and loyalty.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Boosted Productivity & Efficiency</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Free your employees from repetitive data entry tasks. Mulesoft automates data exchange, allowing your team to focus on what truly matters – building strong customer relationships and driving business growth.

                  </p>


                </p>
              </div>
            </div>
            <div className="reasons__items"><span className="reasons__item_figures"></span>
              <div className="reasons__item_contents">
                <h3 className="reasons__item_titles">Actionable Insights</h3>
                <p className="reasons__item_texts">
                  {/* <b style={{color:'#006b95'}}> Needs Assessment:</b> */}
                  <p>Mulesoft's powerful data integration capabilities go beyond simply connecting systems. It provides a holistic view of your customer data, enabling you to generate insightful reports and make data-driven decisions to optimize your marketing campaigns, improve sales efforts, and personalize customer service interactions.


                  </p>


                </p>
              </div>
            </div>













          </div>
        </div>
      </div>
    </div>
  )
}
