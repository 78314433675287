import React from 'react'

export default function SitecorePower() {
  return (
    <div className="client-info-containerb">
    <div className="client-backgroundb" >
      <h2> Elevate Your Digital Experience with Saasverse Pvt Ltd’s  <span style={{color:'#003e95'}}>Sitecore </span> Development Services</h2>
      <p>At Saasverse Pvt Ltd, we specialize in delivering top-tier Sitecore Development Services that empower your business to create personalized, scalable, and impactful digital experiences. Our team of Sitecore-certified experts is dedicated to providing tailored solutions that meet your unique business requirements, helping you leverage the full potential of the Sitecore Experience Platform.
      </p>
     <p>From implementation and customization to integration and support, we have the expertise to drive your success in the digital landscape.</p>
    </div>
  
  </div>
  )
}
