import React, { useState, useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = (id) => {
    if (isMobile) {
      setIsSubmenuOpen(prevState => ({
        ...prevState,
        [id]: !prevState[id]
      }));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header__wrapper">
        <a className="header__logo" href="/">
          <picture>
            <source media="(min-width: 768px)" srcSet="/assets/images/saasverselast.png" />
            <img src="/assets/images/saasverselast.png" alt="saasverse logo" />
          </picture>
        </a>
        <div className={`nav ${isMenuOpen ? 'nav--open' : ''}`}>
          <div className="nav__wrapper">
            {[
              { id: 'dropNav1', title: 'Services', links: [
                { href: "/salesforce-consulting-company", text: "Salesforce Consulting Service", desc: "Support and development" },
                { href: "/salesforce-isv", text: "Salesforce ISV development", desc: "Launch own Salesforce app" },
                { href: "salesforce-custom", text: "Salesforce Custom development", desc: "Implement any solution for your Salesforce" },
                { href: "/salesforce-integration", text: "Salesforce Integration Services", desc: "Integrate any solution with your Salesforce" },
                { href: "/salesforce-managed", text: "Salesforce Managed service", desc: "Dedicated teams for customers" },
                { href: "/salesforce-review", text: "Salesforce Security Review", desc: "Full check-up of Salesforce environment" },
                { href: "/salesforce-ex", text: "Salesforce AppExchange App Development", desc: "Implement any solution for your Salesforce" },
              ] },
              { id: 'dropNav4', title: 'Industries', links: [
                { href: "/automotive-industry", text: "Automotive" },
                { href: "/healthcare-industry/", text: "Healthcare" },
                { href: "/communications-industry/", text: "Communications" },
                { href: "/media-industry/", text: "Media" },
                { href: "/consumer-goods-industry/", text: "Consumer Goods" },
                { href: "/retail-industry/", text: "Retail" },
                { href: "/education-industry/", text: "Education" },
                { href: "/technology-industry/", text: "Technology" },
                { href: "/energy-industry/", text: "Energy" },
                { href: "/travels-hospitality-industry/", text: "Travels & Hospitality" },
                { href: "/financial-industry/", text: "Financial" },
              ] },
              { id: 'dropNav2', title: 'Expertise', links: [
                { href: "/expertise/salesforce-sales-cloud/", text: "Sales Cloud", desc: "Effectively monitor sales process" },
                { href: "/expertise/salesforce-service-cloud/", text: "Service cloud", desc: "Proficiently manage service operations" },
                { href: "/expertise/salesforce-marketing-cloud/", text: "Marketing cloud", desc: "Boost marketing initiatives and <br/>customer engagement" },
                { href: "/expertise/mulesoft-development/", text: "Mulesoft development", desc: "Connect and automate your CRM" },
                { href: "/expertise/health-cloud-services/", text: "Health Cloud", desc: "Tailored solutions for superior care" },
                { href: "/expertise/experience-cloud/", text: "Experience Cloud", desc: "Redefining personalized engagement" },
                { href: "/expertise/tableau-crm/", text: "Tableau CRM", desc: "Unleashing dynamic data insights" },
                { href: "/expertise/salesforce-ai-company/", text: "AI Company", desc: "Enhance business with AI insights" },
                { href: "/expertise/mobile-publisher-services/", text: "Mobile Publisher", desc: "Empower your mobile engagement" },
                { href: "/expertise/salesforce-cpq/", text: "CPQ implementation", desc: "Optimize sales workflow solutions" },
                { href: "/expertise/field-service-lightning/", text: "Field Service Lightning Integration", desc: "Improve field service operations" },
                { href: "/expertise/financial-cloud-implementation-services/", text: "Financial Cloud Implementation", desc: "Optimize financial customer relationships" },
                { href: "/expertise/nonprofit-cloud/", text: "Nonprofit Consultant", desc: "Amplify Impact for Nonprofits" },
              ] },
              { id: 'dropNav3', title: 'Hire', links: [
                { href: "/hire-salesforce-consultants/", text: "Hire Salesforce Consultants" },
                { href: "/hire-salesforce-project-managers/", text: "Hire Salesforce Project Managers" },
                { href: "/hire-salesforce-admin/", text: "Hire Salesforce Administrators" },
                { href: "/hire-salesforce-product-managers/", text: "Hire Salesforce Product Managers" },
                { href: "/hire-salesforce-developers/", text: "Hire Salesforce Developers" },
                { href: "/hire-salesforce-architect/", text: "Hire Salesforce Architects" },
                { href: "/hire-salesforce-business-analyst/", text: "Hire Salesforce Business Analysts" },
                { href: "/hire-salesforce-expert/", text: "Hire Salesforce Quality Analyst" },
              ] },
              { id: 'dropNav6', title: 'Company', links: [
                { href: "/about-us", text: "About Us" },
                { href: "/career/", text: "Careers" },
                { href: "/contact-us/", text: "Contact Us" },
              ] }
            ].map((dropdown) => (
              <div key={dropdown.id} className="nav__dropdown" data-id={dropdown.id}>
                <div className="nav__dropdown_title" onClick={() => toggleSubmenu(dropdown.id)}>
                  {dropdown.title}
                </div>
                <div className={`navContent-wrap nav__dropdown_content ${isSubmenuOpen[dropdown.id] ? 'active' : ''}`} id={dropdown.id}>
                  <div className={`navContent ${dropdown.id === 'dropNav1' ? 'mod-row4' : dropdown.id === 'dropNav4' ? 'mod-row6' : dropdown.id === 'dropNav2' ? 'mod-row7' : 'mod-column'}`}>
                    {dropdown.links.map((link, index) => (
                      <a key={index} className="navContent-item navLink" href={link.href}>
                        {link.text}
                        {link.desc && <span>{link.desc}</span>}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="nav__dropdown"><a className="navLink nav__dropdown_title" href="/training">Training</a></div>
          </div>
        </div>
        <div className="hamburger menuSubTrigger" onClick={toggleMenu}>
          {/* Hamburger Icon */}
          <i className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
