import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseAuto = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Saasverse Expertise
        </h2>
        <p>At Saasverse, we have a deep understanding of the automotive industry and extensive experience in implementing Salesforce solutions. We will work closely with you to:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Identify your specific needs and pain points.

        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop a customized Salesforce solution that addresses your challenges.
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate Salesforce with your existing systems for seamless data flow.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure successful user adoption.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseAuto;
