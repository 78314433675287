import React from 'react'
import { Link } from 'react-router-dom'

export default function FinancialBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="hero"  style={{backgroundImage: `url('/assets/images/banner/expbanner.jpg')`, backgroundSize: "cover",
        backgroundPosition: "center"}}>
  
      <div className="hero-container">
        <div className="hero__content">
          <h1 className="hero__content_title" style={{color:'white' }}>Salesforce Financial Cloud 
          implementation services</h1>
          <p className="hero__content_text" style={{color:'white' }}> How Financial Cloud Implementation Optimizes Customer Relationships


          </p>
          <div className="hero__content_button button button-solid contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</Link></div>
          <div className="hero__content_logos">
             <Link to='https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=bf4cc5b0-f7c4-4d44-99ac-1ddaa2ca27e1'  target="_blank" > <img className="hero__content_logos-item hero__content_logos-item-1" style={{height:'70px',borderRadius:'10px'}} src="/assets/images/salesforcepartner.png" alt="saasverse" /></Link>
            {/* <img className="hero__content_logos-item hero__content_logos-item-2" src="https://magicfuse.co/_images/badges/hero-2.svg" alt="saasverse" /> */}
          </div>
        </div>
        <div className="hero__image">
          <picture className="hire-cns">
            {/* <img src="/assets/images/4444.png"  style={{height:'500px'}} alt="Hire Salesforce Consultants" /> */}
          </picture>
        </div>
      </div>
    </div>
  )
}
