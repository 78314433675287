import React from 'react'

export default function HealthConclusion() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Conclusion</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Salesforce Healthcare Cloud has empowered Unity Health Systems to deliver patient-centered care more efficiently and effectively. By integrating patient data, enhancing care coordination, and improving operational workflows, UHS has positioned itself as a leader in healthcare innovation, ensuring better outcomes for patients and providers alike.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
