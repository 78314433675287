import React from 'react'

export default function B2BServices() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2 style={{textAlign:'center'}}>Our Salesforce B2B Services</h2><br/>

        <div className="reasons__wrapper">
{/* <p> We empower you to unlock the potential of AI with our:</p> */}
        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Discovery and Planning</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>B2B Commerce Cloud Implementation</b>
                 <p>Revolutionize your B2B purchasing process with the help of Salesforce B2B commerce cloud. Consult with our professional advisors to safely and efficiently migrate to e-commerce and adjust to its peculiarities with the help of adjusting it to your current systems. We allow a seamless transition of its sales from conventional methods such as account management to efficiency in order processing in the e-commerce platform.
                 </p>

{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">Sales Cloud</h3> */}
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>The Screencast of CPQ (Configure, Price, Quote) Solutions</b>
    <p>Automate the selling process through an effective CPQ solution. The Salesforce CPQ system will be put in place by our team to improve quote generation and accuracy while also decreasing the number of mistakes on quotes and helping to close more deals faster. Easily modify the quotes that you have and make sure that you give your clients the best and most professional quotes in every proposal that they receive from your company.</p>
   


{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              {/* <h3 className="reasons__item_title">App Testing and Quality Assurance</h3> */}
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Sales Cloud Optimization</b>
                 <p>One of the many ways, you can maximize the efficiency of your sales team is by using Salesforce Sales Cloud. Opportunity management, lead management, customer behavior analysis and all in one place. Optimization guarantees you those crucial sales processes are in order and will facilitate your business objectives.
                 </p>

{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Service Cloud Integration </b>
                <p>Provide top-notch customer service to the clients with the help of Salesforce Service Cloud. With our integration services, you shall be able to handle customer cases as well as provide self-serving portals and a knowledge base all in an attempt to improve and boost customer loyalty. </p>


</p>
            </div>
          </div>
        
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Marketing Automation with Pardot </b>
                <p>Take your B2B marketing to new heights with help from Salesforce Pardot. Our primary focus is creating and maintaining the marketing automation processes, which create leads, control their activity, and help in monitoring the ROI. Ethically guide your sales and marketing approaches, so your companies are on the same team with the proper strategy for success. </p>


</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Custom Development and Integration </b>
                <p>Need something unique? To help you, our development group is one of the best when it comes to development of bespoke Salesforce solutions with focus on B2B scenarios. Starting with connecting with third-party systems to creating new applications, the Salesfore environment is set up in accordance with your business. </p>


</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Data Migration and Management </b>
                <p>Maximizing the shift to Salesforce is easy with our data management solutions. From data preparatory tasks such as data cleansing and mapping to data migration that includes secure migration, thereby providing you with clean data ready to be used, all duties are handled. </p>


</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Training and Change Management </b>
                <p>Any change is good if you consider adoption as the core of any new system. This means that we guarantee that your team is only trained enough to perfect using Salesforce, and only offer change management strategies to make the transformation as positive an experience as possible. </p>


</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
           
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Ongoing Support and Maintenance </b>
                <p>This is not the end of the relationship, we take our products to the next level, implementation. With our continuous support, systems update and maintenance solutions, your Salesforce B2B solutions will continue to run efficiently and meet the needs of the organization. </p>


</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  )
}
