import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseTab = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Tableau CRM Certified Professionals </b> <br/>Our team comprises certified experts who possess deep knowledge of both Salesforce and Tableau CRM. We ensure you leverage the platform's full potential to unlock actionable insights.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Customizable Dashboards & Reports</b><br/>We don't believe in a one-size-fits-all approach. We design custom dashboards and reports tailored to your specific business needs and KPIs, so you have the information you need at a glance.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Data Storytelling Experts</b><br/>Data is powerful, but communication is key. We help you transform raw data into compelling narratives that resonate with stakeholders across your organization.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseTab;
