import React from 'react'
import { Link } from 'react-router-dom'
export default function AWSBanner() {
  return (
    <div className="hero"  style={{backgroundImage: `url('/assets/images/banner/aws.jpg')`, backgroundSize: "cover",
        backgroundPosition: "center"}}>
  
      <div className="hero-container">
        <div className="hero__content">
          <h1 className="hero__content_title" style={{color:'white' }}>Amazon Web Services (AWS) Development Services</h1>
          <p className="hero__content_text" style={{color:'white' }}>Harness the Power of AWS with Saasverse Pvt Ltd

          </p>
          <div className="hero__content_button button button-solid contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</a></div>
         
        </div>
        <div className="hero__image">
          <picture className="hire-cns">
            {/* <img src="/assets/images/4444.png"  style={{height:'500px'}} alt="Hire Salesforce Consultants" /> */}
          </picture>
        </div>
      </div>
   
  
    </div>
  )
}
