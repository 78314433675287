import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseComm = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Saasverse Expertise in Communications
        </h2>
        <p>At Saasverse, we have a team of Salesforce experts well-versed in the intricacies of the communications industry. We can help you:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Customise Communications Cloud to meet your specific needs, whether you're a mobile network operator, internet service provider, or cable company.


        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate Communications Cloud with your existing systems for a unified view of your customer data.
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop custom solutions to address unique challenges, such as managing complex service bundles or optimizing network infrastructure.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training to ensure your staff can leverage Communications Cloud effectively.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseComm;
