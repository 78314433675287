import React from 'react'
import './css/banner.css'
import './css/saasversepart.css'
import { Link } from 'react-router-dom'
export default function AppExchangeBanner() {
  return (
    <>
    <div className="hero"  style={{backgroundImage: `url('/assets/images/banner/banner002.png')`, backgroundSize: "cover",
      backgroundPosition: "center"}}>

    <div className="hero-container">
      <div className="hero__content">
        <h1 className="hero__content_title" style={{color:'#006b95' }}>Salesforce AppExchange Development Company</h1>
        <p className="hero__content_text"> Elevate Your Business with Salesforce AppExchange App Development
        </p>
        <div className="hero__content_button button button-solid contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Get a quote</a></div>
        <div className="hero__content_logos">
          <Link to='https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=bf4cc5b0-f7c4-4d44-99ac-1ddaa2ca27e1'  target="_blank" > <img className="hero__content_logos-item hero__content_logos-item-1" style={{height:'70px',borderRadius:'10px'}} src="/assets/images/salesforcepartner.png" alt="saasverse" /></Link>
          {/* <img className="hero__content_logos-item hero__content_logos-item-2" src="https://magicfuse.co/_images/badges/hero-2.svg" alt="saasverse" /> */}
        </div>
      </div>
      <div className="hero__image">
        <picture className="hire-cns">
          {/* <img src="/assets/images/4444.png"  style={{height:'500px'}} alt="Hire Salesforce Consultants" /> */}
        </picture>
      </div>
    </div>
 

  </div>
{/* <div class="saasverse-partner">
  <div class="saasverse-inner">
    
     <img alt="Salesforce Partner" width="100" height="70" src="/assets/images/salesforce.png"/>
   
    <p class="con">PARTNER</p>
  </div>
  <div class="saasverse-inner">
     <img alt="Salesforce PDO Partner" width="100" height="70" src="/assets/images/salesforce.png"/>
    <p class="con">PDO</p>
  </div>
  <div class="saasverse-inner" > 
    <img alt="AppExchange Partner" width="100" height="70" src="/assets/images/salesforce.png"/>
    <p class="con"> Available on <br/> AppExchange </p>
  </div>
  <div class="saasverse-inner">
     <img alt="Salesforce Managed Service Provider Partner" width="100" height="70" src="/assets/images/salesforce.png"/>
    <p class="con"> <span class="d-inline-block w-100"> MANAGED SERVICE <br/> PROVIDER </span> PARTNER </p>
  </div>
</div> */}

  </>
  )
}
