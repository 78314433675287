import React from 'react'

export default function JustQuality() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Testing</h2>
      <p>Our Salesforce Quality Analysts offer a comprehensive skillset</p>
    </div>
    <div className="columnt">
      <p><b>Test Plan & Case Development</b><br/>They collaborate with stakeholders to develop comprehensive test plans and test cases covering all functionalities of your Salesforce solution.
      </p>
      <br/> <p><b>Test Execution & Reporting</b> <br/>Our QAs execute test plans meticulously, document results, and report on findings in a clear and concise manner.
      </p>
    </div>
    <div className="columnt">
      <p><b>Defect Tracking & Management</b><br/>They track and manage defects throughout the testing lifecycle until they are resolved.</p>
      <br/> <p><b>Usability Testing</b><br/>Our QAs conduct usability testing to ensure the Salesforce platform is intuitive and user-friendly for your team.</p>
    </div>
  </div>
  )
}
