import React, { useState, useEffect } from 'react';
import '../css/Testimonial.css'; // Import your CSS file for styling

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([
    {
      id: 1,
      name: 'Evelyn P',
      text: 'Saasverse efforts have reorganized the client users, permissions, processes, and dashboards. Their communicative, flexible, and responsive approach resulted in a positive partnership. Ultimately, their Salesforce expertise complemented their technical expertise. ',
      country: 'United States',
    },
    {
      id: 2,
      name: 'Chris Crombie',
      text: 'Saasverse team ability to quickly pivot their operations whenever the requirements and deadlines had to shift to accommodate the client. This allowed the company to continue with the project without wasting any time in regrouping efforts.',
      country: 'United Kingdom',
    },
    {
      id: 3,
      name: 'Lauren Olson',
      text: 'Saasverse completed deliverables on time and provided detailed reports throughout the project. They had an efficient workflow, and transparent communication, which impressed the client.',
      country: 'United States',
    },
  ]);

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-container">
      <h2 className="testimonial-heading" style={{paddingBottom:'10px'}}>Testimonial</h2>
      <div className="testimonial">
        <div className="testimonial-content">
          <p className="testimonial-text">{testimonials[currentTestimonial].text}</p>
          <p className="testimonial-author">{testimonials[currentTestimonial].name}</p>
          <p className="testimonial-country">{testimonials[currentTestimonial].country}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
