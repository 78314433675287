

import React from 'react';
// import './../css/Trustedby2.css';

const TrainingPartner = () => {
  
  const partnerLogos = [
    '/assets/images/traininglogoo/CapgeminiLogo.png',
    '/assets/images/traininglogoo/CognizantLogo.png',
    '/assets/images/traininglogoo/FujitsuLogo.png',
    '/assets/images/traininglogoo/genpactlogo.png',
    '/assets/images/traininglogoo/WileyEdgelogo.png',
    '/assets/images/traininglogoo/ibmlogo.png',
    '/assets/images/traininglogoo/HCLLogo.png',
    '/assets/images/traininglogoo/TataLogo.png',
    '/assets/images/traininglogoo/Ilearninglogo.png',
   
    
  ];

  return (
    <section className="trusted-partner">
        {/* <p style={{color:'darkgrey'}}>Industry Partnerships</p> */}
      <h2>Elevating Training Through<br/> Accredited Collaborations</h2>
      <div className="trusted-partner__grid">
        {partnerLogos.map((logo, index) => (
            <div key={index} className="trusted-partner__item">
              
            <img src={logo} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrainingPartner;
