import React from 'react';


const HealthSolution = () => {
  return (
    <div className="info-container">
    <div className="info-image">
      <img src="/assets/images/casestudy/healthsolution.jpg" alt="Why Choose Saasverse?" />
    </div>
    <div className="info-text">
      <h2 style={{marginBottom:'0px'}}>Solution</h2>
      <h3 style={{fontSize:'20px',color:'#006b95',fontWeight:'500',marginBottom:'10px'}}>Implementation of Salesforce Healthcare Cloud
      </h3>
      {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
      {/* </p> */}
      <p>
       <b style={{color:'#006b95'}}>Platform Deployment </b> <br/>UHS decided to implement Salesforce Healthcare Cloud, leveraging its robust capabilities in patient relationship management, care coordination, and data integration.

      </p>
      <p>
        <b style={{color:'#006b95'}}>Customization</b><br/>Salesforce was customized to align with UHS's specific workflows and regulatory requirements, including HIPAA compliance for patient data security.
      </p>
      <p>
       <b style={{color:'#006b95'}}>Integration</b><br/>Seamless integration with existing systems such as electronic health records (EHR) and billing systems was achieved, ensuring a holistic view of patient information across departments.</p>
     
    </div>
  </div>
  );
};

export default HealthSolution;
