import React from 'react'


import Faq from '../../Faq/Faq'



import EnergyBanner from './energycompo/EnergyBanner'
import EnergyBenifits from './energycompo/EnergyBenifits'
import EnergyBanner2 from './energycompo/EnergyBanner2'
import EnergyReadyBanner from './energycompo/EnergyReadyBanner'
import WhySaasverseEnergy from './energycompo/WhySaasverseEnergy'

export default function Energy() {
  return (
    <><EnergyBanner/>
    <EnergyBenifits/>
    <EnergyBanner2/>
    <WhySaasverseEnergy/>
    <EnergyReadyBanner/>
    <Faq/>
      
    </>
  )
}
