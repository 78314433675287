import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/CertificateSlider.css'; // Import your CSS for styling

const CertificateSlider = () => {
  const images = [
    '/assets/images/certificateImage/certificate1.png',
    '/assets/images/certificateImage/certificate2.png',
    '/assets/images/certificateImage/certificate3.png',
    '/assets/images/certificateImage/certificate4.png',
    '/assets/images/certificateImage/certificate5.png',
    '/assets/images/certificateImage/certificate6.png',
    '/assets/images/certificateImage/certificate7.png',
    '/assets/images/certificateImage/certificate8.png',
  ];

  const settings = {
    dots: false, // Show dots for navigation
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          arrows: false, // Disable arrows on small screens
          dots: false, // Keep dots on small screens
        },
      },
    ],
  };

  return (
    <div className="slider-containerc">
      <div className='certitext'>
        <h2> We Hold Skilled, <b>Well-Trained</b> And <br />Certified <b>Salesforce Team</b></h2>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="slick-slidec">
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

// Custom Arrow Components
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowc next-arrowc" onClick={onClick}>
      &#9654; {/* Right arrow icon */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowc prev-arrowc" onClick={onClick}>
      &#9664; {/* Left arrow icon */}
    </div>
  );
};

export default CertificateSlider;
