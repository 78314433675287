import React, { useState } from 'react';


export default function CloudFaq() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };
    const toggleContent1 = () => {
        setIsOpen1(!isOpen1);
    };
    const toggleContent2 = () => {
        setIsOpen2(!isOpen2);
    };
    const toggleContent3 = () => {
        setIsOpen3(!isOpen3);
    };
    const toggleContent4 = () => {
        setIsOpen4(!isOpen4);
    };
    const toggleContent5 = () => {
        setIsOpen5(!isOpen5);
    };
    const toggleContent6 = () => {
        setIsOpen6(!isOpen6);
    };

  return (
    <div className="faq-container">
    <div className="faq-row">
        <h2 className="faq-title" style={{color:'black'}}>FAQ's</h2>
        <div className="faq-collapse">
            <div className="faq-accordion">
                <div className="faq-accordion-content" itemScope itemType="https://schema.org/FAQPage">

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen ? 'faq-active' : ''}`} onClick={toggleContent}>
                        What are the benefits of using cloud services for my business?
                        </div>
                        <div className={`faq-card-content ${isOpen ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Cloud services offer numerous benefits, including scalability, cost efficiency, enhanced security, and the ability to quickly adapt to market changes.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen1 ? 'faq-active' : ''}`} onClick={toggleContent1}>
                        How can Saasverse Pvt Ltd help with my cloud migration?
                        </div>
                        <div className={`faq-card-content ${isOpen1 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Saasverse provides end-to-end cloud migration services, from planning and execution to post-migration optimization, ensuring a smooth and efficient transition.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen2 ? 'faq-active' : ''}`} onClick={toggleContent2}>
                        What is a multi-cloud strategy, and why should I consider it?
                        </div>
                        <div className={`faq-card-content ${isOpen2 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            A multi-cloud strategy involves using multiple cloud providers to avoid vendor lock-in, optimize costs, and improve performance. It allows you to choose the best services from each provider.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen3 ? 'faq-active' : ''}`} onClick={toggleContent3}>
                        How does Saasverse ensure the security of my cloud environment?
                        </div>
                        <div className={`faq-card-content ${isOpen3 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            We conduct thorough security assessments, implement robust security measures, and ensure compliance with industry regulations to protect your cloud environment.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen4 ? 'faq-active' : ''}`} onClick={toggleContent4}>
                        What kind of support can I expect after cloud deployment?
                        </div>
                        <div className={`faq-card-content ${isOpen4 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Saasverse offers ongoing support and maintenance, including monitoring, optimization, and troubleshooting, to ensure your cloud environment remains efficient and secure.
                            </p>
                        </div>
                    </div>

                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen5 ? 'faq-active' : ''}`} onClick={toggleContent5}>
                        Can Saasverse help with hybrid cloud solutions?
                        </div>
                        <div className={`faq-card-content ${isOpen5 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            Yes, we specialize in integrating on-premises infrastructure with public and private clouds to create a seamless hybrid cloud environment.
                            </p>
                        </div>
                    </div>
                    <div className="faq-card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                        <div className={`faq-card-title ${isOpen6 ? 'faq-active' : ''}`} onClick={toggleContent6}>
                        How can I manage and optimize cloud costs?
                        </div>
                        <div className={`faq-card-content ${isOpen6 ? 'faq-show' : ''}`}>
                            <p className="faq-card-text">
                            We implement cost management tools and practices to monitor spending and optimize resource allocation, helping you reduce costs and maximize value.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
  )
}
