import React from 'react'

import Case1Banner from './Compo/Case1Banner'
import HealthClientBackground from './Compo/HealthClientBackground'
import HealthClient from './Compo/HealthClient'
import HealthSolution from './Compo/HealthSolution'
import HealthImplementation from './Compo/HealthImplementation '
import HealthResult from './Compo/HealthResult'
import HealthFuture from './Compo/HealthFuture'
import HealthConclusion from './Compo/HealthConclusion'
import ContactForm from '../../Company/Contact Us/contactcompo/ContactForm'
import CertificateSlider from '../../../Home/Component/CertificateSlider'

export default function HealthCloudCase() {
  return (
    <>
    <Case1Banner/>
    <HealthClient/>
    <HealthSolution/>
    <HealthImplementation/>
    <CertificateSlider/>
    <HealthResult/>
    <HealthFuture/>
    <HealthConclusion/>
    <ContactForm/>
   {/* <HealthClientBackground/> */}
  
   </>
  
  )
}
