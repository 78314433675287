import React from 'react';
import './css/JobOpenings.css'; // Import the CSS file

const JobOpenings = () => {
  const jobOpenings = [
    { 
      id: 1, 
      title: 'Salesforce Developer', 
      location: 'Jaipur', 
      fullTime: true, 
      experience: '2+ years', 
      city: 'Anywhere' ,
      description: 'Experience with Salesforce development, strong problem-solving skills, and ability to work collaboratively.'
    },
    { 
      id: 2, 
      title: 'App Developer', 
      location: 'Jaipur', 
      fullTime: true, 
      experience: '3+ years', 
      city: 'San Francisco, CA' ,
      description: 'Proficiency in programming languages, experience in app development, and strong analytical skills.'
    },
    { 
      id: 3, 
      title: 'Data Integration Specialist', 
      location: 'Jaipur', 
      fullTime: false, 
      experience: '3+ years', 
      city: 'New York, NY' ,
      description: 'Experience with data integration tools, strong technical skills, and attention to detail.'
    },
    { 
      id: 4, 
      title: 'Automation Engineer', 
      location: 'Jaipur', 
      fullTime: true, 
      experience: '5+ years', 
      city: 'Anywhere' ,
      description: 'Expertise in automation tools, strong problem-solving skills, and ability to work independently.'
    },
    // Add more job openings as needed
  ];

  return (
    <div className="job-openings">
      <h2 className="job-openings-title">Current Job Openings</h2>
      <div className="job-list">
        {jobOpenings.map(job => (
          <div key={job.id} className="job-item">
            <h3 className="job-title" style={{fontSize:'35px',fontWeight:'500'}}>{job.title}</h3>
            <div className="job-details">
              <p><span className="label">Experience:</span> {job.experience}</p>
              <p><span className="label">Location:</span> {job.location}</p>
              <button className="apply-button">
                <b>
                  <a 
                    href={`mailto:hr@sasverse.in?subject=Application for ${encodeURIComponent(job.title)}`} 
                    style={{color:'white',textDecoration:'none'}}
                  >
                    Apply for this job
                  </a>
                </b>
              </button>
            </div>
            <div className="job-description">
              <p><span className="label"></span> {job.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobOpenings;
