import React from 'react'
import ContactBanner from './contactcompo/ContactBanner'
import ContactInfo from './contactcompo/ContactInfo'
import ContactForm from './contactcompo/ContactForm'
import VisitUs from './contactcompo/VisitUs'

export default function ContactUs() {
  return (
    <>
      <ContactBanner/>
      <ContactInfo/>
      <ContactForm/>
      <VisitUs/>
    </>
  )
}
