import {React,useState} from 'react'


export default function ManagedBanner2() {

  return (
    <>
        {/* <div className="sp-line"></div> */}
      <div className="bannerL">
        <div className="container-md">
          <div className="bannerL__container">
            <div className="bannerL__wrapper">
              <h2 className="bannerL__title">Need an experienced Salesforce team?</h2>
              <h5 className="bannerL__subtitle">Book a call and discuss details with our experts.</h5>
              <div className="bannerL__button button button-outline contactModalButton" ><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>

              <div className="bannerL__list">
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">120+</h2>
                  <h6 className="bannerL__list-item_text">Delivered projects</h6>
                </div>
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">50+</h2>
                  <h6 className="bannerL__list-item_text">Salesforce certified specialists</h6>
                </div>
                <div className="bannerL__list-item">
                  <h2 className="bannerL__list-item_num">8+</h2>
                  <h6 className="bannerL__list-item_text">years of experience</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
