import React from 'react'

export default function ReadyCloud() {
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Transform Your Business with Cloud Consulting</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Take the leap to the cloud with Saasverse Pvt Ltd’s Cloud Consulting Services. Contact us today to discuss your cloud needs and embark on a journey towards digital excellence.</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
        </div>
      </div>
    </div>
  </div>
  )
}
