import React from 'react';
import { Link } from 'react-router-dom';

export default function Litify() {
  return (
    <div className="info-container" style={{ display: 'flex', alignItems: 'center' }}>
      <div className="info-image" style={{ display: 'flex',flexDirection:'column', alignItems: 'center' }}>
      <img
          src="/assets/images/litify.png"
          alt="Litify"
          style={{ width: '200px', height: '120px', marginLeft: '0px', objectFit: 'contain' }}
        />
         <span style={{ margin: '0 10px', fontSize: '34px' }}>+</span>
        <img
          src="/assets/images/salesforce.png"
          alt="Salesforce"
          style={{ width: '300px', height: '120px', marginRight: '10px', objectFit: 'contain' }}
        />
       
      
      </div>
      <div className="info-text" style={{ marginLeft: '20px' }}>
        <h2>Salesforce and Litify Integration + Automation</h2>
        <p>
          With our expertise in Litify custom API development, we help companies who can't find a
          proper integration for their business process or use case.
        </p>
        {/* <a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a> */}
    
      <a href='/contact-us'>  <button style={{padding:'10px',borderRadius:'8px',border:'1px solid #003e95',backgroundColor:'#003e95',color:'white',fontWeight:'500' ,marginTop:'10px',    cursor: 'pointer'}}>Consult With Our Expert</button></a>
      </div>
    </div>
  );
}
