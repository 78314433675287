import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseMule = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Why Choose Saasverse Pvt Ltd for Mulesoft Development?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>MuleSoft Certified Experts </b> <br/>Our team boasts a deep understanding of Mulesoft Anypoint Platform. We're certified developers with a proven track record of delivering robust, scalable, and secure integrations tailored to your unique CRM needs.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Custom Solutions, Not Cookie-Cutter</b><br/>We don't believe in a one-size-fits-all approach. We take the time to understand your specific business goals and CRM workflows, then design custom Mulesoft integrations that optimize your processes and unlock hidden efficiencies.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Security & Reliability Always</b><br/>Data security is paramount. We prioritize robust security measures and ensure your integrations operate smoothly 24/7. We understand that downtime isn't an option, so we build reliable integrations you can count on.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverseMule;
