import React from 'react'


import Faq from '../../Faq/Faq'

import AutomotiveBanner from './autocompo/AutomotiveBanner'
import AutomotiveBenifits from './autocompo/AutomotiveBenifits'
import AutomotiveBanner2 from './autocompo/AutomotiveBanner2'
import AutomotiveReadyBanner from './autocompo/AutomotiveReadyBanner'
import WhySaasverseAuto from './autocompo/WhySaasverseAuto'

export default function Automotive() {
  return (
    <><AutomotiveBanner/>
    <AutomotiveBenifits/>
    <AutomotiveBanner2/>
    <WhySaasverseAuto/>
    <AutomotiveReadyBanner/>
    <Faq/>
      
    </>
  )
}
