import React from 'react'


import Faq from '../../Faq/Faq'

import ExperienceCloudBanner from './experiencecompo/ExperienceCloudBanner'
import ExperienceCloudBenifits from './experiencecompo/ExperienceCloudBenifits'
import ExperienceCloudBanner2 from './experiencecompo/ExperienceCloudBanner2'
import WhySaasverseExp from './experiencecompo/WhySaasverseExp'
import ExperienceCloudReadyBanner from './experiencecompo/ExperienceCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function ExperienceCloud() {
  return (
    <><ExperienceCloudBanner/>
    <ExperienceCloudBenifits/>
    <ExperienceCloudBanner2/>
    <WhySaasverseExp/>
    <CertificateSlider/>
    <Trustedby2/>
    <ExperienceCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
