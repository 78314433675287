import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseMark = () => {
  return (
    <div className="info-container">
      <div className="info-image">
        <img src="/assets/images/websiteimages/23.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Why Partner with Saasverse?
        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>We're Marketing Cloud wizards</b> <br/> Our certified experts have a proven track record of success, ensuring you leverage the platform's full potential.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Data is our superpower</b><br/> We don't just send emails; we use data to understand your audience and craft hyper-personalized experiences that convert.
        </p>
        <p>
         <b style={{color:'#006b95'}}>Your partner, every step of the way</b><br/>From crafting the perfect strategy to ongoing optimization, we're here to guide you and ensure your marketing thrives.</p>
        <p>
         <b style={{color:'#006b95'}}>Proven Results</b><br/>Our track record of successful Salesforce management and satisfied clients across various industries speaks to our capability and reliability.</p>
      
      </div>
    </div>
  );
};

export default WhySaasverseMark;
