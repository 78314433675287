import React from 'react'

export default function SalesImplementation() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Implementation <span style={{color:'#006b95' }}>Highlights</span></h2>
      {/* <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4> */}
    </div>
    <div className="columnt">
      <p><b>Lead Management</b><br/>Salesforce enabled Tech Solutions Inc. to capture, qualify, and nurture leads more effectively, improving lead conversion rates and sales efficiency.</p>
      <br/> <p><b>Opportunity Tracking</b> <br/>Sales teams gained real-time visibility into their pipeline, allowing them to prioritize opportunities, forecast revenue accurately, and manage deals more efficiently.</p>
    </div>
    <div className="columnt">
      <p><b>Sales Collaboration</b><br/>Improved communication and collaboration among sales teams, enabled by Salesforce Chatter and shared dashboards, facilitated better alignment and faster decision-making.</p>
      {/* <br/> <p><b>Proven Success</b><br/> Our track record of successful security reviews and satisfied clients demonstrates our capability and reliability in ensuring Salesforce security.</p> */}
    </div>
  </div>
  )
}
