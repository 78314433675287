import React from 'react';
import './css/HealthClient.css'; // Make sure to create corresponding CSS for styling

const HealthClient = () => {
  return (
    <div className="client-info-containerb">
      <div className="client-backgroundb" >
        <h2>Business  <span style={{color:'#003e95'}}>Challenge</span></h2>
        <p>Unity Health Systems (UHS) faced challenges in managing patient data effectively across multiple departments and facilities. With outdated systems, they struggled with fragmented patient records, inefficient communication between care teams, and delays in accessing critical patient information.
        </p>
       <p> They sought a solution to unify their patient management processes, enhance care coordination, and improve overall operational efficiency.</p>
      </div>
    
    </div>
  );
};

export default HealthClient;
