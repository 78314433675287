import React from 'react'

export default function CloudServices() {
  return (
    <div id="servicess" >
        <div className="cnts">
          <div className="rows">
      <h2 style={{fontSize:'40px',textAlign:'center',paddingBottom:'20px'}}> Our Cloud Consulting Services
      </h2>
      {/* <p>At Saasverse, we offer more than just a job – we offer a career filled with opportunities for growth, learning, and advancement. Here’s why you should consider joining our team:</p><br/> */}
    
            <div className="reasons__wrappers">
    
            <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Cloud Strategy and Planning</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Assessment and Analysis</b>
                     <p> Evaluate your current IT landscape and business objectives to develop a tailored cloud strategy.

                     </p>
                     <b style={{color:'#006b95'}}>Roadmap Development</b>
                     <p>Create a comprehensive roadmap outlining your cloud adoption journey, including timelines and milestones.

                     </p>
                    
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Cloud Architecture and Design</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Custom Cloud Solutions</b>
                     <p>Design cloud architectures that are tailored to your specific business needs and goals.

                     </p>
                     <b style={{color:'#006b95'}}>Scalable and Resilient Design</b>
                     <p>Ensure your cloud infrastructure is scalable, resilient, and capable of supporting your growth.

                     </p>
         
   
   
    </p>
                </div>
              </div>

              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Cloud Migration Services</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}> Migration Planning</b>
                     <p>Develop a detailed migration plan that minimizes downtime and ensures data integrity.

                     </p>
                     <b style={{color:'#006b95'}}>Seamless Migration</b>
                     <p>Execute a smooth migration of your applications, data, and workloads to the cloud with minimal disruption.

                     </p>
                   
   
   
    </p>
                </div>
              </div>


              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Multi-Cloud and Hybrid Cloud Solutions</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Multi-Cloud Strategy</b>
                     <p>Develop and implement strategies to leverage multiple cloud providers for optimal performance and cost-efficiency.

                     </p>
                     <b style={{color:'#006b95'}}>Hybrid Cloud Integration</b>
                     <p>Seamlessly integrate your on-premises infrastructure with public and private clouds for a unified environment.
                     </p>
                  
   
   
    </p>
                </div>
              </div>


              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles"> Cloud Cost Optimization</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Cost Management</b>
                     <p>Implement cost management tools and practices to monitor and control cloud spending.

                     </p>
                     <b style={{color:'#006b95'}}>Resource Optimization</b>
                     <p>Optimize cloud resource allocation to reduce waste and maximize value.
                     </p>
                  
   
   
    </p>
                </div>
              </div>




              <div className="reasons__items"><span className="reasons__item_figures"></span>
                <div className="reasons__item_contents">
                    
                  <h3 className="reasons__item_titles">Cloud Security and Compliance</h3>
                  <p className="reasons__item_texts">
                     <b style={{color:'#006b95'}}>Security Assessments</b>
                     <p> Conduct comprehensive security assessments to identify and address vulnerabilities.

                     </p>
                     <b style={{color:'#006b95'}}>Compliance Support</b>
                     <p>Ensure your cloud environment complies with industry regulations and standards.
                     </p>
                  
   
   
    </p>
                </div>
              </div>
          
          
    
             
            
    
    
             
    
    
             
            
    
             
          
            </div>
          </div>
        </div>
      </div>
  )
}
