import React from 'react'

export default function JustBA() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Business Analysis</h2>
      <p>Our Salesforce Business Analysts offer a comprehensive skillset</p>
    </div>
    <div className="columnt">
      <p><b>Solution Design & Documentation</b><br/>They collaborate with stakeholders to design and document the optimal Salesforce solution based on your requirements.
      </p>
      <br/> <p><b>Data Analysis & Reporting</b> <br/> Our BAs leverage data to identify trends, patterns, and opportunities for improvement.
      </p>
    </div>
    <div className="columnt">
      <p><b>System Testing & User Training</b><br/>They participate in system testing and develop comprehensive user training materials to ensure a smooth transition to the new Salesforce environment.</p>
      <br/> <p><b>Post-Implementation Support</b><br/>Our BAs provide ongoing support to ensure your team continues to leverage Salesforce effectively.</p>
    </div>
  </div>
  )
}
