import React from 'react'
import CloudBanner from './compo/CloudBanner'
import CloudPower from './compo/CloudPower'
import WhyCloud from './compo/WhyCloud'
import CloudServices from './compo/CloudServices'
import WhySaasverseC from './compo/WhySaasverseC'
import ReadyCloud from './compo/ReadyCloud'
import CloudFaq from './compo/CloudFaq'

export default function CloudComputing() {
  return (
    <>
      <CloudBanner/>
      <CloudPower/>
      <WhyCloud/>
      <CloudServices/>
      <WhySaasverseC/>
      <ReadyCloud/>
      <CloudFaq/>
    </>
  )
}