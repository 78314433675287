import React from 'react'

import Faq from '../../Faq/Faq'


import CPQSBanner from './cpqcompo/CPQBanner'
import CPQBenifits from './cpqcompo/CPQBenifits'
import CPQBanner2 from './cpqcompo/CPQBanner2'
import WhySaasverseCPQ from './cpqcompo/WhySaasverseCPQ'
import CPQReadyBanner from './cpqcompo/CPQReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function CPQImplementation() {
  return (
    <><CPQSBanner/>
    <CPQBenifits/>
    <CPQBanner2/>
    <WhySaasverseCPQ/>
    <CertificateSlider/>
    <Trustedby2/>
    <CPQReadyBanner/>
    <Faq/>
      
    </>
  )
}
