import React from 'react'
import { Link } from 'react-router-dom'

export default function TechnologyReadyBanner() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to Supercharge Your Tech Stack?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>Partner with Saasverse and unlock the full potential of Salesforce for your tech venture. Let's build a robust CRM platform that fuels your sales engine, fosters innovation, and propels your tech business to the forefront of the industry. Contact us today and see how Saasverse can become your trusted partner in tech success!</p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton" onClick={scrollToTop}><Link  to='/contact-us' style={{textDecoration:'none',color:'white'}}>Contact Us Today</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
