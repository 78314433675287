import React from 'react'
import './css/benifits.css'
export default function AppExchangeBenifits() {
  return (
    <div id="services" >
    <div className="cnt">
      <div className="row">
  <h2 >Our Approach to Salesforce AppExchange App Development<br/></h2>

        <div className="reasons__wrapper">

        <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Discovery and Planning</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}> Needs Assessment:</b>
                 <p> We start by understanding your business objectives, processes, and challenges to identify opportunities for app development.
                 </p>
<b style={{color:'#006b95'}}>Market Research:</b> 
<p>Conduct a thorough analysis of existing apps on the AppExchange to identify gaps and areas for innovation.
</p>
{/* <p><b style={{color:'#006b95'}}>Feature Enhancements:</b> Regularly review and implement new Salesforce features and enhancements to keep your system up-to-date.</p> */}
</p>
            </div>
          </div>

          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Custom App Development</h3>
              <p className="reasons__item_text">  
    <b style={{color:'#006b95'}}>Tailored Solutions:</b>
    <p>Develop custom applications that address your specific business requirements and provide unique value to your users.</p>
   
<b style={{color:'#006b95'}}>User-Centric Design:</b>
<p>Design intuitive and user-friendly interfaces that enhance user adoption and satisfaction.
</p>

{/* <b style={{color:'#006b95'}}>   Data Migration:</b> */}
{/* <p> We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.</p> */}
</p>
            </div>
          </div>
         
        


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">App Testing and Quality Assurance</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Rigorous Testing:</b>
                 <p>Conduct comprehensive testing to ensure the functionality, security, and performance of the app meet the highest standards.
                 </p>
<b style={{color:'#006b95'}}>User Acceptance Testing:</b> 
<p>Involve key stakeholders in the testing process to validate the app's functionality and usability.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>


          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">AppExchange Listing and Deployment
              </h3>
              <p className="reasons__item_text">
                <b style={{color:'#006b95'}}>Security Review:</b>
                <p>Prepare and submit the app for Salesforce's security review process to ensure compliance with AppExchange guidelines.</p>
<b style={{color:'#006b95'}}>Packaging and Deployment:</b> 
<p>Package the app for distribution on the AppExchange, ensuring seamless installation and integration with Salesforce orgs.</p>
{/* <b style={{color:'#006b95'}}>Lightning Components:</b> */}
{/* <p>We build responsive Lightning Components to enhance user experience and functionality. </p> */}
</p>
            </div>
          </div>
          <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Post-Deployment Support and Maintenance</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Continuous Support:</b>
                 <p>Provide ongoing support and maintenance to address any issues, implement updates, and ensure the app remains optimized.
                 </p>
<b style={{color:'#006b95'}}>User Training and Adoption: </b> 
<p>Offer training sessions to help users familiarize themselves with the app and maximize its potential.</p>
{/* <p><b style={{color:'#006b95'}}>User Training:</b> We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.</p> */}
</p>
            </div>
          </div>
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Strategic Consulting</h3>
              <p className="reasons__item_text">
                 <b style={{color:'#006b95'}}>Business Alignment:</b>
                 <p>Regularly review your Salesforce strategy to ensure it aligns with your overall business objectives.
                 </p>
<b style={{color:'#006b95'}}>Growth Planning: </b> 
<p>Develop plans for scaling your Salesforce environment as your business grows and evolves.</p>

</p>
            </div>
          </div> */}
         

         
          {/* <div className="reasons__item"><span className="reasons__item_figure"></span>
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">Security review for appexchange</h3>
              <p className="reasons__item_text">Confidence, assistance & full support in protecting customers’ data and handling the Salesforce security review process considering our experience in releasing AppExchange apps. The Salesforce security review team follows all the Salesforce guidelines and doesn’t violate any limits that might threaten customers' business-vital data. We manage highly secure applications as they need to go through a security scan and have code hand validated.</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}
