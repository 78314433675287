import React from 'react'

export default function WhySaasverseC() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <h4>Your Salesforce environment holds sensitive business data, customer information, and confidential records. Regular security assessments are essential to:</h4>
    </div>
    <div className="columnt">
      <p><b>Expert Cloud Consultants</b><br/>Our team of certified cloud consultants brings extensive experience and deep expertise in cloud technologies.</p>
      <br/> <p><b>Customized Solutions</b> <br/>We deliver tailored cloud solutions that align with your unique business needs and objectives.</p>
    </div>
    <div className="columnt">
      <p><b>Innovative Approach</b><br/>We leverage the latest cloud technologies and best practices to drive innovation and efficiency in your organization.</p>
      <br/> <p><b>Commitment to Excellence</b><br/>We are dedicated to providing high-quality services that exceed your expectations and deliver tangible results.</p>
    </div>
  </div>
  )
}
