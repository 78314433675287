import React from 'react';
import './css/Ready.css';

const AppExchangeReadyBanner = () => {
  return (
    <div className="cnt_banner">
      <div className="banner__container">
        <h2 className="banner__heading">Transform Your Business with Custom <br/>Salesforce Apps?</h2>
        <div className="banner__text-wrapper">
          <div className="banner__text-content">
            <p>Partner with Saasverse Pvt Ltd for Salesforce AppExchange App Development and take your business to new heights. Contact us today to discuss your app development needs and start your journey towards success on the AppExchange.</p>
          </div>
          <div className="banner__button-wrapper">
            <div className="banner__button button button-outline contactModalButton"><a  href='/contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppExchangeReadyBanner;
