import React from 'react';
import './css/WhyChooseUs.css'; // Import your custom CSS file

const WhyChooseUs = () => {
  return (
    <div className="containert1">
    <div className="columnt1">
      <h2>Why Choose <span style={{color:'#006b95' }}>Saasverse?</span></h2>
      <p>At Saasverse, we are not just service providers; we are your partners in growth. Our commitment to excellence is reflected in every project we undertake. We stay abreast of the latest trends and best practices to ensure that our solutions are both innovative and effective. Our design philosophy emphasizes user experience and brand consistency, creating interfaces that are not only visually appealing but also intuitive and adaptable across various platforms.</p>
    </div>
    <div className="columnt1">
      <h2>Ongoing Support and Training</h2>
      <p>We understand that the journey doesn’t end with implementation. Our team provides continuous support and training to ensure that you maximize the value of your Salesforce investment. We are here to help you navigate any challenges and keep your systems running smoothly.</p>
      <br/>
    </div>
    <div className="columnt1">
      <h2>Let's Thrive Together</h2>
      <p> At Saasverse, your success is our success. We are dedicated to helping your business thrive in the dynamic Salesforce ecosystem. Contact us today to discover how our solutions can propel your business to new heights.</p>
     
    </div>
  </div>
  );
}

export default WhyChooseUs;
