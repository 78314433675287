import React from 'react'

export default function HealthFuture() {
  return (
    <div className="info-container">
   
    <div className="info-text" style={{textAlign:'justify'}}>
      <h2>Future Directions
      </h2>
      <p style={{fontWeight:'500'}}>Looking ahead, UHS plans to further leverage Salesforce Healthcare Cloud to:
      </p>
      <p>
      Expand patient engagement initiatives through mobile and telehealth solutions.

      </p>
      <p>
      Implement predictive analytics to anticipate patient needs and optimize resource allocation.
      </p>
      <p>
      Enhance interoperability with external healthcare providers for seamless care transitions.</p>
     
    </div>
    <div className="info-image">
      <img src="/assets/images/casestudy/futurehealth.jpg" alt="Why Choose Saasverse?" />
    </div>
  </div>
  )
}
