import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverse = () => {
  return (
    <div className="info-container">
     
      <div className="info-text">
        <h2>Why Choose Service Cloud?

        </h2>
        {/* <p>Salesforce ISV Development offers unparalleled opportunities for businesses to innovate and grow. By developing your own Salesforce application, you can: */}
        {/* </p> */}
        <p>
         <b style={{color:'#006b95'}}>Efficient Case Management</b> <br/>Handle customer inquiries and issues seamlessly with a centralised platform.

        </p>
        <p>
          <b style={{color:'#006b95'}}>Automated Workflows</b><br/>Streamline processes with automated workflows to reduce response times and improve service delivery.
        </p>
        <p>
         <b style={{color:'#006b95'}}>360-Degree View of Customers</b><br/>Gain insights into customer interactions and preferences to personalize service experiences.</p>
       
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/21.jpg" alt="Why Choose Saasverse?" />
      </div>
    </div>
  );
};

export default WhySaasverse;
