import React from 'react'


import Faq from '../../Faq/Faq'


import ConsumerBanner from './consumercompo/ConsumerBanner'
import ConsumerBenifits from './consumercompo/ConsumerBenifits'
import ConsumerBanner2 from './consumercompo/ConsumerBanner2'
import ConsumerReadyBanner from './consumercompo/ConsumerReadyBanner'
import WhySaasverseConsumer from './consumercompo/WhySaasverseConsumer'

export default function ConsumerGoods() {
  return (
    <><ConsumerBanner/>
    <ConsumerBenifits/>
    <ConsumerBanner2/>
    <WhySaasverseConsumer/>
    <ConsumerReadyBanner/>
    <Faq/>
      
    </>
  )
}
