import React from 'react';

const ManagedReadyBanner = () => {
  return (
  
  


<div className="cnt_banner">
<div className="banner__container">
  <h2 className="banner__heading">Ensure Your Salesforce Success</h2>
  <div className="banner__text-wrapper">
    <div className="banner__text-content">
      <p>Partner with Saasverse Pvt Ltd for reliable and efficient Salesforce Managed Services. Contact us today to learn how our dedicated teams can help you manage, optimize, and enhance your Salesforce environment.</p>
    </div>
    <div className="banner__button-wrapper">
      <div className="banner__button button button-outline contactModalButton"><a  href='contact-us' style={{textDecoration:'none',color:'white'}}>Book a Call</a></div>
    </div>
  </div>
</div>
</div>
  );
}

export default ManagedReadyBanner;
