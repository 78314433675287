import React, { useState } from 'react';
import './MobileNavbar.css'; // Ensure the CSS is correctly imported

const MobileNavbar = ({ isMenuOpen, toggleMenu }) => {
  // Track the currently open dropdown ID
  const [openSubmenuId, setOpenSubmenuId] = useState(null);

  const toggleSubmenu = (id) => {
    // Toggle the dropdown: open if closed, close if open
    setOpenSubmenuId(openSubmenuId === id ? null : id);
  };

  // Inline CSS for the "Training" link
  const trainingLinkStyle = {
    // Add any inline styles you want for the "Training" link
    color: '', // Example: change text color on hover
    textDecoration: 'none',
    fontWeight:'bold',
     display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ddd',/* Add border to dropdown title */
    borderRadius: '4px', /* Rounded corners */
    background: '#f9f9f9', /* Background color */
  };

  return (
    <div className={`mobile-nav ${isMenuOpen ? 'mobile-nav--open' : ''}`}>
      <div className="mobile-nav__wrapper">
        {[
            { id: 'dropNav9', title: 'Services', links: [
              { href: "/services-aws", text: "Amazon Web Services (AWS)" },
              { href: "/services-cloud-computing", text: "Cloud Computing Services" },
              { href: "salesforce-sitecore", text: "Sitecore" },
              { href: "salesforce-kentico", text: "Kentico" },
           
            ] },
          { id: 'dropNav1', title: 'Salesforce', links: [
            { href: "/salesforce-consulting-company", text: "Salesforce Consulting Service" },
            { href: "/salesforce-isv-development-company", text: "Salesforce ISV Development" },
            { href: "/salesforce-custom-development-services", text: "Salesforce Custom Development" },
            { href: "/salesforce-integration-services", text: "Salesforce Integration Services" },
            { href: "/salesforce-managed-services", text: "Salesforce Managed Service" },
            { href: "/salesforce-security-review", text: "Salesforce Security Review" },
            { href: "/salesforce-appexchange-development-company", text: "Salesforce AppExchange App Development" },
          ] },
          { id: 'dropNav4', title: 'Industries', links: [
            { href: "/automotive-industry", text: "Automotive" },
            { href: "/healthcare-industry/", text: "Healthcare" },
            { href: "/communications-industry/", text: "Communications" },
            { href: "/media-industry/", text: "Media" },
            { href: "/consumer-goods-industry/", text: "Consumer Goods" },
            { href: "/retail-industry/", text: "Retail" },
            { href: "/education-industry/", text: "Education" },
            { href: "/technology-industry/", text: "Technology" },
            { href: "/energy-industry/", text: "Energy" },
            { href: "/travels-hospitality-industry/", text: "Travels & Hospitality" },
            { href: "/financial-industry/", text: "Financial" },
          ] },
          { id: 'dropNav2', title: 'Expertise', links: [
            { href: "/expertise/salesforce-sales-cloud/", text: "Sales Cloud" },
            { href: "/expertise/salesforce-service-cloud/", text: "Service Cloud" },
            { href: "/expertise/salesforce-marketing-cloud/", text: "Marketing Cloud" },
            { href: "/expertise/mulesoft-development/", text: "Mulesoft Development" },
            { href: "/expertise/health-cloud-services/", text: "Health Cloud" },
            { href: "/expertise/experience-cloud/", text: "Experience Cloud" },
            { href: "/expertise/tableau-crm/", text: "Tableau CRM" },
            { href: "/expertise/b2b-b2c/", text: "Salesforce B2B & B2C Services" },
            { href: "/expertise/salesforce-ai-company/", text: "AI Company" },
            { href: "/expertise/mobile-publisher-services/", text: "Mobile Publisher" },
            { href: "/expertise/salesforce-cpq/", text: "CPQ Implementation" },
            { href: "/expertise/field-service-lightning/", text: "Field Service Lightning Integration" },
            { href: "/expertise/financial-cloud-implementation-services/", text: "Financial Cloud Implementation" },
            { href: "/expertise/nonprofit-cloud/", text: "Nonprofit Cloud Consultant" },
          ] },
          { id: 'dropNav3', title: 'Hire', links: [
            { href: "/hire-salesforce-consultants/", text: "Hire Salesforce Consultants" },
            { href: "/hire-salesforce-project-managers/", text: "Hire Salesforce Project Managers" },
            { href: "/hire-salesforce-admin/", text: "Hire Salesforce Administrators" },
            { href: "/hire-salesforce-product-managers/", text: "Hire Salesforce Product Managers" },
            { href: "/hire-salesforce-developers/", text: "Hire Salesforce Developers" },
            { href: "/hire-salesforce-architect/", text: "Hire Salesforce Architects" },
            { href: "/hire-salesforce-business-analyst/", text: "Hire Salesforce Business Analysts" },
            { href: "/hire-salesforce-expert/", text: "Hire Salesforce Quality Analyst" },
          ] },



          { id: 'dropNav7', title: 'Training', links: [
            { href: "/training/", text: "Salesforce Training & Certifications" },
            { href: "/apply/salesforce-admin/", text: "Salesforce Admin Training" },
            { href: "/apply/salesforce-developer/", text: "Salesforce Developer Training" },
            { href: "/apply/salesforce-cpq/", text: "Salesforce CPQ Training" },
            { href: "/apply/marketing-salesforce/", text: "Salesforce Marketing Training" },
           
          ] },
          { id: 'dropNav8', title: 'Work', links: [
            { href: "/case-studies/salesforce-healthcare-cloud/", text: "Salesforce Healthcare Cloud" },
            { href: "/case-studies/salesforce-sales-cloud/", text: "Salesforce Sales Cloud" },
            { href: "/case-studies/salesforce-service-cloud/", text: "Salesforce Service Cloud" },
          
           
          ] },

          
          // { id: 'dropNav5', title: 'Training', href: "/training", text: "Training" },
          { id: 'dropNav6', title: 'Company', links: [
            { href: "/about-us", text: "About Us" },
            { href: "/career/", text: "Careers" },
            { href: "/contact-us/", text: "Contact Us" },
          ] }
        ].map((dropdown) => (
          <div key={dropdown.id} className="mobile-nav__dropdown" data-id={dropdown.id}>
            {dropdown.links ? (
              <div
                className="mobile-nav__dropdown-title"
                onClick={() => toggleSubmenu(dropdown.id)}
              >
                {dropdown.title}
                <span className={`mobile-nav__dropdown-icon ${openSubmenuId === dropdown.id ? 'open' : ''}`}>▼</span>
              </div>
            ) : (
              <a
                className="mobile-nav__link"
                href={dropdown.href}
                style={dropdown.title === 'Training' ? trainingLinkStyle : {}}
              >
                {dropdown.text}
              </a>
            )}
            {dropdown.links && (
              <div
                className={`mobile-nav__dropdown-content ${openSubmenuId === dropdown.id ? 'active' : ''}`}
                id={dropdown.id}
              >
                {dropdown.links.map((link, index) => (
                  <a key={index} className="mobile-nav__content-item mobile-nav__link" href={link.href}>
                    {link.text}
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNavbar;
