import React from 'react'


import Faq from '../../Faq/Faq'

import MobileBanner from './mobilecompo/MobileBanner'
import MobileBenifits from './mobilecompo/MobileBenifits'
import MobileBanner2 from './mobilecompo/MobileBanner2'
import WhySaasverseMobile from './mobilecompo/WhySaasverseMobile'
import MobileReadyBanner from './mobilecompo/MobileReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function MobilePublisher() {
  return (
    <><MobileBanner/>
    <MobileBenifits/>
    <MobileBanner2/>
    <WhySaasverseMobile/>
    <CertificateSlider/>
    <Trustedby2/>
    <MobileReadyBanner/>
    <Faq/>
      
    </>
  )
}
