import React from 'react'
import SitecoreBanner from './Compo/SitecoreBanner'
import SitecorePower from './Compo/SitecorePower'
import WhySitecore from './Compo/WhySitecore'
import SitecoreServices from './Compo/SitecoreServices'
import WhySitecoreS from './Compo/WhySitecoreS'
import ReadySitecore from './Compo/ReadySitecore'
import SitecoreFaq from './Compo/SitecoreFaq'

export default function Sitecore() {
  return (
    <>
      <SitecoreBanner/>
      <SitecorePower/>
      <WhySitecore/>
      <SitecoreServices/>
      <WhySitecoreS/>
      <ReadySitecore/>
      <SitecoreFaq/>
    </>
  )
}
