import React from 'react'

export default function SitecoreServices() {
  return (
    <div id="servicess" >
    <div className="cnts">
      <div className="rows">
  <h2 style={{fontSize:'40px',textAlign:'center',paddingBottom:'20px'}}>Our Sitecore Development Services
  </h2>
  {/* <p>At Saasverse, we offer more than just a job – we offer a career filled with opportunities for growth, learning, and advancement. Here’s why you should consider joining our team:</p><br/> */}

        <div className="reasons__wrappers">

        <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
                
              <h3 className="reasons__item_titles">Sitecore Implementation</h3>
              <p className="reasons__item_texts">
                 <b style={{color:'#006b95'}}>Strategy and Planning</b>
                 <p>Develop a comprehensive implementation strategy tailored to your business goals and requirements.

                 </p>
                 <b style={{color:'#006b95'}}>Installation and Configuration</b>
                 <p>Set up and configure the Sitecore environment to align with your business processes and workflows.

                 </p>
                 <b style={{color:'#006b95'}}>Data Migration</b>
                 <p>Ensure a seamless transition of your existing data to the Sitecore platform with minimal disruption.


                 </p>
                


</p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
                
              <h3 className="reasons__item_titles">Sitecore Customization</h3>
              <p className="reasons__item_texts">
                 <b style={{color:'#006b95'}}>Module Customization</b>
                 <p>Tailor existing Sitecore modules to better fit your specific business needs.
                 </p>
                 <b style={{color:'#006b95'}}>Custom Development</b>
                 <p> Develop new modules and functionalities to address unique business challenges and requirements.

                 </p>
                 <b style={{color:'#006b95'}}>User Interface Enhancements</b>
                 <p>Enhance the usability and appearance of your Sitecore interface for a superior user experience.

                 </p>
     


</p>
            </div>
          </div>

          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
                
              <h3 className="reasons__item_titles">Sitecore Integration</h3>
              <p className="reasons__item_texts">
                 <b style={{color:'#006b95'}}>Third-Party Integrations</b>
                 <p>Integrate Sitecore with other software solutions, including CRMs, e-commerce platforms, and marketing automation tools.

                 </p>
                 <b style={{color:'#006b95'}}>API Development</b>
                 <p>Develop and implement custom APIs to facilitate seamless data exchange between Sitecore and other systems.

                 </p>
                
               


</p>
            </div>
          </div>


          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
                
              <h3 className="reasons__item_titles">Sitecore Support and Maintenance</h3>
              <p className="reasons__item_texts">
                 <b style={{color:'#006b95'}}>Ongoing Support</b>
                 <p>Provide continuous support to address any issues, perform updates, and ensure optimal performance.

                 </p>
                 <b style={{color:'#006b95'}}>System Monitoring</b>
                 <p>Monitor your Sitecore environment to proactively identify and resolve potential problems.
                 </p>
                 <b style={{color:'#006b95'}}>User Training</b>
                 <p>Offer comprehensive training sessions to help your team maximize the benefits of Sitecore.
                 </p>
              


</p>
            </div>
          </div>


          <div className="reasons__items"><span className="reasons__item_figures"></span>
            <div className="reasons__item_contents">
                
              <h3 className="reasons__item_titles"> Sitecore Migration</h3>
              <p className="reasons__item_texts">
                 <b style={{color:'#006b95'}}>Version Upgrades</b>
                 <p>Upgrade your existing Sitecore installation to the latest version to take advantage of new features and improvements.

                 </p>
                 <b style={{color:'#006b95'}}> Data Migration</b>
                 <p> Migrate data from other CMS platforms to Sitecore, ensuring data integrity and continuity.

                 </p>
              


</p>
            </div>
          </div>




       
      
      

         
        


         


         
        

         
      
        </div>
      </div>
    </div>
  </div>
  )
}
