import React from 'react'

export default function JustProject() {
  return (
    <div className="containert">
    <div className="columnt">
      <h2>More Than Just Project Management</h2>
      <p>We go beyond basic project management. Our Salesforce PMs offer</p>
    </div>
    <div className="columnt">
      <p><b>Requirements Gathering and Analysis</b><br/>They work closely with you to define project goals, scope, and requirements.
      </p>
      <br/> <p><b>Vendor Management</b> <br/>If needed, they can manage relationships with third-party vendors involved in your project.
      </p>
    </div>
    <div className="columnt">
      <p><b>Change Management</b><br/>Our PMs facilitate change management initiatives to ensure user buy-in and smooth adoption of the new system.</p>
      <br/> <p><b>Post-Implementation Support</b><br/>They provide ongoing support to ensure your team continues to maximize the value of Salesforce.</p>
    </div>
  </div>
  )
}
