import React, { useState, useRef, useEffect } from 'react';
import './css/HealthClientBackground.css';

const HealthClientBackground = () => {
  const sections = [
    {
      title: 'Business Challenge',
      content: `Unity Health Systems (UHS) faced challenges in managing patient data effectively across multiple departments and facilities. With outdated systems, they struggled with fragmented patient records, inefficient communication between care teams, and delays in accessing critical patient information. They sought a solution to unify their patient management processes, enhance care coordination, and improve overall operational efficiency.`,
    },
    {
      title: 'Why did the client come to Saasverse?',
      content: `Unity Health Systems (UHS) approached Dotsquares seeking a solution to their fragmented and inefficient patient management systems. They needed a robust platform that could integrate with their existing systems, ensure data security, and enhance care coordination. UHS chose Dotsquares for their expertise in implementing Salesforce Healthcare Cloud, known for its capabilities in patient relationship management, care coordination, and data integration. By partnering with Dotsquares, UHS aimed to transform their patient care delivery, improve patient engagement, streamline workflows, and ultimately achieve better patient outcomes. Dotsquares' proven track record and customized approach made them the ideal partner for this significant transformation.`,
    },
  ];

  const [currentSection, setCurrentSection] = useState(0);
  const contentRefs = useRef([]);

  const handleScroll = () => {
    const sectionElements = contentRefs.current;
    sectionElements.forEach((el, index) => {
      const rect = el.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
        setCurrentSection(index);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="client-info-container">
      <div className="headingss">
        <div className="headingg active">
          {sections[currentSection].title}
        </div>
      </div>
      <div className="contents">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`content-section ${currentSection === index ? 'active' : ''}`}
            ref={(el) => (contentRefs.current[index] = el)}
          >
            <div className="contentg">
              {section.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HealthClientBackground;
