import React from 'react';
// import './css/WhySaasverse.css'; 

const WhySaasverseTravel = () => {
  return (
    <div className="info-container">
       <div className="info-image">
        <img src="/assets/images/websiteimages/20.jpg" alt="Why Choose Saasverse?" />
      </div>
      <div className="info-text">
        <h2>Saasverse: Your Travel & Hospitality Expertise
        </h2>
        <p>At Saasverse, we're passionate about travel. Our team of seasoned Salesforce experts understands the intricacies of the Travel & Hospitality industry. We can help you:
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Customize Salesforce solutions to perfectly fit your unique offerings, whether you manage hotels, resorts, cruises, or adventure tours.


        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Integrate seamlessly with your existing systems (PMS, booking engines) to create a single source of guest truth and a unified travel ecosystem.
        </p>
        <p>
        <b style={{color:'#006b95'}}>-</b> Develop custom applications to address specific challenges, such as managing loyalty programs for multiple brands or optimizing operations for seasonal fluctuations.
         </p>
        <p>
         <b style={{color:'#006b95'}}>-</b> Provide ongoing support and training, empowering your team to leverage Salesforce and deliver world-class hospitality.</p>
      
      </div>
    
    </div>
  );
};

export default WhySaasverseTravel;
